// Core
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Instruments
import { Route, RoutePrefix } from '../helpers/routeDecorators';
import { JsonReply, JsonReplyWithParams } from "../models/api/JsonReply";
import { CustomerEvent } from "../models/customer/CustomerEvent";
import { SharedService } from "./shared.service";
import { LinkToAssetParams, GetContactEventParams } from '../models';


@Injectable()
@RoutePrefix('/api/ContactEvent')
export class CustomerEventsService {
    constructor(private httpClient: HttpClient,
                private shared: SharedService) {

    }

    @Route('ForContact')
    public getEventsById(params: GetContactEventParams, url: string = ''): Promise<JsonReplyWithParams<CustomerEvent[], GetContactEventParams>> {
        return this.httpClient.post<JsonReplyWithParams<CustomerEvent[], GetContactEventParams>>(url, params)
            .toPromise();
    }

    @Route('AllForContact')
    public getAllEventNames(contactId: number, url: string = ''): Promise<JsonReply<CustomerEvent[]>> {
        return this.httpClient.post<JsonReply<CustomerEvent[]>>(url, contactId).toPromise();
    }

    @Route('Add')
    public addEvent(event: CustomerEvent, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, event)
            .toPromise();
    }

    @Route('Update')
    public updateEvent(event: CustomerEvent, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, event)
            .toPromise();
    }

    @Route('Delete')
    public deleteEvent(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteEvents(idList: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, idList)
            .toPromise();
    }

    @Route('ExportMany')
    public exportEvents(idList: number[], url: string = ''): void {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        headers = headers.append('IdList', idList.join(','));

        this.httpClient.get(url, { headers: headers, responseType: 'blob' }).subscribe((resp: any) => {
            this.shared.downloadBlobFile(resp, 'contact_events.xlsx');
        });
    }

    @Route('LinkToAsset')
    public linkEventToAsset(params: LinkToAssetParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, params).toPromise();
    }

}
