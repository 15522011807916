import { enumerable } from "../helpers/enumerable.decorator";

export class RichEnumOption {
    public name: string
    public displayName: string

    constructor(public value: number, _name: string, _displayName: string = null) {
        this.name = _name;
        this.displayName = _displayName;
    }

    public toString(): string {
        return (this.displayName !== undefined && this.displayName !== null) ? this.displayName : this.name;
    }
}


export class RichEnum extends Array<RichEnumOption> {
    static TRANSLATE_PATH: string = 'ENUMS';
    static readonly Any: number = -1;

    @enumerable(false)
    protected ENUM_NAME: string;

    getForValue: (value: number) => string | RichEnumOption | RichEnumOption[];

    constructor(name: string, ...args: RichEnumOption[]) {

        args.forEach(a => {
            if (a.name !== null) {
                a.name = `${RichEnum.TRANSLATE_PATH}.${name}.${a.name}`;
            }
        });

        super(...args);

        this.ENUM_NAME = name;

        RichEnum.prototype.getForValue = function (value: number | number[]): string | RichEnumOption | RichEnumOption[]  {
            if (value instanceof Array) {
                let array = value as number[];
                if (array.length === 0)
                    return 'N/A';

                let items: RichEnumOption[] = [];
                array.forEach(v => {
                    items.push(this.find(function (i: RichEnumOption) {
                        return i.value === v;
                    }));
                });

                return items;
            } else {
                let item: RichEnumOption = this.find(function (i: RichEnumOption) {
                    return i.value === value;
                });

                if (item) {
                    return item;
                } else {
                    return 'N/A';
                }
            }
        };
    }
}

export class FlaggedRichEnum extends RichEnum {
    constructor(...args: RichEnumOption[]) {
        let val = 1;
        let options: RichEnumOption[] = args;
        options.forEach(op => {
            op.value = val;
            val = val * 2;
        });

        super('', ...options);
    }
}

export class FlaggedRichEnumWithAny extends RichEnum {
    constructor(...args: RichEnumOption[]) {
        let val = 1;
        let options: RichEnumOption[] = args;
        options.forEach(op => {
            if (op.value === 0)
                return;

            op.value = val;
            val = val * 2;
        });

        options.push(new RichEnumOption(val - 1, "Any"));

        super('', ...options);
    }
}
