import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberwithsign',
    standalone: true
})
export class NumberWithSign implements PipeTransform {
    transform(value: number, decimal = 2): string {
        if (value > 0)
            return "+" + value.toFixed(decimal);
        return value.toFixed(decimal)
    }
}