<div class="row" style="margin: 0;">
    <div class="col">
        <div class="contentComponent"
             (click)="emitSoftLegal(row, $event)"
             [matTooltip]="'TITLES.SOFT_LEGAL' | translate"
             [ngClass]="{'disabled': !row.contentWidget.isSoftLegal}"
             style="-webkit-mask: url(/assets/svg/material-icons/gavel.svg) no-repeat center;margin-right: 3px;">
        </div>
        <span *ngIf="softLegalService.SoftLegalBadgeIsGreen(row.contentWidget.status) == false" class="red red-text" matBadge="R" matBadgeSize="small"></span>
        <span *ngIf="softLegalService.SoftLegalBadgeIsGreen(row.contentWidget.status) == true" class="green green-text" matBadge="G" matBadgeSize="small"></span>
        <div *ngIf="row.contentWidget.othersSoftLegalCustomers && row.contentWidget.othersSoftLegalCustomers.length > 0" style="-webkit-mask: url(/assets/svg/material-icons/share_alt.svg) no-repeat center; background-color: rgb(50, 153, 50); position: absolute; bottom: 5px; right: 5px; height: 20px; width: 20px; "></div>
    </div>

    <div class="col">
        <div class="contentComponent"
             [matTooltip]="'TABLE_HEADINGS.EVENTS_NAMES' | translate"
             [ngClass]="{'disabled': !row.contentWidget.hasEvents}"
             style="-webkit-mask: url(/assets/svg/material-icons/event.svg) no-repeat center">
        </div>
    </div>

    <div class="col">
        <div class="contentComponent"
             [matTooltip]="'TITLES.SERP' | translate"
             [ngClass]="{'disabled': !row.contentWidget.isSerpContent}"
             (click)="emitDatasourceId(row, $event)"
             style="-webkit-mask: url(/assets/svg/material-icons/serp_white.svg) no-repeat center;margin-right: 3px;">
        </div>
    </div>

    <div class="col">
        <div class="contentComponent"
             [matTooltip]="'TITLES.CONTENT_BUILD' | translate"
             [ngClass]="{'disabled': !row.contentWidget.isContentBuild || !row.contentWidget.rmPublished}"
             style="-webkit-mask: url(/assets/svg/material-icons/content_build.svg) no-repeat center; margin-right: 3px;">
        </div>
    </div>

    <div class="col positioned" (click)="emitPositioned(row, $event)" [matTooltip]="'TABLE_HEADINGS.POSITIONED' | translate">
        <p *ngIf="row.contentWidget.positioned">P</p>
        <p class="disabled" *ngIf="!row.contentWidget.positioned">P</p>
    </div>
</div>

<div class="row" style="margin: 0;">
    <div class="col">
        <div class="contentComponent" [ngClass]="{'wtfStatusActive': row.contentCustomerExtData.wftDate != null, 'wftStatus': row.contentCustomerExtData.wftDate == null }"
             matTooltip="WFT  {{row.contentCustomerExtData.wftDate | date: 'dd/MM/yyyy, HH:mm'}}"
             style="-webkit-mask: url(/assets/svg/material-icons/wft.svg) no-repeat center">
        </div>
    </div>

    <div class="col">
        <div class="contentComponent"
             (click)="emitChannel(row, $event)"
             [matTooltip]="'TITLES.WIP' | translate"
             [ngClass]="{'disabled': !row.contentWidget.isCustomerChannel}"
             style="-webkit-mask: url(/assets/svg/material-icons/channel2_white.svg) no-repeat center">
        </div>
        <p style="display: inline-block; position: absolute; bottom: 3px; right: 6px;">
            <strong *ngIf="row.contentCustomerExtData.controlled">C</strong>
            <strong *ngIf="row.contentCustomerExtData.aggregator">A</strong>
        </p>
    </div>

    <div class="col">
        <div class="contentComponent"
             [matTooltip]="'TITLES.DESIRED_MATRIX' | translate"
             [ngClass]="{'disabled': !row.contentWidget.hasValues}"
             style="-webkit-mask: url(/assets/svg/material-icons/target.svg) no-repeat center">
        </div>
    </div>

    <div class="col" style="text-align: center; padding-right: 15px">
        <div [matTooltip]="'TABLE_HEADINGS.RSCORE' | translate"
             style="background-color: transparent">
            <p class="widgetTitle">R</p>
            {{row.contentCustomerExtData.rScore ? (row.contentCustomerExtData.rScore).toFixed(2) : '-'}}
        </div>
    </div>

    <div class="col">
        <div class="contentComponent"
             [matTooltip]="'TABLE_HEADINGS.PREMIUM' | translate"
             [ngClass]="{'disabled': !row.contentCustomerExtData.premium}"
             style="-webkit-mask: url(/assets/svg/material-icons/star.svg) no-repeat center">
        </div>
    </div>
</div>
<div class="coloreLato" [ngStyle]="{'background': getColorEvaluation(row)}"></div>
