// Core
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, EventEmitter, OnDestroy} from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { DatePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
// Instruments
import { Enums, PopupTableType, UserGroup, Contact, JsonReply, BRCustomerContentId, linkCheckModel, LinksToAssetsAlertOptions, ContentTag } from '../../instruments/models';
import { MatrixMacroCategory } from '../../instruments/models/valueMatrix/CustomerValueMatrix';
import { SelectValuesOnlyMacroComponent } from '../select-values-only-macro/select-values-only-macro.component';
import { SelectValuesComponent } from '../select-values/select-values.component';

// Table options
import {
    LinkEventToLinkTableOptions,
    LinkEventsToAssetTableOptions,
    LinkDomainsToTtdTableOptions,
    LinkedToLinkEventTableOptions,
    FindContactsTableOptions,
    MassiveContentsEventsTableOptions
} from '../../instruments';
import * as moment from 'moment';
import { DomainUser } from '../../instruments/models';
import { TableOptions } from '../../instruments/models/table/TableOptions';
import { SharedService } from 'src/app/instruments/services/shared.service';
import { ContentService } from 'src/app/instruments/services/content.service';
import { ValueMatrixService } from 'src/app/instruments/services/valueMatrix.service';
import { Config } from '../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { ValueOfEnumPipe } from '../../instruments/helpers/ValueOfEnumPipe';
import { SerpGeneratorComponent } from '../serp-generator/serp-generator.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MyUniumxUsersSettingsComponent } from '../myuniumx-users-settings/myuniumx-users-settings.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MultiselectWithDefaultDirective } from '../../instruments/helpers/multiselect-with-default.directive';
import { SoftLegalMassiveComponent } from '../../pages/project-details/soft-legal/soft-legal-massive/soft-legal-massive.component';
import { LinkPositionTrendComponent } from '../link-position-trend/link-position-trend.component';
import { RmFileUploaderComponent } from '../rm-file-uploader/rm-file-uploader.component';
import { SoftLegalDetailComponent } from '../../pages/project-details/soft-legal/soft-legal-detail/soft-legal-detail.component';
import { RmTableComponent } from '../rm-table/rm-table.component';
import { MatSliderModule } from '@angular/material/slider';
import { RmMonthDatePickerComponent } from '../rm-month-date-picker/rm-month-date-picker.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { RmPaginatorComponent } from '../rm-paginator/rm-paginator.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { WidgetShareStatusComponent } from '../widget-table/widget-components/widget-share-status.component';
import { ChannelsChartComponent } from '../channels-chart/channels-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RmDatetimePickerComponent } from '../rm-datetime-picker/rm-datetime-picker.component';
import {WidgetSetChannelComponent} from '../widget-table/widget-components/widget-set-channel/widget-set-channel.component';
import { SuccessFeeMassiveComponent } from "../../pages/project-details/soft-legal/success-fee-massive/success-fee-massive.component";

@Component({
    selector: 'alert-component',
    templateUrl: './alert.component.html',
    styleUrls: ['alert.component.css'],
    standalone: true,
    imports: [
    MatButtonModule,
    MatDialogModule,
    NgIf,
    MatCheckboxModule,
    FormsModule,
    ChannelsChartComponent,
    WidgetShareStatusComponent,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    RmPaginatorComponent,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    TextFieldModule,
    MatSlideToggleModule,
    RmMonthDatePickerComponent,
    MatSliderModule,
    RmTableComponent,
    SoftLegalDetailComponent,
    NgClass,
    RmFileUploaderComponent,
    SelectValuesOnlyMacroComponent,
    LinkPositionTrendComponent,
    SoftLegalMassiveComponent,
    MatChipsModule,
    MultiselectWithDefaultDirective,
    MatTableModule,
    MatTooltipModule,
    MyUniumxUsersSettingsComponent,
    MatTabsModule,
    SerpGeneratorComponent,
    DatePipe,
    ValueOfEnumPipe,
    TranslateModule,
    RmDatetimePickerComponent,
    WidgetSetChannelComponent,
    SuccessFeeMassiveComponent
],
})
export class AlertComponent implements OnInit {
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    domainUserCompareFn = DomainUser.equals;
    enums: Enums = new Enums();
    tableOptions: TableOptions;
    macroCategories: MatrixMacroCategory[];
    massiveMacroCategories: MatrixMacroCategory[];
    config = Config;
    userRole: any = 0;
    uploadHeaders: HttpHeaders;    
    newContact: Contact = new Contact({});
    uploader: boolean = false;
    docDataSource: any = [];
    selectedCaseType = [];
    AssignedSales = [];
    ContactStatus = null;
    NegotiationStatus = null;
    channelOrLink = 'channel';
    displayedColumnsContentSerp: string[] = [
        'position',
        'variation',
        'keyword',
        'engine',
        'frequency',
        'lastExecutionDate'
    ];

    //reset soft legal if not saved
    softLegalAtAlertOpening: any = null;
    resetSoft: boolean = true;
    successFeeAtOpening: boolean = false;
    contractAtOpening: boolean = false;

    // AVAILABLE
    availableSales: DomainUser[] = [];

    //switches to enable generic edit and massive edit
    enableMassiveStatus: boolean = false;
    enableMassiveCaseType: boolean = false;
    enableMassiveSales: boolean = false;
    enableMassiveRelationship: boolean = false;

    // UI
    states = this.enums.ContactLanguage;
    selectedStates: any = this.states;
    showValues = false;
    keywords: ContentTag[] = [];
    isFutureDate: boolean = false;
    copytext: boolean = false;

    //Import from Unium
    importFromUniumOptions: LinksToAssetsAlertOptions = new LinksToAssetsAlertOptions(false, false);

    updatePagination: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('recallForm') recallForm: NgForm;
    @ViewChild('selectValues') selectValues: SelectValuesComponent;
    @ViewChild('selectValuesMacro') selectValuesMacro: SelectValuesOnlyMacroComponent;

    constructor(        
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public shared: SharedService,
        public contentService: ContentService,
        public valueMatrixService: ValueMatrixService,
        private httpClient: HttpClient
    ) {
    }


    ngOnInit() {
        this.getSales();

        if (this.data.type == 'massiveContents') {
            this.httpClient.post<JsonReply<any>>('/api/ContactEvent/AllForContact', this.data.contents[0].contactId).subscribe(res => {
                this.data.events = res.data;
            });
        }

        //if massivecontents, download macrocat
        if (this.data.contents != undefined) {
            this.resetMassive();
            this.downloadMacroCat(this.data.contents[0].contactId, this.data.contents[0].contentId);
        }

        if (this.data.request) {
            this.data.request.sendToEmail = true;
        }

        if (this.data.link != undefined && this.data.link.url != undefined) {
            this.data.oldUrl = this.data.link.url;
            if (this.data.link.softLegal) {
                if (this.data.link.contentId) {
                    this.softLegalAtAlertOpening = true;
                }
            }
        }

        if (this.data.domain) {
            if (this.data.domain.type == 2) {
                this.data.domain.typeIsVideo = true;
            }
        }

        if (this.data.tableType !== null && this.data.tableType !== undefined) {
            this.setTableOptions();
        }

        if (this.data.tableType == PopupTableType.LinkContacts) {
            this.updateList();
        }
        this.appendCustomerId();        
    }

    public formatDate(date: Date) {
        return moment(date).fromNow();
    }
    
    private getSales(): void {
        this.availableSales = this.shared.availableSales
    }

    public onKeyLanguage(el: EventTarget) {
        const input = el as HTMLInputElement
        this.selectedStates = this.searchLanguage(input.value);
    }

    public deleteKeyword(keyword: ContentTag): void {
        const index = this.keywords.indexOf(keyword);

        if (index >= 0) {
            this.keywords.splice(index, 1);
        }
    }
    public addKeyword(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add new tag
        if ((value || '').trim()) {
            const keyword = value;
            this.keywords.push(new ContentTag(keyword));
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    public deleteDictionary(keyword: any): void {
        const index = this.data.dictionary.indexOf(keyword);

        if (index >= 0) {
            this.data.dictionary.splice(index, 1);
            this.httpClient.post<JsonReply<any>>('/api/QuickSERP/Dictionary/Delete', keyword.id).subscribe(res => {
            });;
        }
    }
    public addDictionary(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add new tag
        if ((value || '').trim()) {
            let keyword: any = { keyword: value };
            this.httpClient.post<JsonReply<any>>('/api/QuickSERP/Dictionary/Add', keyword).subscribe(res => {
                if (res.data) {
                    keyword.id = res.data;
                    this.data.dictionary.push(keyword);
                }
            });;
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }


    public downloadMacroCat(customerId, contentId) {
        this.valueMatrixService.getValuesForContent(new BRCustomerContentId(customerId, contentId))
            .then(resp => {
                if (resp.ok) {
                    this.massiveMacroCategories = [];

                    resp.data.map(res => {
                        res.weight = null;
                        res.value = 0;
                        if (res.macroCategoryName != null) {
                            res.subCategories = [];
                            this.massiveMacroCategories[res.id] = res;
                        }
                    });

                    resp.data.map(res => {
                        if (res.subCategoryName != null) {
                            res.categoryId = res.id;
                            this.massiveMacroCategories[res.macroCategoryId].subCategories.push(res);
                        }
                    });

                    this.showValues = true;
                } else {
                    console.error(resp.errorMessage);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    public searchLanguage(value: string) {
        let filter = value.toUpperCase();

        let states = [];

        this.states.map(ma => {
            if (ma.name.substr(16).startsWith(filter) || this.newContact.languages.includes(ma.displayName)) {
                states.push(ma);
            }
        })
        if (states.length > 9) {
            return states.splice(0, 9);
        } else {
            return states;
        }
    }

    private setTableOptions() {
        switch (this.data.tableType) {
            case PopupTableType.LinkEventToLink:
                this.tableOptions = new LinkEventToLinkTableOptions();
                break;
            case PopupTableType.LinkedEventsToLink:
                this.tableOptions = new LinkedToLinkEventTableOptions();
                break;
            case PopupTableType.LinkEventToAsset:
                this.tableOptions = new LinkEventsToAssetTableOptions();
                break;
            case PopupTableType.LinkDomainToTtd:
                this.tableOptions = new LinkDomainsToTtdTableOptions();
                break;
            case PopupTableType.LinkContacts:
                this.tableOptions = new FindContactsTableOptions();
            case PopupTableType.MassiveContents:
                this.tableOptions = new MassiveContentsEventsTableOptions();
            default:
                break;
        }
    }

    // TODO: refactor!
    public updateList() {
        this.data.getContactsFunc(this.tableOptions.params)
            .then(resp => {
                if (resp.ok) {
                    this.data.contacts = resp.data;
                    if (resp.params) {
                        Object.assign(this.tableOptions.params, resp.params);  //this.options.params = value.params;
                    }
                    this.updatePagination.emit();
                } else {
                    console.error(resp);
                }
            })
            .catch(err => console.error(err));
    }

    public _cancel() {
        this.dialogRef.close('cancel');
    }

    ngOnDestroy() {
        if (this.softLegalAtAlertOpening != null && this.resetSoft) {
            //makes the table re-fetch
            this.dialogRef.close('savedSameUrl');
        }
    }
    
    public _ok() {
        this.resetSoft = false;
        this.dialogRef.close('ok');
    }

    public massiveBrowser() {
        this.dialogRef.close(this.importFromUniumOptions);
    }

    //get alert string from html, open defined alert after closing this one
    public alert(alert: string) {
        this.dialogRef.close(alert);
    }

    public saveValues() {
        this.data.entity.macroCategories = this.selectValuesMacro.macroCategories;
        this._ok();
    }

    public roleNumberToString(value) {

        var ruoli = [];
        // checks if the int we pass as value is in any UserGroup, the answer can be true for multiple groups
        if (this.isUserInRole(value, UserGroup.Admin)) {
            ruoli.push(UserGroup[UserGroup.Admin])
        }
        if (this.isUserInRole(value, UserGroup.Sales)) {
            ruoli.push(UserGroup[UserGroup.Sales])
        }
        if (this.isUserInRole(value, UserGroup.Account)) {
            ruoli.push(UserGroup[UserGroup.Account])
        }
        if (this.isUserInRole(value, UserGroup.Legal)) {
            ruoli.push(UserGroup[UserGroup.Legal])
        }
        if (this.isUserInRole(value, UserGroup.SEO)) {
            ruoli.push(UserGroup[UserGroup.SEO])
        }
        if (this.isUserInRole(value, UserGroup.Writers)) {
            ruoli.push(UserGroup[UserGroup.Writers])
        } 
        if (this.isUserInRole(value, UserGroup.Users)) {
            ruoli.push(UserGroup[UserGroup.Users])
        }
        
        if (ruoli.length > 1) {
            ruoli.map((rol, i) => {
                if (i != 0) {
                    var newRol = " " + rol
                    ruoli.splice(i, 1, newRol)
                }
                i++
            });
        }
        this.userRole = ruoli.toString() 
    }

    public isUserInRole(currUserGroup: number, role: UserGroup): boolean {
        if (role === UserGroup.Any)
            return true;
        
        const res = (currUserGroup & role) === role;
        return res;
    }

    // DOCUMENT COUNT

    public setDocumentCount(count: number) {
        this.newContact.documentsCount = count;
    }

    private appendCustomerId(): void {
        if (this.data.link != undefined) {
            if (this.data.link.contentId != undefined) {
                let headers = new HttpHeaders();
                headers = headers.append('ContactId', this.data.link.contactId.toString());
                headers = headers.append('ContentId', this.data.link.contentId.toString());
                this.uploadHeaders = headers;
            }
        }
    }

    //sezione file per recall
    public createHeaders(id) {
        return new HttpHeaders().append('ActivityLogId', id.toString());
    }

    public onUpload() {
        this.uploader = true
    }

    // FILE UPLOADER

    public fileUploadFinished(event: any, closeAfter: boolean = false) {
        this.uploader = false

        if (closeAfter) {
            this._ok();
        }
    }

    public changeCaseType(event) {
        this.enableMassiveCaseType = true
        this.selectedCaseType = event.value
    }

    public changeSales(event) {
        this.enableMassiveSales = true
        this.AssignedSales = event.value
    }

    public changeRelationship(event) {
        this.enableMassiveRelationship = true
        this.ContactStatus = event.value
    }

    public changeStatus(event) {
        this.enableMassiveStatus = true
        this.NegotiationStatus = event.value
    }


    public reseteditManyFindContacts() {
        this.selectedCaseType = null;
        this.AssignedSales = null;
        this.ContactStatus = null;
        this.NegotiationStatus = null;
        this.resetEnablers();
    }

    public resetEnablers() {
        this.enableMassiveCaseType = false;
        this.enableMassiveSales = false;
        this.enableMassiveRelationship = false;
        this.enableMassiveStatus = false;
    }

    public resetMassive() {
        this.data.contents[0].pertinence = null;
        this.data.contents[0].evaluation = null;
        this.data.contents[0].sender = null;
        this.keywords.splice(0);
        this.data.contents[0].importToSoft = false;
        this.data.contents[0].entityId = null;
        if (this.massiveMacroCategories) {
            this.massiveMacroCategories.map(macro => {
                macro.value = 0;
                macro.subCategories.map(micro => {
                    micro.value = 0;
                })
            })
        }
    }

    public massiveContents() {
        this.data.contents[0].macroCat = this.massiveMacroCategories;
        this.data.contents[0].tags = this.keywords.map(key => { return key.tag });
        this._ok();
    }

    //Edit Massive
    public editMassive() {    

        let editMassiveBody = {
            ContactIds: [],
            CaseType: null,
            AssignedSales: null,
            ContactStatus: null,
            NegotiationStatus: null
        }

        this.data.link.map(link => {        
            editMassiveBody.ContactIds.push(link.id)
        })

        if (this.enableMassiveCaseType == true) {
            editMassiveBody.CaseType = this.selectedCaseType
        }

        if (this.enableMassiveSales == true) {
            editMassiveBody.AssignedSales = this.AssignedSales
        }

        if (this.enableMassiveRelationship == true) {
            editMassiveBody.ContactStatus = this.ContactStatus
        }

        if (this.enableMassiveStatus == true) {
            editMassiveBody.NegotiationStatus = this.NegotiationStatus
        }

        console.log(editMassiveBody);
        this.httpClient.post<JsonReply<any>>('/api/Customer/MassiveUpdate', editMassiveBody).subscribe(res => {
            if (res.ok == true) {
                
            } else {
                
            }
        });

        this._ok();

    }

    public _okStepDateChange() {

        var hour = parseInt(this.data.params.time.split(":")[0]);
        var minutes = this.data.params.time.split(":")[1]
        var dateTime = new Date(this.data.params.statusDate)
        dateTime.setHours(hour, minutes)
        var now = new Date()

        if (dateTime > now) {
            this.isFutureDate = true
        } else {
             this.resetSoft = false;
            this.dialogRef.close('ok');
        }
    }

    public getQuery(query, data) {
        if (query.type == Config.TYPESERP.SERPAUTOMATIC)
            data.query.queryUrlFromParameters = query.queryUrl;
        if (query.type == Config.TYPESERP.SERPMANUAL)
            data.query.queryUrl = query.queryUrl;

        data.query.parameters = query.parameters;
        data.query.description = query.description;
        data.query.frequency = query.frequency;
        this._ok()
    }

    public copyToBoard(text) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.copytext = true;

        setTimeout(() => {
            this.copytext = false;
        }, 1500);
    }

    public handleDownloadRecall(recall): void {
        //trova il nome del file e dichiara il path della chiamata
        const exportName = (recall.fileName).replace(/ /g, '_');
        const path = '/api/CustomerRecall/Document/Download/' + recall.id;

        this.downloadFile(path, exportName).then(() => { });
    }

    private downloadFile(path: string, exportName: string): Promise<boolean> {
        const defer = this.shared.downloadFile(path, exportName);

        return defer;
    }

    public handleRemoveRecall(recall): void {
        var path = "api/CustomerRecall/Document/Delete"
        this.httpClient.post<JsonReply<any>>(path, recall.id).subscribe({
            next: (resp) => {
                if (resp.ok) {
                    recall.fileName = null;
                    this._ok();
                }
            },
        });
    }
} 
