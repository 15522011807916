import { Directive, Input } from '@angular/core';

@Directive({
    selector: 'img[default]',
    host: {
        '(error)': 'updateUrl()',
        '(load)': 'load()',
        '[src]': 'src'
    },
    standalone: true
})

export class DefaultImageSrcDirective {
    @Input() src: string;
    @Input() default: string;

    updateUrl() {
      this.src = this.default;
    }

    load() { }
}
