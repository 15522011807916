<div *ngIf="dataSource; else loading">
    <rm-paginator [params]="params" (paginationParamsChanged)="getDocuments()"></rm-paginator>
    <mat-table [dataSource]="dataSource"
               *ngIf="contactDocuments.length !== 0; else nothingFound"
               class="w-100">
        <!-- Id Code Column -->
        <!-- Name File/Title Column -->
        <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef>{{'TABLE_HEADINGS.FILE_NAME/TITLE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let document">{{document.fileName}}</mat-cell>
        </ng-container>
        <!-- Type Column -->
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>{{'TABLE_HEADINGS.TYPE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let document">{{enums.ContentDocumentType[document.type].toString() | translate}}</mat-cell>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>{{'TABLE_HEADINGS.DATE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let document">{{document.insertDate | date:'dd/MM/yyyy'}}</mat-cell>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="d-flex justify-content-end text-right">{{'TABLE_HEADINGS.ACTIONS' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let document" class="d-flex">
                <a mat-icon-button
                   class="d-flex align-items-center"
                   color="primary"
                   [matTooltip]="'BUTTONS.DOWNLOAD' | translate"
                   href="{{downloadUrl + document.id}}">
                    <img src="/assets/svg/material-icons/save_alt_blue.svg">
                </a>
                <button mat-icon-button
                        class="d-flex justify-content-between align-items-center"
                        color="warn"
                        [matTooltip]="'BUTTONS.DELETE' | translate"
                        (click)="deleteDocument(document.id)">
                    <img src="/assets/svg/material-icons/delete_blue.svg">
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <rm-paginator [params]="params" (paginationParamsChanged)="getDocuments()"></rm-paginator>
</div>

<!-- SPINNER -->
<ng-template #loading>
    <div class="pt-5 pb-5">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>
</ng-template>

<!-- NOTHING FOUND -->
<ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
</ng-template>
