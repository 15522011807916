import { LinkLevelCountItem } from '../LinkLeventCountItem';
import { AssetCountItem } from '../asset/AssetCountItem';
import { ContentCountItem } from '../content/ContentCountItem';

export class ProjectStats {
    public links: LinkLevelCountItem[] = [];
    public linkCount: number = 0;
    public events: number = 0;
    public inheritedAssets: number = 0;
    public assets: AssetCountItem;
    public contents: ContentCountItem[];
    public contentCount: number = 0;
    public removedLinks: number = 0;
    public processedLinks: number = 0;
    public channelCountStats: ChannelCountStats
    public reportsCount: number = 0;
    public activeSERPQueries: number = 0;
}


class ChannelCountStats
{
    totChannels: number = 0;
    totToOpenChannels: number = 0;
    totApprovedChannels: number = 0;
    totToApproveChannels: number = 0;
    totTerminateChannels: number = 0;
}
