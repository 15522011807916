import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filter', pure: true,
    standalone: true
})
export class FilterArrayPipe implements PipeTransform {
    transform(array: any[], filterFn: (x: any) => boolean): any[] {
        let newArr = [];
        for (let prop in array) {
            if (array.hasOwnProperty(prop)) {
                let val = array[prop];

                if (filterFn(val)) {
                    newArr.push(val);
                }
            }
        }

        return newArr;
    }
}