import { Pipe, PipeTransform } from "@angular/core";
import { RichEnum, RichEnumOption } from "../models/RichEnum";

@Pipe({
    name: 'valueOfEnum',
    standalone: true
})
export class ValueOfEnumPipe implements PipeTransform {
    transform(data: any, en: RichEnum, returnEnumEntry: boolean = false): any {
        if (data == null) {
            return RichEnum.prototype.getForValue.apply(en, [0]).toString();
        }

        let entry = RichEnum.prototype.getForValue.apply(en, [data]);
        if (returnEnumEntry) {
            return entry;
        } else {
            if (entry instanceof Array) {
                return (entry as RichEnumOption[]).map(option => option.toString());
            }

            return entry.toString();
        }
    }
}
