<div class="row">
    <!-- web form -->
    <div class="col-12">
        <div class="row align-items-center">
            <app-tri-state-checkbox class="mat-form-field-infix col-5 p-0"
                                    [name]="'link_web_form'"
                                    (ngModelChange)="toBeSaved = true"
                                    [(ngModel)]="params.webFormRequest">
                {{'TABLE_HEADINGS.REQUEST' | translate}} {{'CUSTOMER_LINK.IS_WEB_FORM_REQUEST' | translate}}
            </app-tri-state-checkbox>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('CUSTOMER_LINK.IS_WEB_FORM_REQUEST' | translate)"
                            name="link_web_form"
                            (selectionChange)="onActionResponseChange($event, false, 'webFormRequest')"
                            [(ngModel)]="params.webFormResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.WebFormStatus" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- guarantor -->
    <div class="col-12">
        <div class="row align-items-center">
            <app-tri-state-checkbox class="mat-form-field-infix col-5 p-0"
                                    [name]="'link_guarantor'"
                                    (ngModelChange)="toBeSaved = true"
                                    [(ngModel)]="params.authorityRequest">
                {{'TABLE_HEADINGS.REQUEST' | translate}} {{'CUSTOMER_LINK.GUARANTOR' | translate}}
            </app-tri-state-checkbox>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('CUSTOMER_LINK.GUARANTOR' | translate)"
                            name="authorityResponse"
                            (selectionChange)="onActionResponseChange($event,false, 'authorityRequest')"
                            [(ngModel)]="params.authorityResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.AuthorityStatus" [value]="option.value == 1">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- google -->
    <div class="col-12">
        <div class="row align-items-center">
            <app-tri-state-checkbox class="mat-form-field-infix col-5 p-0"
                                    [name]="'link_google'"
                                    (ngModelChange)="toBeSaved = true"
                                    [(ngModel)]="params.googleRequest">
                {{'TABLE_HEADINGS.REQUEST' | translate}} {{'TABLE_HEADINGS.SEARCH_ENGINES_GOOGLE' | translate}}
            </app-tri-state-checkbox>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('TABLE_HEADINGS.SEARCH_ENGINES_GOOGLE' | translate)"
                            name="instance_link_google"
                            (selectionChange)="onActionResponseChange($event, false, 'googleRequest')"
                            [(ngModel)]="params.googleResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- yahoo -->
    <div class="col-12">
        <div class="row align-items-center">
            <app-tri-state-checkbox class="mat-form-field-infix col-5 p-0"
                                    [name]="'link_yahoo'"
                                    (ngModelChange)="toBeSaved = true"
                                    [(ngModel)]="params.yahooRequest">
                {{'TABLE_HEADINGS.REQUEST' | translate}} {{'TABLE_HEADINGS.SEARCH_ENGINES_YAHOO' | translate}}
            </app-tri-state-checkbox>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('TABLE_HEADINGS.SEARCH_ENGINES_YAHOO' | translate)"
                            name="yahooResponse"
                            (selectionChange)="onActionResponseChange($event, false, 'yahooRequest')"
                            [(ngModel)]="params.yahooResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- bing -->
    <div class="col-12">
        <div class="row align-items-center">
            <app-tri-state-checkbox class="mat-form-field-infix col-5 p-0"
                                    [name]="'link_bing'"
                                    (ngModelChange)="toBeSaved = true"
                                    [(ngModel)]="params.bingRequest">
                {{'TABLE_HEADINGS.REQUEST' | translate}} {{'TABLE_HEADINGS.SEARCH_ENGINES_BING' | translate}}
            </app-tri-state-checkbox>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.RESULT' | translate) + ' ' + ('TABLE_HEADINGS.SEARCH_ENGINES_BING' | translate)"
                            name="bingResponse"
                            (selectionChange)="onActionResponseChange($event, false, 'bingRequest')"
                            [(ngModel)]="params.bingResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.SearchEngineStatus" [value]="option.value == 1">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- end actions -->

    <hr style="width: 100%" />

    <!-- status -->
    <div class="col-12" *ngIf="tab != SoftLegalTab.TODO">
        <div class="row align-items-center">
            <div class="col-5">
                <span>{{'TABLE_HEADINGS.STATUS' | translate}}</span>
            </div>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.STATUS' | translate)"
                            name="statusResponse"
                            [ngClass]="{'toBeSavedUnderline': toBeSavedStatus}"
                            (selectionChange)="onActionResponseChange($event, true, null)"
                            [(ngModel)]="params.status">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.SoftLegalLinkStatus" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- Workability -->
    <div class="col-12">
        <div class="row align-items-center m-0">
            <div class="col-5 p-0">
                <span>{{'TABLE_HEADINGS.WORKABILITY' | translate}}</span>
            </div>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.STATUS' | translate) + ' ' + ('TABLE_HEADINGS.WORKABILITY' | translate)"
                            name="workabilityResponse"
                            (ngModelChange)="toBeSaved = true"
                            [(ngModel)]="params.workability">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.InjuryLevel" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- Sucess fee -->
    <div class="col-12">
        <div class="row align-items-center m-0">
            <div class="col-5 p-0">
                <span>{{'TABLE_HEADINGS.SUCCESS_FEE' | translate}}</span>
            </div>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.STATUS' | translate) + ' ' + ('TABLE_HEADINGS.SUCCESS_FEE' | translate)"
                            name="successFeeResponse"
                            (ngModelChange)="toBeSaved = true"
                            [(ngModel)]="params.successFee">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.SuccessFeeStatus" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!--status instance-->
    <div *ngIf="tab == SoftLegalTab.Instance" class="col-12">
        <div class="row align-items-center m-0">
            <div class="col-5 p-0">
                <span>{{'TITLES.INSTANCE' | translate}}</span>
            </div>
            <mat-form-field class="col-7 pl-0">
                <mat-select [placeholder]="('TABLE_HEADINGS.STATUS' | translate) + ' ' + ('TITLES.INSTANCE' | translate)"
                            name="successFeeResponse"
                            (ngModelChange)="toBeSaved = true; toBeSavedStatus = true;"
                            [(ngModel)]="params.instanceResponse">
                    <mat-option [value]="null">Seleziona</mat-option>
                    <mat-option *ngFor="let option of enums.InstanceLinkStatus" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <hr style="width: 100%" class="mb-3 mt-3" />

    <!-- Button -->
    <div class="col-12">
        <div class="row justify-content-center">
            <mat-dialog-actions *ngIf="tab != SoftLegalTab.Instance && tab != SoftLegalTab.TODO" class="col-4" style="padding: 12px 6px">
                <button mat-button
                        class="uniumx-button level2-button"
                        (click)="onReturnToDo()">
                    <img class="mr-2" src="/assets/svg/material-icons/cached_blue.svg">
                    Riporta in ToDo
                </button>
            </mat-dialog-actions>
            <mat-dialog-actions class="col-4" style="padding: 12px 6px">
                <button mat-button
                        class="uniumx-button level2-button"
                        (click)="onReset()">
                    <img class="mr-2" src="/assets/svg/material-icons/cached_blue.svg">
                    Reset
                </button>
            </mat-dialog-actions>
            <mat-dialog-actions class="col-4" style="padding: 12px 6px">
                <button mat-button
                        class="uniumx-button level2-button"
                        [ngClass]="{'toBeSaved': toBeSaved}"
                        (click)="onSave()">
                    <img class="mr-2" src="/assets/svg/material-icons/save_blue.svg">
                    {{'BUTTONS.SAVE' | translate}}
                </button>
            </mat-dialog-actions>
        </div>
    </div>

    <!-- EVENTS/INSTANCE -->
    <div *ngIf="tab != SoftLegalTab.Instance">
        <hr style="width: 100%" class="mt-3" />
        <div class="col-12">
            <div class="row">
                <!--link to event-->
                <mat-dialog-actions matTooltip="{{'BUTTONS.LINK_TO_EVENT' | translate}}" class="col-4" style="padding: 12px 6px">
                    <button mat-raised-button color="primary"
                            class="uniumx-button level2-button"
                            (click)="alert.emit('linkEvent')">
                        <img src="/assets/svg/material-icons/link_grey.svg">
                        {{'BUTTONS.EVENT' | translate}}
                    </button>
                </mat-dialog-actions>
                <!--create instance-->
                <mat-dialog-actions matTooltip="{{'BUTTONS.CREATE_INSTANCE' | translate}}" class="col-4" style="padding: 12px 6px">
                    <button mat-raised-button color="primary"
                            class="uniumx-button level2-button"
                            (click)="alert.emit('createInstance')">
                        <img src="/assets/svg/material-icons/add_blue.svg">
                        {{'TITLES.INSTANCE' | translate}}
                    </button>
                </mat-dialog-actions>
                <!--add to instance-->
                <mat-dialog-actions matTooltip="{{'BUTTONS.ADD_TO_INSTANCE' | translate}}" class="col-4" style="padding: 12px 6px">
                    <button mat-raised-button color="primary"
                            class="uniumx-button level2-button"
                            (click)="alert.emit('addInstance')">
                        <img src="/assets/svg/material-icons/link_grey.svg">
                        {{'TITLES.INSTANCE' | translate}}
                    </button>
                </mat-dialog-actions>
            </div>
        </div>
    </div>


</div>
