import { Pipe, PipeTransform, Injectable, OnDestroy, ChangeDetectorRef, EventEmitter } from "@angular/core";
import {TranslateService, LangChangeEvent, TranslationChangeEvent, DefaultLangChangeEvent} from "@ngx-translate/core";

export interface MultiTranslation {
    [key: string]: string
} 

@Injectable()
@Pipe({
    name: 'translateMany',
    pure: false,
    standalone: true
})
export class TranslateManyPipe implements PipeTransform, OnDestroy {
    value: Promise<string> = Promise.resolve('');
    lastKeys: string[] = null;
    lastParams: any[] = null;
    onTranslationChange: any;
    onLangChange: any;
    onDefaultLangChange:any;

    constructor(private translate: TranslateService, private _ref: ChangeDetectorRef) {
        //this.translate.onLangChange.subscribe((ev: LangChangeEvent) => {
        //    ev.
        //});
    }

    updateValue(keys: string[], params?: Object, translations?: any): void {
        let onTranslation = (res: string | MultiTranslation) => {
            this.value = res !== undefined ? Promise.resolve(this.getTranslationsFromObject(res as MultiTranslation)) : Promise.resolve(keys.join());
            this.lastKeys = keys;
            this._ref.markForCheck();
        };

        if (translations) {
            let res = this.translate.getParsedResult(translations, keys, params);
            if (typeof res.subscribe === 'function') {
                res.subscribe(onTranslation);
            } else {
                onTranslation(res);
            }
        }
        this.translate.get(keys, params).subscribe(onTranslation);
    }

    transform(query: string[], separator: string = ' ', ...args: any[]): Promise<string> {

        if (!query || query.length === 0) {
            return Promise.resolve(query.join());
        }

        // if we ask another time for the same key, return the last value
        if ((this.lastKeys && query.every(i => this.lastKeys.includes(i))) && (this.lastParams || args.every(i => this.lastParams.includes(i)))) {
            return this.value;
        }

        // store the query, in case it changes
        this.lastKeys = query;

        // store the params, in case they change
        this.lastParams = args;

        // set the value
        this.updateValue(query, args);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this.onTranslationChange) {
            this.onTranslationChange = this.translate.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
                if (this.lastKeys && event.lang === this.translate.currentLang) {
                    this.lastKeys = null;
                    this.updateValue(query, args, event.translations);
                }
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                if (this.lastKeys) {
                    this.lastKeys = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(query, args, event.translations);
                }
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this.onDefaultLangChange) {
            this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(() => {
                if (this.lastKeys) {
                    this.lastKeys = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(query, args);
                }
            });
        }

        return this.value;

        //return new Promise((resolve, reject) => {
        //    this.translate.get(query.map(key => key.toString()))
        //        .subscribe(success => {
        //            let result = [];
        //            for (let prop in success) {
        //                result.push(success[prop]);
        //            }

        //            return resolve(result.join(separator));
        //        });
        //});

    }

    private getTranslationsFromObject(success: { [key: string]: string }, separator = ' '): string {
        let result = [];
        for (let prop in success) {
            result.push(success[prop]);
        }

        return result.join(separator);
    }

    /**
   * Clean any existing subscription to change events
   */
    private _dispose(): void {
        if (typeof this.onTranslationChange !== 'undefined') {
            this.onTranslationChange.unsubscribe();
            this.onTranslationChange = undefined;
        }
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
        if (typeof this.onDefaultLangChange !== 'undefined') {
            this.onDefaultLangChange.unsubscribe();
            this.onDefaultLangChange = undefined;
        }
    }

    ngOnDestroy(): void {
        this._dispose();
    }
}
