<!-- AUTOMATICA -->
<div *ngIf="type == typeSerp.SERPAUTOMATIC" class="row mt-4 mx-0">
    <!-- citta o nazione -->
    <div class="col-sm-12">
        <div class="row justify-content-center">
            <mat-button-toggle-group value="it"name="fontStyle" aria-label="Font Style">
                <mat-button-toggle (click)="changeCity('it')" value="it">Italia</mat-button-toggle>
                <mat-button-toggle (click)="changeCity('all')" value="all">Mondo</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <!-- IT -->
        <mat-form-field class="w-100" *ngIf="showIT">
            <mat-label>Città</mat-label>
            <input type="text"
                   name="cityAC"
                   placeholder="Scrivi e seleziona dal menu a tendina"
                   matInput
                   required
                   [formControl]="myControlCityIT"
                   [matAutocomplete]="autoCity">
            <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="getCity($event.option.value)" [displayWith]="displayOption">
                <mat-option *ngFor="let option of filteredCitiesIT | async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
            <mat-error>Inserire una città valida</mat-error>
        </mat-form-field>
        <!-- All -->
        <mat-form-field class="w-100" *ngIf="!showIT">
            <mat-label>Città</mat-label>
            <input type="text"
                   name="cityAC"
                   placeholder="Scrivi e seleziona dal menu a tendina"
                   matInput
                   required
                   [formControl]="myControlCityAll"
                   [matAutocomplete]="autoCity">
            <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="getCity($event.option.value)" [displayWith]="displayOption">
                <mat-option *ngFor="let option of filteredCitiesAll | async" [value]="option">
                    {{option.text}}
                </mat-option>
            </mat-autocomplete>
            <mat-error>Inserire una città valida</mat-error>
        </mat-form-field>
    </div>
    <!-- browser -->
    <div class="col-sm-3">
        <mat-form-field class="rm-paginator-select p-0 w-100">
            <mat-label>{{ 'Browser' | translate }}</mat-label>
            <mat-select [formControl]="myControlBrowser" [(ngModel)]="BrowserQuery" required>
                <mat-option *ngFor="let option of browserList" [value]="option" class="small-mat-option">{{option.text}}</mat-option>
            </mat-select>
            <mat-error>Inserire un valore</mat-error>
        </mat-form-field>
    </div>
    <!-- City -->
    <div class="col-sm-3">
        <mat-form-field class="rm-paginator-select p-0 w-100">
            <mat-label>{{ 'Nazione' | translate }}</mat-label>
            <mat-select [formControl]="myControlGl" [(ngModel)]="GLQuery"  required>
                <mat-option *ngFor="let option of glList" [value]="option" class="small-mat-option">{{option.text}}</mat-option>
            </mat-select>
            <mat-error>Inserire un valore</mat-error>
        </mat-form-field>
    </div>
    <!-- dominio -->
    <div class="col-sm-6">
        <mat-form-field class="rm-paginator-select p-0 w-100">
            <mat-label>Motore</mat-label>
            <input type="text"
                   name="domain"
                   placeholder="Scrivi e seleziona dal menu a tendina"
                   matInput
                   required
                   [formControl]="myControlDomain"
                   [matAutocomplete]="autoDomain">
            <mat-autocomplete #autoDomain="matAutocomplete" (optionSelected)="getDomain($event.option.value)" [displayWith]="displayOptionValue">
                <mat-option *ngFor="let option of filteredDomain | async" [value]="option">{{option.value}}</mat-option>
            </mat-autocomplete>
            <mat-error>Inserire una dominio</mat-error>
        </mat-form-field>
    </div>
    <!-- keyword -->
    <div class="col-sm-12">
        <form>
            <mat-form-field class="w-100">
                <mat-label>Keyword</mat-label>
                <input type="text"
                       required
                       name="keyword"
                       [(ngModel)]="keyword"
                       [formControl]="myControlKeyword"
                       placeholder="Keyword"
                       matInput>
                <mat-error>Inserire una o più parole</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>
<!-- END AUTOMATICA -->
<!-- MANUALE -->
<div *ngIf="type == typeSerp.SERPMANUAL" class="row mt-4 mx-0">
    <div class="col-12">
        <mat-form-field style="width: 100%" class="mt-4">
            <input [(ngModel)]="queryUrlManual" matInput placeholder="{{'SERP.QUERY_URL' | translate}}" />
        </mat-form-field>
    </div>
    
</div>
<!-- END MANUALE -->

<!-- DESCRIZIONE E FREQUENZA -->
<div class="row mx-0" *ngIf="!isEdit">
    <div class="col-12">
        <!-- description -->
        <mat-form-field style="width: 100%;">
            <input [formControl]="myControlDescription" [(ngModel)]="description" matInput placeholder="{{'SERP.NOTE' | translate}}" />
        </mat-form-field>
        <div class="row my-4">
            <!-- ACTIVATION DATE -->
            <div class="col-6 d-flex">
                <span class="text-bold text-nowrap mr-2">{{'SERP.ACTIVATION_DATE' | translate}}: </span>
                <span class="long-text">{{insertDate | date:nativeDateFormat}}</span>
            </div>
            <!-- FREQUENCY -->
            <div class="col-6 d-flex align-items-center">
                <span class="text-bold text-nowrap mr-2">{{'SERP.FREQUENCY' | translate}}: </span>
                <mat-select  [formControl]="myControlFrequency" [(ngModel)]="frequency">
                    <mat-option *ngFor="let option of enums.SERPFrequency" [value]="option.value">
                        {{option.name | translate}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>
<!-- SAVE AND RESET -->
<div class="col-12">
    <p class="error-text" *ngIf="showError">Compilare tutti i campi</p>
</div>
<div class="col-12">
    <div class="d-flex">
        <div class="col-sm-6">
            <button mat-raised-button color="accent" (click)="reset()" class="w-100 my-2 text-bold level2-button">
                <img src="/assets/svg/material-icons/cached_blue.svg" /> Reset
            </button>
        </div>
        <div class="col-sm-6" *ngIf="!isEdit">
            <button mat-raised-button color="primary" (click)="getQuery(type)" class="w-100 my-2 text-bold level2-button">
                <img src="/assets/svg/material-icons/save_blue.svg" /> Crea query
            </button>
        </div>
        <div class="col-sm-6" *ngIf="isEdit">
            <button mat-raised-button color="primary" (click)="getQuery(type)" class="w-100 my-2 text-bold level2-button">
                <img src="/assets/svg/material-icons/save_blue.svg" /> Aggiorna query
            </button>
        </div>
    </div>
</div>