import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'enumToArray',
    standalone: true
})
export class EnumToArrayPipe implements PipeTransform {
    transform(data: Object) {
        let result = [];
        const keys = Object.keys(data);
        let actualKeys = keys.slice(keys.length / 2);
        for (let key in actualKeys) {
            let keyN: number = Number.parseInt(key);
            result[keyN] = { id: keyN, value: data[key] };
        }

        return result;
    }
}