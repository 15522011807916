import { UserGroup } from "../DomainUser";

export interface TableAction {
    iconPath: string;
    //text before button
    label?: string;
    //text inside button
    btnText?: string;
    tooltip: string;
    style?: string;
    btnStyle?: string;
    enabled: boolean;
    allowed: boolean;
    type: TableActionType;
    genericType: TableActionGenericType;
    allowedFor: UserGroup[];
}

export enum TableActionGenericType {
    JsonExport,
    ExportAll,
    ExportMany,
    ExportDefault,
    DeleteMany,
    LinkMany,
    GenericEdit,
    MassiveContents,
    EditMany,
    ConvertMany
}

export enum TableActionType  {
    JsonExport,
    // Export all
    ContactsReportAll,
    SalesReportAll,
    LegalReportAll,
    ExportDefaultAll,
    TTDReportAll,
    ExportSoftLegalLinksAll,
    ExportSERPLinksAll,
    ContactsReportMany,
    SalesReportMany,
    LegalReportMany,
    ExportDefaultMany,
    ExportRelatedDomains,
    DeleteMany,
    MassiveContents,
    EditMany,
    ConvertMany,
    ExportFiltered,
    DeleteManyBR,
    DeleteManyContactDocument,
    MassiveSoftLegal
}

export class TableActions {
    /*
     * Home - Json
     */
    public static GetApiToken: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/link_grey.svg',
        label: 'Sales',
        tooltip: 'BUTTONS.JSON_EXPORT',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.JsonExport,
        genericType: TableActionGenericType.JsonExport,
        allowedFor: [UserGroup.Sales, UserGroup.Admin]
    };

    /*
     * Home - Contact
     */
    public static ContactsGenericReportAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        label: 'Report: Contacts',
        tooltip: 'BUTTONS.EXPORT_XLS',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.ContactsReportAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    public static SalesReportAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:   '/assets/svg/xls_all-icon-grey.svg',
        label:      'Sales',
        tooltip:    'BUTTONS.SALES_REPORT_ALL',
        style:      'add_btn_exportAll_icon',
        type:        TableActionType.SalesReportAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Sales]
    };

    /*
     * Home - Legal
     */
    public static LegalReportAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls_all-icon-grey.svg',
        label:       'Legal',
        tooltip:     'BUTTONS.LEGAL_REPORT_ALL',
        style:       'add_btn_exportAll_icon',
        type:        TableActionType.LegalReportAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    public static ExportDefaultAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls_all-icon-grey.svg',
        tooltip:     'BUTTONS.EXPORT_ALL_XLS',
        style:       'add_btn_exportAll_icon',
        type:        TableActionType.ExportDefaultAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor:  [UserGroup.Any]
    };

    public static ExportContentsAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        tooltip: 'BUTTONS.EXPORT_CONTENTS',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.ExportFiltered,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    /*
     * Customer - Contents
     */
    public static ExportFiltered: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls_all-icon-grey.svg',
        tooltip:     'BUTTONS.EXPORT_ALL_XLS',
        style:       'add_btn_exportAll_icon',
        type: TableActionType.ExportFiltered,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    public static TTDReportAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls_all-icon-grey.svg',
        tooltip:     'BUTTONS.EXPORT_ALL_XLS',
        style:       'add_btn_exportAll_icon ml-2',
        type:        TableActionType.TTDReportAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor:  [UserGroup.Any]
    };

    public static ExportRelatedDomains: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls-icon-grey.svg',
        tooltip: 'BUTTONS.EXPORT_XLS',
        style: 'add_btn_icon',
        type: TableActionType.ExportRelatedDomains,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Any]
    };

    // EXPORT MANY

    /*
     * Home - Contact
     */
    public static ContactsGenericReportMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls-icon-grey.svg',
        label: 'Report: Contacts',
        tooltip: 'BUTTONS.EXPORT_XLS',
        style: 'add_btn_icon',
        type: TableActionType.ContactsReportMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    public static SalesReportMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls-icon-grey.svg',
        label:       'Sales',
        tooltip:     'BUTTONS.SALES_REPORT',
        style:       'add_btn_icon',
        type:        TableActionType.SalesReportMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor:  [UserGroup.Sales]
    };

    /*
     * Home - Legal
     */
    public static LegalReportMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls-icon-grey.svg',
        label:       'Legal',
        tooltip:     'BUTTONS.LEGAL_REPORT',
        style:       'add_btn_icon',
        type:        TableActionType.LegalReportMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    /*
     * Customer - Content
     */
    public static ExportDefaultMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/xls-icon-grey.svg',
        tooltip:     'BUTTONS.EXPORT_XLS',
        style:       'add_btn_icon',
        type:        TableActionType.ExportDefaultMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    // DELETE

    public static DeleteMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/material-icons/delete_grey.svg',
        tooltip:     'BUTTONS.DELETE',
        style:       '',
        type:        TableActionType.DeleteMany,
        genericType: TableActionGenericType.DeleteMany,
        allowedFor: [UserGroup.Any]
    };

    public static DeleteManyBR: TableAction = {
        enabled: true,
        allowed: false,
        iconPath:    '/assets/svg/material-icons/delete_grey.svg',
        tooltip:     'BUTTONS.DELETE',
        style:       '',
        type: TableActionType.DeleteManyBR,
        genericType: TableActionGenericType.DeleteMany,
        allowedFor: [UserGroup.Any]
    };

    public static DeleteManyContactDocument: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/delete_grey.svg',
        tooltip: 'BUTTONS.DELETE',
        style: '',
        type: TableActionType.DeleteManyContactDocument,
        genericType: TableActionGenericType.DeleteMany,
        allowedFor: [UserGroup.Any]
    };

    public static ExportEventsAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        tooltip: 'REPORTS.EVENTS_ALL',
        label: 'REPORTS.EVENTS_ALL',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.ExportDefaultAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    public static ExportEventsMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls-icon-grey.svg',
        tooltip: 'REPORTS.EVENTS',
        label: 'REPORTS.EVENTS',
        style: 'add_btn_icon',
        type: TableActionType.ExportDefaultMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    /*
     * Customer - Feasability
     */
    public static ExportContactLinksAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        tooltip: 'REPORTS.CONTACT_LINKS_ALL',
        label: 'REPORTS.CONTACT_LINKS_ALL',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.ExportDefaultAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    /*
     * Customer - Feasability
     */
    public static ExportContactLinksMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls-icon-grey.svg',
        tooltip: 'REPORTS.CONTACT_LINKS',
        label: 'REPORTS.CONTACT_LINKS',
        style: 'add_btn_icon',
        type: TableActionType.ExportDefaultMany,
        genericType: TableActionGenericType.ExportMany,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Admin]
    };

    /*
     * Customer - SoftLegal
     */
    public static ExportSoftLegalLinksAll: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        tooltip: 'REPORTS.SOFT_LEGAL_LINKS_ALL',
        style: 'add_btn_exportAll_icon',
        label: 'REPORTS.SOFT_LEGAL_LINKS_ALL',
        type: TableActionType.ExportSoftLegalLinksAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.Admin]
    };

    /*
     * Customer - SERP
     */
    public static ExportSERPLinksAll: TableAction = {
        enabled: true,
        allowed: true,
        iconPath: '/assets/svg/xls_all-icon-grey.svg',
        tooltip: 'BUTTONS.EXPORT_ALL_SERP',
        style: 'add_btn_exportAll_icon',
        type: TableActionType.ExportSERPLinksAll,
        genericType: TableActionGenericType.ExportAll,
        allowedFor: [UserGroup.Legal, UserGroup.Sales, UserGroup.Account, UserGroup.SEO, UserGroup.Writers, UserGroup.Admin]
    };

    //generic edit popup

    public static MassiveContents: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/check_gray.svg',
        tooltip: 'BUTTONS.MASSIVE_OPERATIONS',
        style: '',
        type: TableActionType.MassiveContents,
        genericType: TableActionGenericType.DeleteMany,
        allowedFor: [UserGroup.Any]
    }

    //generic edit popup

    public static EditMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/check_gray.svg',
        tooltip: 'BUTTONS.MASSIVE_OPERATIONS',
        style: '',
        type: TableActionType.EditMany,
        genericType: TableActionGenericType.EditMany,
        allowedFor: [UserGroup.Any],
    }

    //Convert Many

    public static ConvertMany: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/publish_blue.svg',
        tooltip: 'BUTTONS.CONVERT_TO_ASSET',
        style: '',
        type: TableActionType.ConvertMany,
        genericType: TableActionGenericType.ConvertMany,
        allowedFor: [UserGroup.Any],
    }

    public static MassiveSoftLegal: TableAction = {
        enabled: true,
        allowed: false,
        iconPath: '/assets/svg/material-icons/check_gray.svg',
        tooltip: 'BUTTONS.MASSIVE_OPERATIONS',
        style: '',
        type: TableActionType.MassiveSoftLegal,
        genericType: TableActionGenericType.GenericEdit,
        allowedFor: [UserGroup.Any],
    }
}
