export interface IValidators {
    phonePattern: string;
    link:         string;
    noDomainLink: string;
}

export const VALIDATORS: IValidators = {
    phonePattern: '^\\+\\d{2} \\d{3} \\d{10}$|^\\d{2} \\d{10}$|^\\d{12}$|^\\(\\d{3}\\)-\\d{8}$',
    link:         '(?!https?:\\/\\/).+',
    noDomainLink: '(?!https?:\\/\\/).+'
};
