import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ContentModel, SoftLegalTab } from 'src/app/instruments/models';
import { SoftLegalMassiveParams } from '../soft-legal-massive/soft-legal-massive.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { AlertService, NotificationService, SoftLegalService } from 'src/app/instruments/services';
import { MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

class SuccessFeeMassiveParams extends SoftLegalMassiveParams{
  successFeeReported: boolean = null
}


@Component({
  selector: 'success-fee-massive',
  standalone: true,
  imports: [MatCheckbox, FormsModule, MatLabel, MatDialogActions, TranslateModule, MatButton],
  templateUrl: './success-fee-massive.component.html',
  styleUrl: './success-fee-massive.component.css'
})
export class SuccessFeeMassiveComponent implements OnInit {
  @Input() data: {link: ContentModel[]}
  @Input() tab: SoftLegalTab;
  @Output() ok: EventEmitter<any> = new EventEmitter<any>();
  @Output() alert: EventEmitter<string> = new EventEmitter<string>();

  service = inject(SoftLegalService);
  snackbar = inject(NotificationService);
  alertService = inject(AlertService);

  _ids: { customerId: number, contentId: number }[] = [];
  params: SuccessFeeMassiveParams;
  
  ngOnInit(): void {
    this.data.link.map(l => {
      this._ids.push({
          customerId: l.softLegal.contactId,
          contentId: l.softLegal.contentId
      })
    });
    this.params = new SuccessFeeMassiveParams(this._ids)

    console.log(this.params)
  }


  onSave(){
    this.alertService.prompt().subscribe(res => {
        if (res === 'ok') {
            this.save();
        }
    });
            
  }

  private save(){
    this.service.MassiveUpdate(this.params).subscribe({
      next: () => this.ok.emit(),
      error: () => this.snackbar.error()
    });
  }
  
}
