import {Pipe, ChangeDetectorRef, PipeTransform, OnDestroy, NgZone, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent, TranslationChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';

const momentConstructor = moment;

@Pipe({
    name: 'timeAgo', pure: false,
    standalone: true
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private currentTimer: number | null;

    private lastTime: Number;
    private lastValue: moment.MomentInput;
    private lastOmitSuffix: boolean;
    private lastLocale?: string;
    private lastText: string;
    
    onLangChange: any;
    onDefaultLangChange: any;

    constructor(
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private translate: TranslateService
    ) {
    }

    transform(value: moment.MomentInput, omitSuffix: boolean = false): string {
        if (this.hasChanged(value, omitSuffix)) {
            this.lastTime = this.getTime(value);
            this.lastValue = value;
            this.lastOmitSuffix = omitSuffix;
            this.lastLocale = this.getLocale();
            this.removeTimer();
            this.createTimer();
            this.updateValue(value, omitSuffix);
        } else {
            this.createTimer();
        }

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        if (!this.onDefaultLangChange) {
            this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(() => {
                if (this.lastText) {
                    this.lastText = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(value, omitSuffix);
                }
            });
        }

        if (!this.onLangChange) {
            this.onLangChange = this.translate.onLangChange.subscribe(() => {
                if (this.lastText) {
                    this.lastText = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(value, omitSuffix);
                }
            });
        }

        return this.lastText;
    }

    ngOnDestroy(): void {
        this.removeTimer();
        this._dispose();
    }

    private _dispose(): void {
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
        if (typeof this.onDefaultLangChange !== 'undefined') {
            this.onDefaultLangChange.unsubscribe();
            this.onDefaultLangChange = undefined;
        }
    }

    private updateValue(value: moment.MomentInput, omitSuffix: boolean = false) {
        this.lastText = momentConstructor(value).from(momentConstructor(), omitSuffix);
    }

    private createTimer() {
        if (this.currentTimer) {
            return;
        }

        const momentInstance = momentConstructor(this.lastValue);
        const timeToUpdate = this.getSecondsUntilUpdate(momentInstance) * 1000;

        this.currentTimer = this.ngZone.runOutsideAngular(() => {
        if (typeof window !== 'undefined') {
            return window.setTimeout(() => {
                this.lastText = momentConstructor(this.lastValue).from(momentConstructor(), this.lastOmitSuffix);

                this.currentTimer = null;
                this.ngZone.run(() => this.cdRef.markForCheck());
            }, timeToUpdate);
        } else {
            return null;
        }});
    }

    private removeTimer() {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }

    private getSecondsUntilUpdate(momentInstance: moment.Moment) {
        const howOld = Math.abs(momentConstructor().diff(momentInstance, 'minute'));
        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }

    private hasChanged(value: moment.MomentInput, omitSuffix?: boolean): boolean {
        return this.getTime(value) !== this.lastTime
        || this.getLocale() !== this.lastLocale
        || omitSuffix !== this.lastOmitSuffix;
    }

    private getTime(value: moment.MomentInput): number {
        if (moment.isDate(value)) {
            return value.getTime();
        } else if (moment.isMoment(value)) {
            return value.valueOf();
        } else {
            return momentConstructor(value).valueOf();
        }
    }

    private getLocale(): string {
        return this.translate.currentLang;
    }
}
