import { inject, Injectable } from '@angular/core';
import { SoftLegalMassiveParams } from '../../pages/project-details/soft-legal/soft-legal-massive/soft-legal-massive.component';
import {InstanceLinkStatus, JsonReply, SoftLegal, SoftLegalLinkStatus, SuccessFeeStatus, WebFormStatus} from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SoftLegalService {
    httpClient = inject(HttpClient)
    
    constructor() { }

    MassiveUpdate(params: SoftLegalMassiveParams){
        return this.httpClient.post<JsonReply<boolean>>('/api/SoftLegal/MassiveUpdate', params)
    }

    SoftLegalBadgeIsGreen(status: number | SoftLegalLinkStatus): boolean {
        let isGreen = null;
        switch (status) {
            case SoftLegalLinkStatus.Anonymized:
            case SoftLegalLinkStatus.Deleted:
            case SoftLegalLinkStatus.UnIndexed:
            case SoftLegalLinkStatus.Updated:
            case SoftLegalLinkStatus.Blocked:
                isGreen = true;
                break;
            case SoftLegalLinkStatus.Refused:
                isGreen = false;
                break;
            default:
                isGreen = null;
        }
        return isGreen;
    }

    // controlliamo che lo status generico rispetti quello delle azioni
    CheckForStatusChanged(status: number | SoftLegalLinkStatus, action: SoftLegal | SoftLegalMassiveParams) {
        let save = false;

        switch (status) {
            case SoftLegalLinkStatus.Blocked:
                save = action.googleResponse ||
                    action.yahooResponse ||
                    action.bingResponse ||
                    action.webFormResponse === WebFormStatus.Blocked;
                break;

            case SoftLegalLinkStatus.Deleted:
                save = action.webFormResponse === WebFormStatus.Cancelled || action.instanceResponse === InstanceLinkStatus.Deleted;
                break;

            case SoftLegalLinkStatus.Anonymized:
                save = action.instanceResponse === InstanceLinkStatus.Anonymized;
                break;

            case SoftLegalLinkStatus.UnIndexed:
                save = action.instanceResponse === InstanceLinkStatus.UnIndexed;
                break;

            case SoftLegalLinkStatus.Updated:
                save = action.instanceResponse === InstanceLinkStatus.Updated;
                break;

            case SoftLegalLinkStatus.Refused:
                save = action.googleResponse === false ||
                        action.yahooResponse === false ||
                        action.bingResponse === false ||
                        action.webFormResponse === WebFormStatus.Rejected ||
                        action.instanceResponse === InstanceLinkStatus.Refused;
                break;

            default:
                save = true;
                break;
        }

        return save;
    }

    /*
    * Da rendicontare(SuccessFeeToBeReported) è un campo privato e derivato con queste logiche
    * https://bt.prod.reputation-manager.it/view.php?id=17378
    * Quando diventa "da rendicontare"? 
    * quando nel campo "success fee" è selezionato "da contratto con success fee" o "senza contratto con success fee" 
    * e lo status generale del link diventa "anonimizzato/cancellato/deindicizzato/aggiornato/bloccato"
    */
    public isSuccessFeeToBeReported(entity: SoftLegal):boolean{
        //se è gia rendicontata non può essere da rendicontare
         if (entity.successFeeReported)
             return false;
        //se non ha success fee non può essere da rendicontare
        if(entity.successFee == SuccessFeeStatus.Unknown || entity.successFee == SuccessFeeStatus.Contract_NoSuccessFee)
            return false;
        //se ha questi status non può essere da rendicontare 
        if (entity.status == SoftLegalLinkStatus.Any || 
            entity.status == SoftLegalLinkStatus.Unknown || 
            entity.status == SoftLegalLinkStatus.Refused)
                return false;
        
        return true;

    }

}
