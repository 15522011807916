export interface GraphNodeData<TKey, TValue> {
    id: TKey,
    name: string,
    value: TValue,
    linkWith: TKey[],
    children?: GraphNodeData<TKey, TValue>[],
    color: any,
    relation: number
}

export interface CustomerGraphNodeData extends GraphNodeData<string, number> {

}