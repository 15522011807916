<div class="row">
    <div class="col-12">
        <!-- Success Fee reported -->
        <mat-checkbox class="mat-form-field-infix col-12 mt-2"
                    [(ngModel)]="params.successFeeReported"
                    name="successFeeReported">
          <mat-label>Rendiconta e manda e-mail</mat-label>
      </mat-checkbox>
    </div>
    <div class="col-12 text-center">
        <mat-dialog-actions class="col-4" style="padding: 12px 6px">
            <button mat-button
                    class="uniumx-button level2-button"
                    (click)="onSave()">
                <img class="mr-2" src="/assets/svg/material-icons/save_blue.svg">
                {{'BUTTONS.SAVE' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</div>
