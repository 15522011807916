import { CdkTableModule } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from "@angular/router";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HighchartsChartModule } from 'highcharts-angular';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, Config } from './app.constants';
import { routes } from "./app.routes";
import { MaterialModule } from './instruments/material.module';
import { ServicesModule } from './instruments/services/services.module';
import { SignalRService } from './instruments/services/signalr.service';

function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withViewTransitions()),
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, MaterialModule, ServicesModule, CdkTableModule, HighchartsChartModule, AngularEditorModule, TranslateModule.forRoot({
            loader: {
                deps: [HttpClient],
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
            },
        })),
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5500, horizontalPosition: 'right' } },
        { provide: APP_CONFIG, useValue: Config },
        CookieService,
        DatePipe,
        SignalRService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
};