import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
    JsonReply,
    JsonReplyWithParams,
    LinkManyEntitiesParams,
    BRLinkManyEntitiesParams,
    GetCustomerLinkParams,
    ProjectLink,
    ChangeLinkStatusParams,
    ContentModel,
    BRCustomerContentId
} from '../models';
import {Route, RoutePrefix} from '../helpers/routeDecorators';
import {SharedService} from "./shared.service";


@Injectable()
@RoutePrefix('/api/SoftLegal')
export class ProjectLinkService {
    constructor(private httpClient: HttpClient,
                private shared: SharedService) {
    }

    @Route('ForCustomer')
    public getCustomerLinks(param: GetCustomerLinkParams, url: string = ''): Promise<JsonReplyWithParams<ProjectLink[], GetCustomerLinkParams>> {
        return this.httpClient.post<JsonReplyWithParams<ProjectLink[], GetCustomerLinkParams>>(url, param)
            .toPromise();
    }

    //@Route('WebFormForCustomer')
    //public getCustomerWebFormLinks(param: GetCustomerLinkParams, url: string = ''): Promise<JsonReplyWithParams<ProjectLink[], GetCustomerLinkParams>> {
    //    return this.httpClient.post<JsonReplyWithParams<ProjectLink[], GetCustomerLinkParams>>(url, param)
    //        .toPromise();
    //}

    @Route('ImportToSoftLegal')
    public importToSoftLegal(brList: BRCustomerContentId[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, brList)
            .toPromise();
    }

    @Route('AddWithContent')
    public addCustomerLink(link: ContentModel, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, link)
            .toPromise();
    }

    @Route('ById')
    public getById(linkId: number, url: string = ''): Promise<JsonReply<ContentModel>> {
        return this.httpClient.post<JsonReply<ContentModel>>(url, linkId)
            .toPromise();
    }

    @Route('Delete')
    public deleteCustomerLink(id: number, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, id)
            .toPromise();
    }

    @Route('Update')
    public updateCustomerLink(link: ProjectLink, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, link)
            .toPromise();
    }

    @Route('DeleteMany')
    public deleteLinks(idList: number[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, idList)
            .toPromise();
    }

    @Route('ForInstance')
    public getForInstance(instanceId: number, url: string = ''): Promise<JsonReply<ProjectLink[]>> {
        return this.httpClient.post<JsonReply<ProjectLink[]>>(url, instanceId)
            .toPromise();
    }

    @Route('GroupToInstance')
    public groupToInstance(ids: BRCustomerContentId[], url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, ids)
            .toPromise();
    }

    @Route('LinkManyToInstance')
    public linkManyToInstance(param: LinkManyEntitiesParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param)
            .toPromise();
    }

    @Route('LinkManyToEvent')
    public linkManyToEvent(param: LinkManyEntitiesParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param).toPromise();
    }

    @Route('UnlinkFromInstance')
    public unlinkFromInstance(param: BRLinkManyEntitiesParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param)
            .toPromise();
    }

    @Route('ExportMany')
    public exportLinks(idList: number[], url: string = ''): void {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        headers = headers.append('IdList', idList.join(','));

        this.httpClient.get(url, {headers: headers, responseType: 'blob'}).subscribe((resp: any) => {
            this.shared.downloadBlobFile(resp, 'soft_legal_links.xlsx');
        });
    }

    @Route('ExportLinks')
    public exportSoftLegalReport(customerId: number, url: string = ''): Promise<boolean> {
        return this.shared.downloadFile(url + '?customerId=' + customerId, 'should_have_filename');
    }

    @Route('ExportSuccessFee')
    public exportSuccessFeeReport(customerId: number, url: string = ''): Promise<boolean> {
        return this.shared.downloadFile(url + '?customerId=' + customerId, 'should_have_filename');
    }

    @Route('ChangeStatus')
    public changeStatus(param: ChangeLinkStatusParams, url: string = ''): Promise<JsonReply<any>> {
        return this.httpClient.post<JsonReply<any>>(url, param)
            .toPromise();
    }
}
