//import {OnlineContent} from '../../instruments/models/content/OnlineContent';
import { CustomerEvent } from '../../instruments/models/customer/CustomerEvent';
import {AssetTag} from '../../instruments/models/asset/AssetTag';
import {DomainUser} from '../../instruments/models/DomainUser';
import {PopupTableType, SoftLegalTab} from '../../instruments/models/Enums';
//import {Asset} from '../../instruments/models/asset/Asset';
import {Recall} from '../../instruments/models/Recall';
import {Domain} from '../../instruments/models/Domain';
import {LinkManyEntitiesParams} from '../../instruments/models/api/LinkEntitiesParams';
import { Instance } from '../../instruments/models/project/Instance';
import { ProjectLink } from '../../instruments/models/project/ProjectLink';
import { Contact } from '../../instruments/models/customer/Customer';
import { FindContactsParams } from '../../instruments/models/api/FindContactsParams';
import { JsonReplyWithParams } from '../../instruments/models/api/JsonReply';
import { LinkContactsParams, LinkContactsParamsWithNumber } from '../../instruments/models/api/LinkContactsParams';
import { AcquisitionRequest, ContentModel, CustomerStartActivity, RequestPreAnalysisDateParams, SERPLinkPositionTrend, SERPQuery, UsersCustomer } from '../../instruments/models';

export class AlertData {
    constructor(public title: string,
                public showCancelBtn: boolean,
                public icon: string,
                public success: boolean,
                public type: string,
                public randomString?: string,
                public afterTitle?:string) { }
}

export class PromptAlertData extends AlertData {
    constructor(public promptTitle: string) {
        super(promptTitle, true, 'help_blue', true, 'default');
    }
}

export class MassiveAlertData extends AlertData {
    constructor(public promptTitle: string) {
        super(promptTitle, true, 'help_blue', true, 'massiveImport');
    }
}

export class DeleteAlertData extends AlertData {
    constructor(public promptTitle: string, public translateParams: any) {
        super(promptTitle, true, 'help_blue', true, 'default');
    }
}

export class ErrorAlertData extends AlertData {
    constructor(public errorTitle: string) {

        super(errorTitle, false, 'report_problem_red', false, 'default');
    }
}

export class ApiLinkData extends AlertData {
    constructor(public token: string, public link: string, public linkTitle: string) {
        super(linkTitle, false, null, true, 'apiLink');
    }
}

export class SuccessAlertData extends AlertData {
    constructor(public successTitle: string) {

        super(successTitle, false, 'check_circle_green', true, 'default');
    }
}

export class EventAlertData extends AlertData {
    constructor(public event: CustomerEvent,
                public eventTitle: string) {

        super(eventTitle, false, null, true, 'event');
    }
}

export class DomainAlertData extends AlertData {
    constructor(public domain: Domain,
                public title: string) {

        super(title, false, null, true, 'domain');
    }
}

export class LinkEventAlertData extends AlertData {
    constructor(public customerId: number,
                public entityId: number,
                public tableType: PopupTableType,
                public title: string) {

        super(title, false, null, true, 'linkEntity');
    }
}

export class ContentAlertData extends AlertData {
    constructor(public customerId: number,
                public entityId: number,
                public tableType: PopupTableType,
                public eventTitle: string) {

        super(eventTitle, false, null, true, 'linkEntity');
    }
}

export class AssetAlertData extends AlertData {
    constructor(public customerId: number,
                public entityId: number,
                public tableType: PopupTableType,
                public title: string) {

        super(title, false, null, true, 'linkEntity');
    }
}

export class LinkDomainAlertData extends AlertData {
    constructor(public entityId: number,
                public tableType: PopupTableType,
                public title: string) {

        super(title, false, null, true, 'linkEntity');
    }
}

export class LinkAlertData extends AlertData {
    constructor(public linkTitle: string,
        public link: ContentModel,
                public tableType: PopupTableType,
                public alertService: any) {

        super(linkTitle, false, null, true, 'link');
    }
}

export class TagAlertData extends AlertData {
    constructor(public tagTitle: string,
                public tag: AssetTag) {

        super(tagTitle, false, null, true, 'tag');
    }
}

export class RecallAlertData extends AlertData {
    constructor(public recall: Recall,
                public recallTitle: string,
                public compareFn: (a: DomainUser, b: DomainUser) => boolean) {

        super(recallTitle, false, null, true, 'contactRecall');
    }
}

export class CustomerRecallAlertData extends AlertData {
    constructor(public recall: Recall,
                public recallTitle: string,
                public compareFn: (a: DomainUser, b: DomainUser) => boolean) {

        super(recallTitle, false, null, true, 'customerRecall');
    }
}

export class ReminderAlertData extends AlertData {
    constructor(public recall: Recall,
                public recallTitle: string,
                public compareFn: (a: DomainUser, b: DomainUser) => boolean) {
        super(recallTitle, false, null, true, 'customerReminder');
    }
}

export class ValueAlertData extends AlertData {
    constructor(public entity: ContentModel,
                public title: string) {

        super(title, false, null, true, 'value');
    }
}

export class InstanceAlertData extends AlertData {
    constructor(public params: LinkManyEntitiesParams,
                private instances: Instance[],
                public title: string) {

        super(title, false, null, true, 'selectInstance');
    }
}

export class SelectEventAlertData extends AlertData {
    constructor(public params: LinkManyEntitiesParams,
        public events: CustomerEvent[],
                public title: string
    ) {
        super(title, false, null, true, 'selectEvent');
    }
}

export class InstanceLinkAlertData extends AlertData {
    constructor(public link: ProjectLink,
                public title: string) {

        super(title, false, null, true, 'instanceLink');
    }
}

export class WftAcquisitionAlertData extends AlertData {
    constructor(public request: AcquisitionRequest, public title: string) {
        super(title, true, null, true, 'wftAcquisition');
    }
}

export class LinkContactAlertData extends AlertData {
    public contacts: Contact[];

    constructor(
        public params: LinkContactsParamsWithNumber,
        public tableType: PopupTableType,
        public getContactsFunc: (params: FindContactsParams) => Promise<JsonReplyWithParams<Contact[], FindContactsParams>>,
        public title: string) {

        super(title, false, null, true, 'contact');

        this.contacts = [];
    };
}

export class LinkTrendData extends AlertData {
    constructor(public trend: SERPLinkPositionTrend, public title: string, public url: string) {
        super(title, false, null, true, 'serpLinkTrend', null, url);
    }
}


//generic edit

export class MassiveSoftLegal extends AlertData {
    constructor(public link: any[], public linkTitle: string, public tab: SoftLegalTab) {
        let type = 'massiveSoftLegal';
        if(tab == SoftLegalTab.SuccessFee)
            type = 'massiveSoftLegalSuccessFee';

        super(linkTitle, false, null, true, (type));
    }
}

//generic edit

export class MassiveContentsData extends AlertData {
    constructor(public contents: any[], public linkTitle: string, public tableType: PopupTableType) {
        super(linkTitle, false, null, true, 'massiveContents');
    }
}


//Edit Many

export class EditManyData extends AlertData {
    constructor(public link: any[], public linkTitle: string) {
        super(linkTitle, false, null, true, 'editMany');
    }
}


//new default channel

export class NewDefaultChannel extends AlertData {
    constructor(public link: any[], public linkTitle: string) {
        super(linkTitle, false, null, true, 'defaultChannelAdd');
    }
}

//url checking the channel update

export class UpdateChannelCheck extends AlertData {
    constructor(public channelCheck: any[], public linkTitle: string) {
        super(linkTitle, false, null, true, 'urlCheck');
    }
}

//serp table

export class ShowSerpTable extends AlertData {
    constructor(public serpSource: any[], public title: string, url) {
        super(title, false, null, true, 'serpTable', url);
    }
}

//serp table

export class QuickSerpDictionary extends AlertData {
    constructor(public dictionary: any[], public title: string) {
        super(title, false, null, true, 'quickSerpDictionary');
    }
}


//myuniumxtable
export class myUniumxTable extends AlertData {
    constructor(public users: UsersCustomer, public title: string, customerName:string) {
        super(title, false, null, true, 'myuniumxtable', null, customerName);
    }
}

//step date change
export class StepDateData extends AlertData {
    constructor(public params: RequestPreAnalysisDateParams, public title: string, public stepName: string) {
        super(title, false, null, true, 'stepdatechange');
    }
}

//add new Serp
export class AddNewSerp extends AlertData {
    constructor(public query: SERPQuery, public title: string) {
        super(title, false, null, true, 'addNewSerp');
    }
}

//Serp info added
export class MoreInfoSerp extends AlertData {
    constructor(public query: SERPQuery, public title: string) {
        super(title, false, null, true, 'moreInfoSerp');
    }
}


export class CustomerStartActivityData extends AlertData {
    constructor(public contract: CustomerStartActivity, public title: string) {
        super(title, false, null, true, 'customerStartActivity');
    }
}

//edit from url google without parameters to url with parameters
export class EditUrlSerp extends AlertData {
    constructor(public query: SERPQuery, public title: string) {
        super(title, false, null, true, 'editUrlSerp');
    }
}

//channel chart editor
export class ChannelChart extends AlertData {
    constructor(public data, public title: string, customerName: string) {
        super(title, false, null, true, 'channelChart', null, customerName);
    }
}

export class SoftLegalShareStatus extends AlertData {
    constructor(public data, public title: string, public afterTitle: string) {
        super(title, false, null, true, 'softLegalShareStatus', null, afterTitle);
    }
}

export class ChannelsSetStatus extends AlertData {
    constructor(public data, public title: string, public afterTitle: string) {
        super(title, false, null, true, 'channelsSetStatus', null, afterTitle);
    }
}
