import { TTDContact } from './TTDContact';
import { Domain } from './Domain';

export class TTD {
    id: number;
    instanceReceiver: string = '';
    name: string = '';
    firstAddress: string = '';
    secondAddress: string = '';
    ttdContacts: TTDContact[] = [];
    note: string = '';
    domains: Domain[];
    domainsCount: number = 0;
    lastUpdate: Date = null;
    insertDate: Date = null;
}
