
<div class="row">
    <mat-checkbox class="col p-0 pl-2" name="controlled" [(ngModel)]="data.controlled" (ngModelChange)="data.aggregator = false">
        <span class="microcategory-text">{{'TABLE_HEADINGS.CONTROLLED' | translate}}</span>
    </mat-checkbox>
    <mat-checkbox class="col p-0" name="aggregator" [(ngModel)]="data.aggregator" (ngModelChange)="data.controlled = false">
        <span class="microcategory-text">{{'TABLE_HEADINGS.AGGREGATOR' | translate}}</span>
    </mat-checkbox>
</div>

<button mat-raised-button color="primary"
        class="ml-auto level2-button my-4 uniumx-button"
        (click)="onSubmit()">
    <img class="mr-2" src="/assets/svg/material-icons/save_blue.svg">
    {{'BUTTONS.SAVE' | translate}}
</button>
