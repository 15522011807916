<form class="row scrollable-container" #valueForm="ngForm" xmlns="http://www.w3.org/1999/html">
    <div class="col-6 col-md-2 specialCol4 pr-0" *ngFor="let macrocategory of macroCategories; let i = index">
        <!-- MACROCATEGORY: Start -->
        <div *ngIf="macrocategory.value" class="macrocategory mb-2" [ngStyle]="{color: colorsDataset[macrocategory.id].color}">

            <mat-checkbox (change)="showMacro($event, i)"
                          [checked]="true"
                          [disabled]="!macrocategory.subCategories || macrocategory.subCategories.length == 0"
                          [labelPosition]="'before'">
                <strong style="font-size:14px;">{{macrocategory.macroCategoryName}}</strong>
            </mat-checkbox>
        </div>

        <div *ngIf="!macrocategory.value" class="macrocategory mb-2" [ngStyle]="{color: 'grey'}">

            <mat-checkbox (change)="showMacro($event, i)"
                          [disabled]="!macrocategory.subCategories || macrocategory.subCategories.length == 0"
                          [labelPosition]="'before'">
                <strong style="font-size:14px;">{{macrocategory.macroCategoryName}}</strong>
            </mat-checkbox>
        </div>
        <!-- MACROCATEGORY: End -->
        <!-- MICROCATEGORY: Start -->
        <div class="row flex-column" *ngIf="macrocategory.subCategories && macrocategory.subCategories.length > 0 && showMicro">

            @if (!isAllTemplate){
                <div class="col-12 mat-typography position-relative" *ngFor="let microcategory of macrocategory.subCategories">
                    <mat-checkbox (change)="microChange(microcategory, i, macrocategory)" [checked]="!!microcategory.value">
                        <span class="microcategory-text">{{microcategory.subCategoryName}}</span>
                    </mat-checkbox>
                </div>
            }


            @if (isAllTemplate){
                <div class="col-12 mat-typography position-relative" *ngFor="let microcategory of filterSub(macrocategory, true); let first = first;">
                    <small *ngIf="first" class="d-block mt-2"><strong>Categorie attive</strong></small>
                    <mat-checkbox (change)="microChange(microcategory, i, macrocategory)" [checked]="!!microcategory.value">
                        <span class="microcategory-text">{{microcategory.subCategoryName}}</span>
                    </mat-checkbox>
                </div>

                <div class="col-12 mat-typography position-relative" *ngFor="let microcategory of filterSub(macrocategory, false); let first = first;">
                    <small *ngIf="first" class="d-block mt-2"><strong>Categorie disattive</strong></small>
                    <mat-checkbox (change)="microChange(microcategory, i, macrocategory)" [checked]="!!microcategory.value">
                        <span class="microcategory-text">{{microcategory.subCategoryName}}</span>
                    </mat-checkbox>
                </div>
            }
        </div>
        <!-- MICROCATEGORY: End -->

        <div *ngIf="showMicro" class="underline mt-4" [ngStyle]="{backgroundColor: macrocategory.weight > 0 ? colorsDataset[macrocategory.id].color : 'grey'}"></div>
    </div>

    <br /><br />
</form>

<div class="row" *ngIf="hasMicro">
    <button style="margin: auto" *ngIf="!showMicro" (click)="showMicroCat(true)" mat-icon-button>
        <img style="transform: rotate(-90deg)" src="/assets/svg/material-icons/chevron_left_grey.svg">
    </button>

    <button style="margin: auto" *ngIf="showMicro" (click)="showMicroCat(false)" mat-icon-button>
        <img style="transform: rotate(90deg)" src="/assets/svg/material-icons/chevron_left_grey.svg">
    </button>
</div>
