import { RichEnum, RichEnumOption } from './RichEnum';
import { OrderParams } from './api/OrderParams';
import { sealed } from '../helpers/sealed.decorator';

export enum SERPSentiment {
    NotSet = -2,
    Negative,
    NA,
    Positive
}

export enum ContactReportType {
    Contacts = -1,
    SalesDashboard,
    Sales,
    Legal
}

export enum CustomerLinkWftStatus {
    Any = -1, /* primo stato */
    NotProcessed, /* primo stato */
    Ok, /* positivo */
    Error, /* negativo */
    UrlNotAvailableForWft, /* negativo */
    UrlAvailableForWft, /* primo stato */
    Loading /* primo stato */
}

export enum SoftLegalTab {
    NoTab = -1,
    TODO,
    NotWorkable,
    NotAvailable,
    WebForm,
    Instance,
    SearchEngine,
    All,
    Authority,
    SuccessFee
}

export enum PopupTableType {
    LinkEventToAsset,
    LinkEventToLink,
    LinkContentToAsset,
    LinkAssetToContent,
    LinkDomainToTtd,
    LinkedEventsToLink,
    LinkContacts,
    MassiveContents
}

export enum TaskManagerType {
    Unknown = 0,
    Build,
    Publish,
    Review,
    OpenChannel,
    OptimizationSEO
}

export enum UpdateType {
    RouterLink,
    Popup
}

@sealed
export class WftStatus extends RichEnum {
    constructor() {
        super(
            'WFT_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'NOT_PROCESSED'),
            new RichEnumOption(2, 'OK'),
            new RichEnumOption(3, 'ERROR'),
            new RichEnumOption(4, 'FAIL_WFT_ACQUISITION'),
            new RichEnumOption(5, 'OK_WFT_ACQUISITION'),
            new RichEnumOption(6, 'LOADING')
        );
    }
}

@sealed
export class ContactPartner extends RichEnum {
    constructor() {
        super(
            'PARTNER_LIST',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'ReputationScience'),
            new RichEnumOption(2, 'CommunityGroup'),
            new RichEnumOption(3, 'Sifa'),
            new RichEnumOption(4, 'BeMedia'),
            new RichEnumOption(5, 'YourNext'),
            new RichEnumOption(6, 'LaRosa')
        );
    }
}

@sealed
export class ValueMatrixCategory extends RichEnum {
    constructor() {
        super(
            'VALUE_MATRIX',
            new RichEnumOption(0, 'EXECUTIVE'),
            new RichEnumOption(1, 'CORPORATE')
        );
    }
}


//customer channel enum
@sealed
export class ChannelStatus extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(0, 'NEW'),
            new RichEnumOption(1, 'EXISTING')
        );
    }
}

@sealed
export class ChannelWorkingStatus extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'EXISTING'),
            new RichEnumOption(2, 'CREATE'),
            new RichEnumOption(3, 'OPTIMISING'),
        );
    }
}

@sealed
export class ContentTypology extends RichEnum {
    constructor() {
        super(
            'CONTENT_TYPOLOGY',
            new RichEnumOption(null, 'ANY'),
            new RichEnumOption(0, 'UNKNOWN'),
            new RichEnumOption(1, 'BIO'),
            new RichEnumOption(2, 'VIDEOAUDIO'),
            new RichEnumOption(3, 'ARTICLEONLINE'),
            new RichEnumOption(4, 'ARTICLEOFFLINE'),
            new RichEnumOption(5, 'IMAGES'),
            new RichEnumOption(6, 'PUBLICATIONS'),
            new RichEnumOption(7, 'PRESSRELEASE'),
            new RichEnumOption(8, 'PRESENTATIONS'),
            new RichEnumOption(9, 'OTHER')
        );
    }
}

@sealed
export class ChannelIndexing extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(1, 'LOW'),
            new RichEnumOption(2, 'MEDIUM'),
            new RichEnumOption(3, 'HIGH')
        );
    }
}

@sealed
export class ActivityTarget extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(1, 'STANDARD'),
            new RichEnumOption(2, 'EXECUTIVE'),
            new RichEnumOption(3, 'CORPORATE')
        );
    }
}

@sealed
export class DomainTypology extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(1, 'BLOG'),
            new RichEnumOption(2, 'SOCIAL'),
            new RichEnumOption(3, 'FORUM')
        );
    }
}

@sealed
export class ChannelType extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'PERSONAL'),
            new RichEnumOption(2, 'CORPORATE')
        );
    }
}

@sealed
export class ContentManagedBy extends RichEnum {
    constructor() {
        super(
            'MANAGED_BY',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(0, 'SERP'),
            new RichEnumOption(1, 'CHANNELS_PLAN'),
            new RichEnumOption(2, 'RM'),
            new RichEnumOption(3, 'SOFT_LEGAL')
        );
    }
}

@sealed
export class DomainType extends RichEnum {
    constructor() {
        super(
            'DOMAIN',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'IMAGE'),
            new RichEnumOption(2, 'VIDEO')
        );
    }
}

@sealed
export class ChannelApprovalStates extends RichEnum {
    constructor() {
        super(
            'CUSTOMER_CHANNEL',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'APPROVED'),
            new RichEnumOption(2, 'IN_APPROVAL'),
            new RichEnumOption(3, 'NOT_APPROVED'),
            new RichEnumOption(4, 'TERMINATED')
        );
    }
}

// language enum
@sealed
export class ContactLanguage extends RichEnum {
    constructor() {
        super(
            'LANGUAGES',
            new RichEnumOption(150, "GERMAN", "de"),
            new RichEnumOption(137, "FRENCH", "fr"),
            new RichEnumOption(123, "ENGLISH", "en"),
            new RichEnumOption(202, "ITALIAN", "it"),
            new RichEnumOption(0, "AFAR", "aa"),
            new RichEnumOption(1, "ABKHAZIAN", "ab"),
            new RichEnumOption(8, "AFRIKAANS", "af"),
            new RichEnumOption(10, "AKAN", "ak"),
            new RichEnumOption(12, "ALBANIAN", "sq"),
            new RichEnumOption(16, "AMHARIC", "am"),
            new RichEnumOption(20, "ARABIC", "ar"),
            new RichEnumOption(22, "ARAGONESE", "an"),
            new RichEnumOption(23, "ARMENIAN", "hy"),
            new RichEnumOption(28, "ASSAMESE", "as"),
            new RichEnumOption(32, "AVARIC", "av"),
            new RichEnumOption(33, "AVESTAN", "ae"),
            new RichEnumOption(35, "AYMARA", "ay"),
            new RichEnumOption(36, "AZERBAIJANI", "az"),
            new RichEnumOption(39, "BASHKIR", "ba"),
            new RichEnumOption(41, "BAMBARA", "bm"),
            new RichEnumOption(43, "BASQUE", "eu"),
            new RichEnumOption(47, "BELARUSIAN", "be"),
            new RichEnumOption(49, "BENGALI", "bn"),
            new RichEnumOption(52, "BIHARI LANGUAGES", "bh"),
            new RichEnumOption(55, "BISLAMA", "bi"),
            new RichEnumOption(58, "BOSNIAN", "bs"),
            new RichEnumOption(60, "BRETON", "br"),
            new RichEnumOption(64, "BULGARIAN", "bg"),
            new RichEnumOption(65, "BURMESE", "my"),
            new RichEnumOption(70, "CATALAN; VALENCIAN", "ca"),
            new RichEnumOption(74, "CHAMORRO", "ch"),
            new RichEnumOption(76, "CHECHEN", "ce"),
            new RichEnumOption(78, "CHINESE", "zh"),
            new RichEnumOption(85, "CHURCH SLAVIC; OLD SLAVONIC; CHURCH SLAVONIC; OLD BULGARIAN; OLD CHURCH SLAVONIC", "cu"),
            new RichEnumOption(86, "CHUVASH", "cv"),
            new RichEnumOption(91, "CORNISH", "kw"),
            new RichEnumOption(92, "CORSICAN", "co"),
            new RichEnumOption(96, "CREE", "cr"),
            new RichEnumOption(101, "CZECH", "cs"),
            new RichEnumOption(103, "DANISH", "da"),
            new RichEnumOption(110, "DIVEHI; DHIVEHI; MALDIVIAN", "dv"),
            new RichEnumOption(116, "DUTCH; FLEMISH", "nl"),
            new RichEnumOption(118, "DZONGKHA", "dz"),
            new RichEnumOption(125, "ESPERANTO", "eo"),
            new RichEnumOption(126, "ESTONIAN", "et"),
            new RichEnumOption(127, "EWE", "ee"),
            new RichEnumOption(130, "FAROESE", "fo"),
            new RichEnumOption(132, "FIJIAN", "fj"),
            new RichEnumOption(134, "FINNISH", "fi"),
            new RichEnumOption(142, "WESTERN FRISIAN", "fy"),
            new RichEnumOption(143, "FULAH", "ff"),
            new RichEnumOption(149, "GEORGIAN", "ka"),
            new RichEnumOption(153, "GAELIC; SCOTTISH GAELIC", "gd"),
            new RichEnumOption(154, "IRISH", "ga"),
            new RichEnumOption(155, "GALICIAN", "gl"),
            new RichEnumOption(156, "MANX", "gv"),
            new RichEnumOption(164, "GREEK; MODERN (1453-)", "el"),
            new RichEnumOption(165, "GUARANI", "gn"),
            new RichEnumOption(167, "GUJARATI", "gu"),
            new RichEnumOption(170, "HAITIAN; HAITIAN CREOLE", "ht"),
            new RichEnumOption(171, "HAUSA", "ha"),
            new RichEnumOption(173, "HEBREW", "he"),
            new RichEnumOption(174, "HERERO", "hz"),
            new RichEnumOption(177, "HINDI", "hi"),
            new RichEnumOption(180, "HIRI MOTU", "ho"),
            new RichEnumOption(181, "CROATIAN", "hr"),
            new RichEnumOption(183, "HUNGARIAN", "hu"),
            new RichEnumOption(186, "IGBO", "ig"),
            new RichEnumOption(187, "ICELANDIC", "is"),
            new RichEnumOption(188, "IDO", "io"),
            new RichEnumOption(189, "SICHUAN YI; NUOSU", "ii"),
            new RichEnumOption(191, "INUKTITUT", "iu"),
            new RichEnumOption(192, "INTERLINGUE; OCCIDENTAL", "ie"),
            new RichEnumOption(194, "INTERLINGUA (INTERNATIONAL AUXILIARY LANGUAGE ASSOCIATION)", "ia"),
            new RichEnumOption(196, "INDONESIAN", "id"),
            new RichEnumOption(199, "INUPIAQ", "ik"),
            new RichEnumOption(203, "JAVANESE", "jv"),
            new RichEnumOption(205, "JAPANESE", "ja"),
            new RichEnumOption(211, "KALAALLISUT; GREENLANDIC", "kl"),
            new RichEnumOption(213, "KANNADA", "kn"),
            new RichEnumOption(215, "KASHMIRI", "ks"),
            new RichEnumOption(216, "KANURI", "kr"),
            new RichEnumOption(218, "KAZAKH", "kk"),
            new RichEnumOption(222, "CENTRAL KHMER", "km"),
            new RichEnumOption(224, "KIKUYU; GIKUYU", "ki"),
            new RichEnumOption(225, "KINYARWANDA", "rw"),
            new RichEnumOption(226, "KIRGHIZ; KYRGYZ", "ky"),
            new RichEnumOption(229, "KOMI", "kv"),
            new RichEnumOption(230, "KONGO", "kg"),
            new RichEnumOption(231, "KOREAN", "ko"),
            new RichEnumOption(238, "KUANYAMA; KWANYAMA", "kj"),
            new RichEnumOption(240, "KURDISH", "ku"),
            new RichEnumOption(245, "LAO", "lo"),
            new RichEnumOption(246, "LATIN", "la"),
            new RichEnumOption(247, "LATVIAN", "lv"),
            new RichEnumOption(249, "LIMBURGAN; LIMBURGER; LIMBURGISH", "li"),
            new RichEnumOption(250, "LINGALA", "ln"),
            new RichEnumOption(251, "LITHUANIAN", "lt"),
            new RichEnumOption(254, "LUXEMBOURGISH; LETZEBURGESCH", "lb"),
            new RichEnumOption(256, "LUBA-KATANGA", "lu"),
            new RichEnumOption(257, "GANDA", "lg"),
            new RichEnumOption(262, "MACEDONIAN", "mk"),
            new RichEnumOption(265, "MARSHALLESE", "mh"),
            new RichEnumOption(268, "MALAYALAM", "ml"),
            new RichEnumOption(270, "MAORI", "mi"),
            new RichEnumOption(272, "MARATHI", "mr"),
            new RichEnumOption(274, "MALAY", "ms"),
            new RichEnumOption(283, "MALAGASY", "mg"),
            new RichEnumOption(284, "MALTESE", "mt"),
            new RichEnumOption(289, "MONGOLIAN", "mn"),
            new RichEnumOption(301, "NAURU", "na"),
            new RichEnumOption(302, "NAVAJO; NAVAHO", "nv"),
            new RichEnumOption(303, "NDEBELE; SOUTH; SOUTH NDEBELE", "nr"),
            new RichEnumOption(304, "NDEBELE; NORTH; NORTH NDEBELE", "nd"),
            new RichEnumOption(305, "NDONGA", "ng"),
            new RichEnumOption(307, "NEPALI", "ne"),
            new RichEnumOption(312, "NORWEGIAN NYNORSK; NYNORSK; NORWEGIAN", "nn"),
            new RichEnumOption(313, "BOKMAL; NORWEGIAN; NORWEGIAN BOKMAL", "nb"),
            new RichEnumOption(316, "NORWEGIAN", "no"),
            new RichEnumOption(321, "CHICHEWA; CHEWA; NYANJA", "ny"),
            new RichEnumOption(326, "OCCITAN (POST 1500)", "oc"),
            new RichEnumOption(327, "OJIBWA", "oj"),
            new RichEnumOption(328, "ORIYA", "or"),
            new RichEnumOption(329, "OROMO", "om"),
            new RichEnumOption(331, "OSSETIAN; OSSETIC", "os"),
            new RichEnumOption(338, "PANJABI; PUNJABI", "pa"),
            new RichEnumOption(342, "PERSIAN", "fa"),
            new RichEnumOption(345, "PALI", "pi"),
            new RichEnumOption(346, "POLISH", "pl"),
            new RichEnumOption(348, "PORTUGUESE", "pt"),
            new RichEnumOption(351, "PUSHTO; PASHTO", "ps"),
            new RichEnumOption(353, "QUECHUA", "qu"),
            new RichEnumOption(358, "ROMANSH", "rm"),
            new RichEnumOption(360, "ROMANIAN; MOLDAVIAN; MOLDOVAN", "ro"),
            new RichEnumOption(361, "RUNDI", "rn"),
            new RichEnumOption(363, "RUSSIAN", "ru"),
            new RichEnumOption(365, "SANGO", "sg"),
            new RichEnumOption(370, "SANSKRIT", "sa"),
            new RichEnumOption(381, "SINHALA; SINHALESE", "si"),
            new RichEnumOption(385, "SLOVAK", "sk"),
            new RichEnumOption(386, "SLOVENIAN", "sl"),
            new RichEnumOption(388, "NORTHERN SAMI", "se"),
            new RichEnumOption(392, "SAMOAN", "sm"),
            new RichEnumOption(394, "SHONA", "sn"),
            new RichEnumOption(395, "SINDHI", "sd"),
            new RichEnumOption(398, "SOMALI", "so"),
            new RichEnumOption(400, "SOTHO; SOUTHERN", "st"),
            new RichEnumOption(401, "SPANISH; CASTILIAN", "es"),
            new RichEnumOption(402, "SARDINIAN", "sc"),
            new RichEnumOption(404, "SERBIAN", "sr"),
            new RichEnumOption(407, "SWATI", "ss"),
            new RichEnumOption(409, "SUNDANESE", "su"),
            new RichEnumOption(412, "SWAHILI", "sw"),
            new RichEnumOption(413, "SWEDISH", "sv"),
            new RichEnumOption(416, "TAHITIAN", "ty"),
            new RichEnumOption(418, "TAMIL", "ta"),
            new RichEnumOption(419, "TATAR", "tt"),
            new RichEnumOption(420, "TELUGU", "te"),
            new RichEnumOption(424, "TAJIK", "tg"),
            new RichEnumOption(425, "TAGALOG", "tl"),
            new RichEnumOption(426, "THAI", "th"),
            new RichEnumOption(427, "TIBETAN", "bo"),
            new RichEnumOption(429, "TIGRINYA", "ti"),
            new RichEnumOption(436, "TONGA (TONGA ISLANDS)", "to"),
            new RichEnumOption(439, "TSWANA", "tn"),
            new RichEnumOption(440, "TSONGA", "ts"),
            new RichEnumOption(441, "TURKMEN", "tk"),
            new RichEnumOption(444, "TURKISH", "tr"),
            new RichEnumOption(447, "TWI", "tw"),
            new RichEnumOption(451, "UIGHUR; UYGHUR", "ug"),
            new RichEnumOption(452, "UKRAINIAN", "uk"),
            new RichEnumOption(455, "URDU", "ur"),
            new RichEnumOption(456, "UZBEK", "uz"),
            new RichEnumOption(458, "VENDA", "ve"),
            new RichEnumOption(459, "VIETNAMESE", "vi"),
            new RichEnumOption(460, "VOLAPUK", "vo"),
            new RichEnumOption(466, "WELSH", "cy"),
            new RichEnumOption(468, "WALLOON", "wa"),
            new RichEnumOption(469, "WOLOF", "wo"),
            new RichEnumOption(471, "XHOSA", "xh"),
            new RichEnumOption(474, "YIDDISH", "yi"),
            new RichEnumOption(475, "YORUBA", "yo"),
            new RichEnumOption(481, "ZHUANG; CHUANG", "za"),
            new RichEnumOption(483, "ZULU", "zu")
        );
    }
}

// tld enum
@sealed
export class TldList extends RichEnum {
    constructor() {
        super(
            'TLDS',
            new RichEnumOption(627, ".IT"),
            new RichEnumOption(272, ".COM"),
            new RichEnumOption(454, ".FR"),
            new RichEnumOption(1551, ".CO.UK"),
            new RichEnumOption(1251, ".UK"),
            new RichEnumOption(390, ".ES"),
            new RichEnumOption(324, ".DE"),
            new RichEnumOption(0, ".AAA"),
            new RichEnumOption(1, ".AARP"),
            new RichEnumOption(2, ".ABARTH"),
            new RichEnumOption(3, ".ABB"),
            new RichEnumOption(4, ".ABBOTT"),
            new RichEnumOption(5, ".ABBVIE"),
            new RichEnumOption(6, ".ABC"),
            new RichEnumOption(7, ".ABLE"),
            new RichEnumOption(8, ".ABOGADO"),
            new RichEnumOption(9, ".ABUDHABI"),
            new RichEnumOption(10, ".AC"),
            new RichEnumOption(11, ".ACADEMY"),
            new RichEnumOption(12, ".ACCENTURE"),
            new RichEnumOption(13, ".ACCOUNTANT"),
            new RichEnumOption(14, ".ACCOUNTANTS"),
            new RichEnumOption(15, ".ACO"),
            new RichEnumOption(16, ".ACTOR"),
            new RichEnumOption(17, ".AD"),
            new RichEnumOption(18, ".ADAC"),
            new RichEnumOption(19, ".ADS"),
            new RichEnumOption(20, ".ADULT"),
            new RichEnumOption(21, ".AE"),
            new RichEnumOption(22, ".AEG"),
            new RichEnumOption(23, ".AERO"),
            new RichEnumOption(24, ".AETNA"),
            new RichEnumOption(25, ".AF"),
            new RichEnumOption(26, ".AFAMILYCOMPANY"),
            new RichEnumOption(27, ".AFL"),
            new RichEnumOption(28, ".AFRICA"),
            new RichEnumOption(29, ".AG"),
            new RichEnumOption(30, ".AGAKHAN"),
            new RichEnumOption(31, ".AGENCY"),
            new RichEnumOption(32, ".AI"),
            new RichEnumOption(33, ".AIG"),
            new RichEnumOption(34, ".AIGO"),
            new RichEnumOption(35, ".AIRBUS"),
            new RichEnumOption(36, ".AIRFORCE"),
            new RichEnumOption(37, ".AIRTEL"),
            new RichEnumOption(38, ".AKDN"),
            new RichEnumOption(39, ".AL"),
            new RichEnumOption(40, ".ALFAROMEO"),
            new RichEnumOption(41, ".ALIBABA"),
            new RichEnumOption(42, ".ALIPAY"),
            new RichEnumOption(43, ".ALLFINANZ"),
            new RichEnumOption(44, ".ALLSTATE"),
            new RichEnumOption(45, ".ALLY"),
            new RichEnumOption(46, ".ALSACE"),
            new RichEnumOption(47, ".ALSTOM"),
            new RichEnumOption(48, ".AM"),
            new RichEnumOption(49, ".AMERICANEXPRESS"),
            new RichEnumOption(50, ".AMERICANFAMILY"),
            new RichEnumOption(51, ".AMEX"),
            new RichEnumOption(52, ".AMFAM"),
            new RichEnumOption(53, ".AMICA"),
            new RichEnumOption(54, ".AMSTERDAM"),
            new RichEnumOption(55, ".ANALYTICS"),
            new RichEnumOption(56, ".ANDROID"),
            new RichEnumOption(57, ".ANQUAN"),
            new RichEnumOption(58, ".ANZ"),
            new RichEnumOption(59, ".AO"),
            new RichEnumOption(60, ".AOL"),
            new RichEnumOption(61, ".APARTMENTS"),
            new RichEnumOption(62, ".APP"),
            new RichEnumOption(63, ".APPLE"),
            new RichEnumOption(64, ".AQ"),
            new RichEnumOption(65, ".AQUARELLE"),
            new RichEnumOption(66, ".AR"),
            new RichEnumOption(67, ".ARAB"),
            new RichEnumOption(68, ".ARAMCO"),
            new RichEnumOption(69, ".ARCHI"),
            new RichEnumOption(70, ".ARMY"),
            new RichEnumOption(71, ".ARPA"),
            new RichEnumOption(72, ".ART"),
            new RichEnumOption(73, ".ARTE"),
            new RichEnumOption(74, ".AS"),
            new RichEnumOption(75, ".ASDA"),
            new RichEnumOption(76, ".ASIA"),
            new RichEnumOption(77, ".ASSOCIATES"),
            new RichEnumOption(78, ".AT"),
            new RichEnumOption(79, ".ATHLETA"),
            new RichEnumOption(80, ".ATTORNEY"),
            new RichEnumOption(81, ".AU"),
            new RichEnumOption(82, ".AUCTION"),
            new RichEnumOption(83, ".AUDI"),
            new RichEnumOption(84, ".AUDIBLE"),
            new RichEnumOption(85, ".AUDIO"),
            new RichEnumOption(86, ".AUSPOST"),
            new RichEnumOption(87, ".AUTHOR"),
            new RichEnumOption(88, ".AUTO"),
            new RichEnumOption(89, ".AUTOS"),
            new RichEnumOption(90, ".AVIANCA"),
            new RichEnumOption(91, ".AW"),
            new RichEnumOption(92, ".AWS"),
            new RichEnumOption(93, ".AX"),
            new RichEnumOption(94, ".AXA"),
            new RichEnumOption(95, ".AZ"),
            new RichEnumOption(96, ".AZURE"),
            new RichEnumOption(97, ".BA"),
            new RichEnumOption(98, ".BABY"),
            new RichEnumOption(99, ".BAIDU"),
            new RichEnumOption(100, ".BANAMEX"),
            new RichEnumOption(101, ".BANANAREPUBLIC"),
            new RichEnumOption(102, ".BAND"),
            new RichEnumOption(103, ".BANK"),
            new RichEnumOption(104, ".BAR"),
            new RichEnumOption(105, ".BARCELONA"),
            new RichEnumOption(106, ".BARCLAYCARD"),
            new RichEnumOption(107, ".BARCLAYS"),
            new RichEnumOption(108, ".BAREFOOT"),
            new RichEnumOption(109, ".BARGAINS"),
            new RichEnumOption(110, ".BASEBALL"),
            new RichEnumOption(111, ".BASKETBALL"),
            new RichEnumOption(112, ".BAUHAUS"),
            new RichEnumOption(113, ".BAYERN"),
            new RichEnumOption(114, ".BB"),
            new RichEnumOption(115, ".BBC"),
            new RichEnumOption(116, ".BBT"),
            new RichEnumOption(117, ".BBVA"),
            new RichEnumOption(118, ".BCG"),
            new RichEnumOption(119, ".BCN"),
            new RichEnumOption(120, ".BD"),
            new RichEnumOption(121, ".BE"),
            new RichEnumOption(122, ".BEATS"),
            new RichEnumOption(123, ".BEAUTY"),
            new RichEnumOption(124, ".BEER"),
            new RichEnumOption(125, ".BENTLEY"),
            new RichEnumOption(126, ".BERLIN"),
            new RichEnumOption(127, ".BEST"),
            new RichEnumOption(128, ".BESTBUY"),
            new RichEnumOption(129, ".BET"),
            new RichEnumOption(130, ".BF"),
            new RichEnumOption(131, ".BG"),
            new RichEnumOption(132, ".BH"),
            new RichEnumOption(133, ".BHARTI"),
            new RichEnumOption(134, ".BI"),
            new RichEnumOption(135, ".BIBLE"),
            new RichEnumOption(136, ".BID"),
            new RichEnumOption(137, ".BIKE"),
            new RichEnumOption(138, ".BING"),
            new RichEnumOption(139, ".BINGO"),
            new RichEnumOption(140, ".BIO"),
            new RichEnumOption(141, ".BIZ"),
            new RichEnumOption(142, ".BJ"),
            new RichEnumOption(143, ".BLACK"),
            new RichEnumOption(144, ".BLACKFRIDAY"),
            new RichEnumOption(145, ".BLOCKBUSTER"),
            new RichEnumOption(146, ".BLOG"),
            new RichEnumOption(147, ".BLOOMBERG"),
            new RichEnumOption(148, ".BLUE"),
            new RichEnumOption(149, ".BM"),
            new RichEnumOption(150, ".BMS"),
            new RichEnumOption(151, ".BMW"),
            new RichEnumOption(152, ".BN"),
            new RichEnumOption(153, ".BNPPARIBAS"),
            new RichEnumOption(154, ".BO"),
            new RichEnumOption(155, ".BOATS"),
            new RichEnumOption(156, ".BOEHRINGER"),
            new RichEnumOption(157, ".BOFA"),
            new RichEnumOption(158, ".BOM"),
            new RichEnumOption(159, ".BOND"),
            new RichEnumOption(160, ".BOO"),
            new RichEnumOption(161, ".BOOK"),
            new RichEnumOption(162, ".BOOKING"),
            new RichEnumOption(163, ".BOSCH"),
            new RichEnumOption(164, ".BOSTIK"),
            new RichEnumOption(165, ".BOSTON"),
            new RichEnumOption(166, ".BOT"),
            new RichEnumOption(167, ".BOUTIQUE"),
            new RichEnumOption(168, ".BOX"),
            new RichEnumOption(169, ".BR"),
            new RichEnumOption(170, ".BRADESCO"),
            new RichEnumOption(171, ".BRIDGESTONE"),
            new RichEnumOption(172, ".BROADWAY"),
            new RichEnumOption(173, ".BROKER"),
            new RichEnumOption(174, ".BROTHER"),
            new RichEnumOption(175, ".BRUSSELS"),
            new RichEnumOption(176, ".BS"),
            new RichEnumOption(177, ".BT"),
            new RichEnumOption(178, ".BUDAPEST"),
            new RichEnumOption(179, ".BUGATTI"),
            new RichEnumOption(180, ".BUILD"),
            new RichEnumOption(181, ".BUILDERS"),
            new RichEnumOption(182, ".BUSINESS"),
            new RichEnumOption(183, ".BUY"),
            new RichEnumOption(184, ".BUZZ"),
            new RichEnumOption(185, ".BV"),
            new RichEnumOption(186, ".BW"),
            new RichEnumOption(187, ".BY"),
            new RichEnumOption(188, ".BZ"),
            new RichEnumOption(189, ".BZH"),
            new RichEnumOption(190, ".CA"),
            new RichEnumOption(191, ".CAB"),
            new RichEnumOption(192, ".CAFE"),
            new RichEnumOption(193, ".CAL"),
            new RichEnumOption(194, ".CALL"),
            new RichEnumOption(195, ".CALVINKLEIN"),
            new RichEnumOption(196, ".CAM"),
            new RichEnumOption(197, ".CAMERA"),
            new RichEnumOption(198, ".CAMP"),
            new RichEnumOption(199, ".CANCERRESEARCH"),
            new RichEnumOption(200, ".CANON"),
            new RichEnumOption(201, ".CAPETOWN"),
            new RichEnumOption(202, ".CAPITAL"),
            new RichEnumOption(203, ".CAPITALONE"),
            new RichEnumOption(204, ".CAR"),
            new RichEnumOption(205, ".CARAVAN"),
            new RichEnumOption(206, ".CARDS"),
            new RichEnumOption(207, ".CARE"),
            new RichEnumOption(208, ".CAREER"),
            new RichEnumOption(209, ".CAREERS"),
            new RichEnumOption(210, ".CARS"),
            new RichEnumOption(211, ".CASA"),
            new RichEnumOption(212, ".CASE"),
            new RichEnumOption(213, ".CASEIH"),
            new RichEnumOption(214, ".CASH"),
            new RichEnumOption(215, ".CASINO"),
            new RichEnumOption(216, ".CAT"),
            new RichEnumOption(217, ".CATERING"),
            new RichEnumOption(218, ".CATHOLIC"),
            new RichEnumOption(219, ".CBA"),
            new RichEnumOption(220, ".CBN"),
            new RichEnumOption(221, ".CBRE"),
            new RichEnumOption(222, ".CBS"),
            new RichEnumOption(223, ".CC"),
            new RichEnumOption(224, ".CD"),
            new RichEnumOption(225, ".CEB"),
            new RichEnumOption(226, ".CENTER"),
            new RichEnumOption(227, ".CEO"),
            new RichEnumOption(228, ".CERN"),
            new RichEnumOption(229, ".CF"),
            new RichEnumOption(230, ".CFA"),
            new RichEnumOption(231, ".CFD"),
            new RichEnumOption(232, ".CG"),
            new RichEnumOption(233, ".CH"),
            new RichEnumOption(234, ".CHANEL"),
            new RichEnumOption(235, ".CHANNEL"),
            new RichEnumOption(236, ".CHARITY"),
            new RichEnumOption(237, ".CHASE"),
            new RichEnumOption(238, ".CHAT"),
            new RichEnumOption(239, ".CHEAP"),
            new RichEnumOption(240, ".CHINTAI"),
            new RichEnumOption(241, ".CHRISTMAS"),
            new RichEnumOption(242, ".CHROME"),
            new RichEnumOption(243, ".CHURCH"),
            new RichEnumOption(244, ".CI"),
            new RichEnumOption(245, ".CIPRIANI"),
            new RichEnumOption(246, ".CIRCLE"),
            new RichEnumOption(247, ".CISCO"),
            new RichEnumOption(248, ".CITADEL"),
            new RichEnumOption(249, ".CITI"),
            new RichEnumOption(250, ".CITIC"),
            new RichEnumOption(251, ".CITY"),
            new RichEnumOption(252, ".CITYEATS"),
            new RichEnumOption(253, ".CK"),
            new RichEnumOption(254, ".CL"),
            new RichEnumOption(255, ".CLAIMS"),
            new RichEnumOption(256, ".CLEANING"),
            new RichEnumOption(257, ".CLICK"),
            new RichEnumOption(258, ".CLINIC"),
            new RichEnumOption(259, ".CLINIQUE"),
            new RichEnumOption(260, ".CLOTHING"),
            new RichEnumOption(261, ".CLOUD"),
            new RichEnumOption(262, ".CLUB"),
            new RichEnumOption(263, ".CLUBMED"),
            new RichEnumOption(264, ".CM"),
            new RichEnumOption(265, ".CN"),
            new RichEnumOption(266, ".CO"),
            new RichEnumOption(267, ".COACH"),
            new RichEnumOption(268, ".CODES"),
            new RichEnumOption(269, ".COFFEE"),
            new RichEnumOption(270, ".COLLEGE"),
            new RichEnumOption(271, ".COLOGNE"),
            new RichEnumOption(273, ".COMCAST"),
            new RichEnumOption(274, ".COMMBANK"),
            new RichEnumOption(275, ".COMMUNITY"),
            new RichEnumOption(276, ".COMPANY"),
            new RichEnumOption(277, ".COMPARE"),
            new RichEnumOption(278, ".COMPUTER"),
            new RichEnumOption(279, ".COMSEC"),
            new RichEnumOption(280, ".CONDOS"),
            new RichEnumOption(281, ".CONSTRUCTION"),
            new RichEnumOption(282, ".CONSULTING"),
            new RichEnumOption(283, ".CONTACT"),
            new RichEnumOption(284, ".CONTRACTORS"),
            new RichEnumOption(285, ".COOKING"),
            new RichEnumOption(286, ".COOKINGCHANNEL"),
            new RichEnumOption(287, ".COOL"),
            new RichEnumOption(288, ".COOP"),
            new RichEnumOption(289, ".CORSICA"),
            new RichEnumOption(290, ".COUNTRY"),
            new RichEnumOption(291, ".COUPON"),
            new RichEnumOption(292, ".COUPONS"),
            new RichEnumOption(293, ".COURSES"),
            new RichEnumOption(294, ".CPA"),
            new RichEnumOption(295, ".CR"),
            new RichEnumOption(296, ".CREDIT"),
            new RichEnumOption(297, ".CREDITCARD"),
            new RichEnumOption(298, ".CREDITUNION"),
            new RichEnumOption(299, ".CRICKET"),
            new RichEnumOption(300, ".CROWN"),
            new RichEnumOption(301, ".CRS"),
            new RichEnumOption(302, ".CRUISE"),
            new RichEnumOption(303, ".CRUISES"),
            new RichEnumOption(304, ".CSC"),
            new RichEnumOption(305, ".CU"),
            new RichEnumOption(306, ".CUISINELLA"),
            new RichEnumOption(307, ".CV"),
            new RichEnumOption(308, ".CW"),
            new RichEnumOption(309, ".CX"),
            new RichEnumOption(310, ".CY"),
            new RichEnumOption(311, ".CYMRU"),
            new RichEnumOption(312, ".CYOU"),
            new RichEnumOption(313, ".CZ"),
            new RichEnumOption(314, ".DABUR"),
            new RichEnumOption(315, ".DAD"),
            new RichEnumOption(316, ".DANCE"),
            new RichEnumOption(317, ".DATA"),
            new RichEnumOption(318, ".DATE"),
            new RichEnumOption(319, ".DATING"),
            new RichEnumOption(320, ".DATSUN"),
            new RichEnumOption(321, ".DAY"),
            new RichEnumOption(322, ".DCLK"),
            new RichEnumOption(323, ".DDS"),
            new RichEnumOption(325, ".DEAL"),
            new RichEnumOption(326, ".DEALER"),
            new RichEnumOption(327, ".DEALS"),
            new RichEnumOption(328, ".DEGREE"),
            new RichEnumOption(329, ".DELIVERY"),
            new RichEnumOption(330, ".DELL"),
            new RichEnumOption(331, ".DELOITTE"),
            new RichEnumOption(332, ".DELTA"),
            new RichEnumOption(333, ".DEMOCRAT"),
            new RichEnumOption(334, ".DENTAL"),
            new RichEnumOption(335, ".DENTIST"),
            new RichEnumOption(336, ".DESI"),
            new RichEnumOption(337, ".DESIGN"),
            new RichEnumOption(338, ".DEV"),
            new RichEnumOption(339, ".DHL"),
            new RichEnumOption(340, ".DIAMONDS"),
            new RichEnumOption(341, ".DIET"),
            new RichEnumOption(342, ".DIGITAL"),
            new RichEnumOption(343, ".DIRECT"),
            new RichEnumOption(344, ".DIRECTORY"),
            new RichEnumOption(345, ".DISCOUNT"),
            new RichEnumOption(346, ".DISCOVER"),
            new RichEnumOption(347, ".DISH"),
            new RichEnumOption(348, ".DIY"),
            new RichEnumOption(349, ".DJ"),
            new RichEnumOption(350, ".DK"),
            new RichEnumOption(351, ".DM"),
            new RichEnumOption(352, ".DNP"),
            new RichEnumOption(353, ".DO"),
            new RichEnumOption(354, ".DOCS"),
            new RichEnumOption(355, ".DOCTOR"),
            new RichEnumOption(356, ".DOG"),
            new RichEnumOption(357, ".DOMAINS"),
            new RichEnumOption(358, ".DOT"),
            new RichEnumOption(359, ".DOWNLOAD"),
            new RichEnumOption(360, ".DRIVE"),
            new RichEnumOption(361, ".DTV"),
            new RichEnumOption(362, ".DUBAI"),
            new RichEnumOption(363, ".DUCK"),
            new RichEnumOption(364, ".DUNLOP"),
            new RichEnumOption(365, ".DUPONT"),
            new RichEnumOption(366, ".DURBAN"),
            new RichEnumOption(367, ".DVAG"),
            new RichEnumOption(368, ".DVR"),
            new RichEnumOption(369, ".DZ"),
            new RichEnumOption(370, ".EARTH"),
            new RichEnumOption(371, ".EAT"),
            new RichEnumOption(372, ".EC"),
            new RichEnumOption(373, ".ECO"),
            new RichEnumOption(374, ".EDEKA"),
            new RichEnumOption(375, ".EDU"),
            new RichEnumOption(376, ".EDUCATION"),
            new RichEnumOption(377, ".EE"),
            new RichEnumOption(378, ".EG"),
            new RichEnumOption(379, ".EMAIL"),
            new RichEnumOption(380, ".EMERCK"),
            new RichEnumOption(381, ".ENERGY"),
            new RichEnumOption(382, ".ENGINEER"),
            new RichEnumOption(383, ".ENGINEERING"),
            new RichEnumOption(384, ".ENTERPRISES"),
            new RichEnumOption(385, ".EPSON"),
            new RichEnumOption(386, ".EQUIPMENT"),
            new RichEnumOption(387, ".ER"),
            new RichEnumOption(388, ".ERICSSON"),
            new RichEnumOption(389, ".ERNI"),
            new RichEnumOption(391, ".ESQ"),
            new RichEnumOption(392, ".ESTATE"),
            new RichEnumOption(393, ".ET"),
            new RichEnumOption(394, ".ETISALAT"),
            new RichEnumOption(395, ".EU"),
            new RichEnumOption(396, ".EUROVISION"),
            new RichEnumOption(397, ".EUS"),
            new RichEnumOption(398, ".EVENTS"),
            new RichEnumOption(399, ".EXCHANGE"),
            new RichEnumOption(400, ".EXPERT"),
            new RichEnumOption(401, ".EXPOSED"),
            new RichEnumOption(402, ".EXPRESS"),
            new RichEnumOption(403, ".EXTRASPACE"),
            new RichEnumOption(404, ".FAGE"),
            new RichEnumOption(405, ".FAIL"),
            new RichEnumOption(406, ".FAIRWINDS"),
            new RichEnumOption(407, ".FAITH"),
            new RichEnumOption(408, ".FAMILY"),
            new RichEnumOption(409, ".FAN"),
            new RichEnumOption(410, ".FANS"),
            new RichEnumOption(411, ".FARM"),
            new RichEnumOption(412, ".FARMERS"),
            new RichEnumOption(413, ".FASHION"),
            new RichEnumOption(414, ".FAST"),
            new RichEnumOption(415, ".FEDEX"),
            new RichEnumOption(416, ".FEEDBACK"),
            new RichEnumOption(417, ".FERRARI"),
            new RichEnumOption(418, ".FERRERO"),
            new RichEnumOption(419, ".FI"),
            new RichEnumOption(420, ".FIAT"),
            new RichEnumOption(421, ".FIDELITY"),
            new RichEnumOption(422, ".FIDO"),
            new RichEnumOption(423, ".FILM"),
            new RichEnumOption(424, ".FINAL"),
            new RichEnumOption(425, ".FINANCE"),
            new RichEnumOption(426, ".FINANCIAL"),
            new RichEnumOption(427, ".FIRE"),
            new RichEnumOption(428, ".FIRESTONE"),
            new RichEnumOption(429, ".FIRMDALE"),
            new RichEnumOption(430, ".FISH"),
            new RichEnumOption(431, ".FISHING"),
            new RichEnumOption(432, ".FIT"),
            new RichEnumOption(433, ".FITNESS"),
            new RichEnumOption(434, ".FJ"),
            new RichEnumOption(435, ".FK"),
            new RichEnumOption(436, ".FLICKR"),
            new RichEnumOption(437, ".FLIGHTS"),
            new RichEnumOption(438, ".FLIR"),
            new RichEnumOption(439, ".FLORIST"),
            new RichEnumOption(440, ".FLOWERS"),
            new RichEnumOption(441, ".FLY"),
            new RichEnumOption(442, ".FM"),
            new RichEnumOption(443, ".FO"),
            new RichEnumOption(444, ".FOO"),
            new RichEnumOption(445, ".FOOD"),
            new RichEnumOption(446, ".FOODNETWORK"),
            new RichEnumOption(447, ".FOOTBALL"),
            new RichEnumOption(448, ".FORD"),
            new RichEnumOption(449, ".FOREX"),
            new RichEnumOption(450, ".FORSALE"),
            new RichEnumOption(451, ".FORUM"),
            new RichEnumOption(452, ".FOUNDATION"),
            new RichEnumOption(453, ".FOX"),
            new RichEnumOption(455, ".FREE"),
            new RichEnumOption(456, ".FRESENIUS"),
            new RichEnumOption(457, ".FRL"),
            new RichEnumOption(458, ".FROGANS"),
            new RichEnumOption(459, ".FRONTDOOR"),
            new RichEnumOption(460, ".FRONTIER"),
            new RichEnumOption(461, ".FTR"),
            new RichEnumOption(462, ".FUJITSU"),
            new RichEnumOption(463, ".FUJIXEROX"),
            new RichEnumOption(464, ".FUN"),
            new RichEnumOption(465, ".FUND"),
            new RichEnumOption(466, ".FURNITURE"),
            new RichEnumOption(467, ".FUTBOL"),
            new RichEnumOption(468, ".FYI"),
            new RichEnumOption(469, ".GA"),
            new RichEnumOption(470, ".GAL"),
            new RichEnumOption(471, ".GALLERY"),
            new RichEnumOption(472, ".GALLO"),
            new RichEnumOption(473, ".GALLUP"),
            new RichEnumOption(474, ".GAME"),
            new RichEnumOption(475, ".GAMES"),
            new RichEnumOption(476, ".GAP"),
            new RichEnumOption(477, ".GARDEN"),
            new RichEnumOption(478, ".GAY"),
            new RichEnumOption(479, ".GB"),
            new RichEnumOption(480, ".GBIZ"),
            new RichEnumOption(481, ".GD"),
            new RichEnumOption(482, ".GDN"),
            new RichEnumOption(483, ".GE"),
            new RichEnumOption(484, ".GEA"),
            new RichEnumOption(485, ".GENT"),
            new RichEnumOption(486, ".GENTING"),
            new RichEnumOption(487, ".GEORGE"),
            new RichEnumOption(488, ".GF"),
            new RichEnumOption(489, ".GG"),
            new RichEnumOption(490, ".GGEE"),
            new RichEnumOption(491, ".GH"),
            new RichEnumOption(492, ".GI"),
            new RichEnumOption(493, ".GIFT"),
            new RichEnumOption(494, ".GIFTS"),
            new RichEnumOption(495, ".GIVES"),
            new RichEnumOption(496, ".GIVING"),
            new RichEnumOption(497, ".GL"),
            new RichEnumOption(498, ".GLADE"),
            new RichEnumOption(499, ".GLASS"),
            new RichEnumOption(500, ".GLE"),
            new RichEnumOption(501, ".GLOBAL"),
            new RichEnumOption(502, ".GLOBO"),
            new RichEnumOption(503, ".GM"),
            new RichEnumOption(504, ".GMAIL"),
            new RichEnumOption(505, ".GMBH"),
            new RichEnumOption(506, ".GMO"),
            new RichEnumOption(507, ".GMX"),
            new RichEnumOption(508, ".GN"),
            new RichEnumOption(509, ".GODADDY"),
            new RichEnumOption(510, ".GOLD"),
            new RichEnumOption(511, ".GOLDPOINT"),
            new RichEnumOption(512, ".GOLF"),
            new RichEnumOption(513, ".GOO"),
            new RichEnumOption(514, ".GOODYEAR"),
            new RichEnumOption(515, ".GOOG"),
            new RichEnumOption(516, ".GOOGLE"),
            new RichEnumOption(517, ".GOP"),
            new RichEnumOption(518, ".GOT"),
            new RichEnumOption(519, ".GOV"),
            new RichEnumOption(520, ".GP"),
            new RichEnumOption(521, ".GQ"),
            new RichEnumOption(522, ".GR"),
            new RichEnumOption(523, ".GRAINGER"),
            new RichEnumOption(524, ".GRAPHICS"),
            new RichEnumOption(525, ".GRATIS"),
            new RichEnumOption(526, ".GREEN"),
            new RichEnumOption(527, ".GRIPE"),
            new RichEnumOption(528, ".GROCERY"),
            new RichEnumOption(529, ".GROUP"),
            new RichEnumOption(530, ".GS"),
            new RichEnumOption(531, ".GT"),
            new RichEnumOption(532, ".GU"),
            new RichEnumOption(533, ".GUARDIAN"),
            new RichEnumOption(534, ".GUCCI"),
            new RichEnumOption(535, ".GUGE"),
            new RichEnumOption(536, ".GUIDE"),
            new RichEnumOption(537, ".GUITARS"),
            new RichEnumOption(538, ".GURU"),
            new RichEnumOption(539, ".GW"),
            new RichEnumOption(540, ".GY"),
            new RichEnumOption(541, ".HAIR"),
            new RichEnumOption(542, ".HAMBURG"),
            new RichEnumOption(543, ".HANGOUT"),
            new RichEnumOption(544, ".HAUS"),
            new RichEnumOption(545, ".HBO"),
            new RichEnumOption(546, ".HDFC"),
            new RichEnumOption(547, ".HDFCBANK"),
            new RichEnumOption(548, ".HEALTH"),
            new RichEnumOption(549, ".HEALTHCARE"),
            new RichEnumOption(550, ".HELP"),
            new RichEnumOption(551, ".HELSINKI"),
            new RichEnumOption(552, ".HERE"),
            new RichEnumOption(553, ".HERMES"),
            new RichEnumOption(554, ".HGTV"),
            new RichEnumOption(555, ".HIPHOP"),
            new RichEnumOption(556, ".HISAMITSU"),
            new RichEnumOption(557, ".HITACHI"),
            new RichEnumOption(558, ".HIV"),
            new RichEnumOption(559, ".HK"),
            new RichEnumOption(560, ".HKT"),
            new RichEnumOption(561, ".HM"),
            new RichEnumOption(562, ".HN"),
            new RichEnumOption(563, ".HOCKEY"),
            new RichEnumOption(564, ".HOLDINGS"),
            new RichEnumOption(565, ".HOLIDAY"),
            new RichEnumOption(566, ".HOMEDEPOT"),
            new RichEnumOption(567, ".HOMEGOODS"),
            new RichEnumOption(568, ".HOMES"),
            new RichEnumOption(569, ".HOMESENSE"),
            new RichEnumOption(570, ".HONDA"),
            new RichEnumOption(571, ".HORSE"),
            new RichEnumOption(572, ".HOSPITAL"),
            new RichEnumOption(573, ".HOST"),
            new RichEnumOption(574, ".HOSTING"),
            new RichEnumOption(575, ".HOT"),
            new RichEnumOption(576, ".HOTELES"),
            new RichEnumOption(577, ".HOTELS"),
            new RichEnumOption(578, ".HOTMAIL"),
            new RichEnumOption(579, ".HOUSE"),
            new RichEnumOption(580, ".HOW"),
            new RichEnumOption(581, ".HR"),
            new RichEnumOption(582, ".HSBC"),
            new RichEnumOption(583, ".HT"),
            new RichEnumOption(584, ".HU"),
            new RichEnumOption(585, ".HUGHES"),
            new RichEnumOption(586, ".HYATT"),
            new RichEnumOption(587, ".HYUNDAI"),
            new RichEnumOption(588, ".IBM"),
            new RichEnumOption(589, ".ICBC"),
            new RichEnumOption(590, ".ICE"),
            new RichEnumOption(591, ".ICU"),
            new RichEnumOption(592, ".ID"),
            new RichEnumOption(593, ".IE"),
            new RichEnumOption(594, ".IEEE"),
            new RichEnumOption(595, ".IFM"),
            new RichEnumOption(596, ".IKANO"),
            new RichEnumOption(597, ".IL"),
            new RichEnumOption(598, ".IM"),
            new RichEnumOption(599, ".IMAMAT"),
            new RichEnumOption(600, ".IMDB"),
            new RichEnumOption(601, ".IMMO"),
            new RichEnumOption(602, ".IMMOBILIEN"),
            new RichEnumOption(603, ".IN"),
            new RichEnumOption(604, ".INC"),
            new RichEnumOption(605, ".INDUSTRIES"),
            new RichEnumOption(606, ".INFINITI"),
            new RichEnumOption(607, ".INFO"),
            new RichEnumOption(608, ".ING"),
            new RichEnumOption(609, ".INK"),
            new RichEnumOption(610, ".INSTITUTE"),
            new RichEnumOption(611, ".INSURANCE"),
            new RichEnumOption(612, ".INSURE"),
            new RichEnumOption(613, ".INT"),
            new RichEnumOption(614, ".INTEL"),
            new RichEnumOption(615, ".INTERNATIONAL"),
            new RichEnumOption(616, ".INTUIT"),
            new RichEnumOption(617, ".INVESTMENTS"),
            new RichEnumOption(618, ".IO"),
            new RichEnumOption(619, ".IPIRANGA"),
            new RichEnumOption(620, ".IQ"),
            new RichEnumOption(621, ".IR"),
            new RichEnumOption(622, ".IRISH"),
            new RichEnumOption(623, ".IS"),
            new RichEnumOption(624, ".ISMAILI"),
            new RichEnumOption(625, ".IST"),
            new RichEnumOption(626, ".ISTANBUL"),
            new RichEnumOption(628, ".ITAU"),
            new RichEnumOption(629, ".ITV"),
            new RichEnumOption(630, ".IVECO"),
            new RichEnumOption(631, ".JAGUAR"),
            new RichEnumOption(632, ".JAVA"),
            new RichEnumOption(633, ".JCB"),
            new RichEnumOption(634, ".JCP"),
            new RichEnumOption(635, ".JE"),
            new RichEnumOption(636, ".JEEP"),
            new RichEnumOption(637, ".JETZT"),
            new RichEnumOption(638, ".JEWELRY"),
            new RichEnumOption(639, ".JIO"),
            new RichEnumOption(640, ".JLL"),
            new RichEnumOption(641, ".JM"),
            new RichEnumOption(642, ".JMP"),
            new RichEnumOption(643, ".JNJ"),
            new RichEnumOption(644, ".JO"),
            new RichEnumOption(645, ".JOBS"),
            new RichEnumOption(646, ".JOBURG"),
            new RichEnumOption(647, ".JOT"),
            new RichEnumOption(648, ".JOY"),
            new RichEnumOption(649, ".JP"),
            new RichEnumOption(650, ".JPMORGAN"),
            new RichEnumOption(651, ".JPRS"),
            new RichEnumOption(652, ".JUEGOS"),
            new RichEnumOption(653, ".JUNIPER"),
            new RichEnumOption(654, ".KAUFEN"),
            new RichEnumOption(655, ".KDDI"),
            new RichEnumOption(656, ".KE"),
            new RichEnumOption(657, ".KERRYHOTELS"),
            new RichEnumOption(658, ".KERRYLOGISTICS"),
            new RichEnumOption(659, ".KERRYPROPERTIES"),
            new RichEnumOption(660, ".KFH"),
            new RichEnumOption(661, ".KG"),
            new RichEnumOption(662, ".KH"),
            new RichEnumOption(663, ".KI"),
            new RichEnumOption(664, ".KIA"),
            new RichEnumOption(665, ".KIM"),
            new RichEnumOption(666, ".KINDER"),
            new RichEnumOption(667, ".KINDLE"),
            new RichEnumOption(668, ".KITCHEN"),
            new RichEnumOption(669, ".KIWI"),
            new RichEnumOption(670, ".KM"),
            new RichEnumOption(671, ".KN"),
            new RichEnumOption(672, ".KOELN"),
            new RichEnumOption(673, ".KOMATSU"),
            new RichEnumOption(674, ".KOSHER"),
            new RichEnumOption(675, ".KP"),
            new RichEnumOption(676, ".KPMG"),
            new RichEnumOption(677, ".KPN"),
            new RichEnumOption(678, ".KR"),
            new RichEnumOption(679, ".KRD"),
            new RichEnumOption(680, ".KRED"),
            new RichEnumOption(681, ".KUOKGROUP"),
            new RichEnumOption(682, ".KW"),
            new RichEnumOption(683, ".KY"),
            new RichEnumOption(684, ".KYOTO"),
            new RichEnumOption(685, ".KZ"),
            new RichEnumOption(686, ".LA"),
            new RichEnumOption(687, ".LACAIXA"),
            new RichEnumOption(688, ".LAMBORGHINI"),
            new RichEnumOption(689, ".LAMER"),
            new RichEnumOption(690, ".LANCASTER"),
            new RichEnumOption(691, ".LANCIA"),
            new RichEnumOption(692, ".LAND"),
            new RichEnumOption(693, ".LANDROVER"),
            new RichEnumOption(694, ".LANXESS"),
            new RichEnumOption(695, ".LASALLE"),
            new RichEnumOption(696, ".LAT"),
            new RichEnumOption(697, ".LATINO"),
            new RichEnumOption(698, ".LATROBE"),
            new RichEnumOption(699, ".LAW"),
            new RichEnumOption(700, ".LAWYER"),
            new RichEnumOption(701, ".LB"),
            new RichEnumOption(702, ".LC"),
            new RichEnumOption(703, ".LDS"),
            new RichEnumOption(704, ".LEASE"),
            new RichEnumOption(705, ".LECLERC"),
            new RichEnumOption(706, ".LEFRAK"),
            new RichEnumOption(707, ".LEGAL"),
            new RichEnumOption(708, ".LEGO"),
            new RichEnumOption(709, ".LEXUS"),
            new RichEnumOption(710, ".LGBT"),
            new RichEnumOption(711, ".LI"),
            new RichEnumOption(712, ".LIDL"),
            new RichEnumOption(713, ".LIFE"),
            new RichEnumOption(714, ".LIFEINSURANCE"),
            new RichEnumOption(715, ".LIFESTYLE"),
            new RichEnumOption(716, ".LIGHTING"),
            new RichEnumOption(717, ".LIKE"),
            new RichEnumOption(718, ".LILLY"),
            new RichEnumOption(719, ".LIMITED"),
            new RichEnumOption(720, ".LIMO"),
            new RichEnumOption(721, ".LINCOLN"),
            new RichEnumOption(722, ".LINDE"),
            new RichEnumOption(723, ".LINK"),
            new RichEnumOption(724, ".LIPSY"),
            new RichEnumOption(725, ".LIVE"),
            new RichEnumOption(726, ".LIVING"),
            new RichEnumOption(727, ".LIXIL"),
            new RichEnumOption(728, ".LK"),
            new RichEnumOption(729, ".LLC"),
            new RichEnumOption(730, ".LLP"),
            new RichEnumOption(731, ".LOAN"),
            new RichEnumOption(732, ".LOANS"),
            new RichEnumOption(733, ".LOCKER"),
            new RichEnumOption(734, ".LOCUS"),
            new RichEnumOption(735, ".LOFT"),
            new RichEnumOption(736, ".LOL"),
            new RichEnumOption(737, ".LONDON"),
            new RichEnumOption(738, ".LOTTE"),
            new RichEnumOption(739, ".LOTTO"),
            new RichEnumOption(740, ".LOVE"),
            new RichEnumOption(741, ".LPL"),
            new RichEnumOption(742, ".LPLFINANCIAL"),
            new RichEnumOption(743, ".LR"),
            new RichEnumOption(744, ".LS"),
            new RichEnumOption(745, ".LT"),
            new RichEnumOption(746, ".LTD"),
            new RichEnumOption(747, ".LTDA"),
            new RichEnumOption(748, ".LU"),
            new RichEnumOption(749, ".LUNDBECK"),
            new RichEnumOption(750, ".LUPIN"),
            new RichEnumOption(751, ".LUXE"),
            new RichEnumOption(752, ".LUXURY"),
            new RichEnumOption(753, ".LV"),
            new RichEnumOption(754, ".LY"),
            new RichEnumOption(755, ".MA"),
            new RichEnumOption(756, ".MACYS"),
            new RichEnumOption(757, ".MADRID"),
            new RichEnumOption(758, ".MAIF"),
            new RichEnumOption(759, ".MAISON"),
            new RichEnumOption(760, ".MAKEUP"),
            new RichEnumOption(761, ".MAN"),
            new RichEnumOption(762, ".MANAGEMENT"),
            new RichEnumOption(763, ".MANGO"),
            new RichEnumOption(764, ".MAP"),
            new RichEnumOption(765, ".MARKET"),
            new RichEnumOption(766, ".MARKETING"),
            new RichEnumOption(767, ".MARKETS"),
            new RichEnumOption(768, ".MARRIOTT"),
            new RichEnumOption(769, ".MARSHALLS"),
            new RichEnumOption(770, ".MASERATI"),
            new RichEnumOption(771, ".MATTEL"),
            new RichEnumOption(772, ".MBA"),
            new RichEnumOption(773, ".MC"),
            new RichEnumOption(774, ".MCKINSEY"),
            new RichEnumOption(775, ".MD"),
            new RichEnumOption(776, ".ME"),
            new RichEnumOption(777, ".MED"),
            new RichEnumOption(778, ".MEDIA"),
            new RichEnumOption(779, ".MEET"),
            new RichEnumOption(780, ".MELBOURNE"),
            new RichEnumOption(781, ".MEME"),
            new RichEnumOption(782, ".MEMORIAL"),
            new RichEnumOption(783, ".MEN"),
            new RichEnumOption(784, ".MENU"),
            new RichEnumOption(785, ".MERCKMSD"),
            new RichEnumOption(786, ".METLIFE"),
            new RichEnumOption(787, ".MG"),
            new RichEnumOption(788, ".MH"),
            new RichEnumOption(789, ".MIAMI"),
            new RichEnumOption(790, ".MICROSOFT"),
            new RichEnumOption(791, ".MIL"),
            new RichEnumOption(792, ".MINI"),
            new RichEnumOption(793, ".MINT"),
            new RichEnumOption(794, ".MIT"),
            new RichEnumOption(795, ".MITSUBISHI"),
            new RichEnumOption(796, ".MK"),
            new RichEnumOption(797, ".ML"),
            new RichEnumOption(798, ".MLB"),
            new RichEnumOption(799, ".MLS"),
            new RichEnumOption(800, ".MM"),
            new RichEnumOption(801, ".MMA"),
            new RichEnumOption(802, ".MN"),
            new RichEnumOption(803, ".MO"),
            new RichEnumOption(804, ".MOBI"),
            new RichEnumOption(805, ".MOBILE"),
            new RichEnumOption(806, ".MODA"),
            new RichEnumOption(807, ".MOE"),
            new RichEnumOption(808, ".MOI"),
            new RichEnumOption(809, ".MOM"),
            new RichEnumOption(810, ".MONASH"),
            new RichEnumOption(811, ".MONEY"),
            new RichEnumOption(812, ".MONSTER"),
            new RichEnumOption(813, ".MORMON"),
            new RichEnumOption(814, ".MORTGAGE"),
            new RichEnumOption(815, ".MOSCOW"),
            new RichEnumOption(816, ".MOTO"),
            new RichEnumOption(817, ".MOTORCYCLES"),
            new RichEnumOption(818, ".MOV"),
            new RichEnumOption(819, ".MOVIE"),
            new RichEnumOption(820, ".MP"),
            new RichEnumOption(821, ".MQ"),
            new RichEnumOption(822, ".MR"),
            new RichEnumOption(823, ".MS"),
            new RichEnumOption(824, ".MSD"),
            new RichEnumOption(825, ".MT"),
            new RichEnumOption(826, ".MTN"),
            new RichEnumOption(827, ".MTR"),
            new RichEnumOption(828, ".MU"),
            new RichEnumOption(829, ".MUSEUM"),
            new RichEnumOption(830, ".MUTUAL"),
            new RichEnumOption(831, ".MV"),
            new RichEnumOption(832, ".MW"),
            new RichEnumOption(833, ".MX"),
            new RichEnumOption(834, ".MY"),
            new RichEnumOption(835, ".MZ"),
            new RichEnumOption(836, ".NA"),
            new RichEnumOption(837, ".NAB"),
            new RichEnumOption(838, ".NAGOYA"),
            new RichEnumOption(839, ".NAME"),
            new RichEnumOption(840, ".NATIONWIDE"),
            new RichEnumOption(841, ".NATURA"),
            new RichEnumOption(842, ".NAVY"),
            new RichEnumOption(843, ".NBA"),
            new RichEnumOption(844, ".NC"),
            new RichEnumOption(845, ".NE"),
            new RichEnumOption(846, ".NEC"),
            new RichEnumOption(847, ".NET"),
            new RichEnumOption(848, ".NETBANK"),
            new RichEnumOption(849, ".NETFLIX"),
            new RichEnumOption(850, ".NETWORK"),
            new RichEnumOption(851, ".NEUSTAR"),
            new RichEnumOption(852, ".NEW"),
            new RichEnumOption(853, ".NEWHOLLAND"),
            new RichEnumOption(854, ".NEWS"),
            new RichEnumOption(855, ".NEXT"),
            new RichEnumOption(856, ".NEXTDIRECT"),
            new RichEnumOption(857, ".NEXUS"),
            new RichEnumOption(858, ".NF"),
            new RichEnumOption(859, ".NFL"),
            new RichEnumOption(860, ".NG"),
            new RichEnumOption(861, ".NGO"),
            new RichEnumOption(862, ".NHK"),
            new RichEnumOption(863, ".NI"),
            new RichEnumOption(864, ".NICO"),
            new RichEnumOption(865, ".NIKE"),
            new RichEnumOption(866, ".NIKON"),
            new RichEnumOption(867, ".NINJA"),
            new RichEnumOption(868, ".NISSAN"),
            new RichEnumOption(869, ".NISSAY"),
            new RichEnumOption(870, ".NL"),
            new RichEnumOption(871, ".NO"),
            new RichEnumOption(872, ".NOKIA"),
            new RichEnumOption(873, ".NORTHWESTERNMUTUAL"),
            new RichEnumOption(874, ".NORTON"),
            new RichEnumOption(875, ".NOW"),
            new RichEnumOption(876, ".NOWRUZ"),
            new RichEnumOption(877, ".NOWTV"),
            new RichEnumOption(878, ".NP"),
            new RichEnumOption(879, ".NR"),
            new RichEnumOption(880, ".NRA"),
            new RichEnumOption(881, ".NRW"),
            new RichEnumOption(882, ".NTT"),
            new RichEnumOption(883, ".NU"),
            new RichEnumOption(884, ".NYC"),
            new RichEnumOption(885, ".NZ"),
            new RichEnumOption(886, ".OBI"),
            new RichEnumOption(887, ".OBSERVER"),
            new RichEnumOption(888, ".OFF"),
            new RichEnumOption(889, ".OFFICE"),
            new RichEnumOption(890, ".OKINAWA"),
            new RichEnumOption(891, ".OLAYAN"),
            new RichEnumOption(892, ".OLAYANGROUP"),
            new RichEnumOption(893, ".OLDNAVY"),
            new RichEnumOption(894, ".OLLO"),
            new RichEnumOption(895, ".OM"),
            new RichEnumOption(896, ".OMEGA"),
            new RichEnumOption(897, ".ONE"),
            new RichEnumOption(898, ".ONG"),
            new RichEnumOption(899, ".ONL"),
            new RichEnumOption(900, ".ONLINE"),
            new RichEnumOption(901, ".ONYOURSIDE"),
            new RichEnumOption(902, ".OOO"),
            new RichEnumOption(903, ".OPEN"),
            new RichEnumOption(904, ".ORACLE"),
            new RichEnumOption(905, ".ORANGE"),
            new RichEnumOption(906, ".ORG"),
            new RichEnumOption(907, ".ORGANIC"),
            new RichEnumOption(908, ".ORIGINS"),
            new RichEnumOption(909, ".OSAKA"),
            new RichEnumOption(910, ".OTSUKA"),
            new RichEnumOption(911, ".OTT"),
            new RichEnumOption(912, ".OVH"),
            new RichEnumOption(913, ".PA"),
            new RichEnumOption(914, ".PAGE"),
            new RichEnumOption(915, ".PANASONIC"),
            new RichEnumOption(916, ".PARIS"),
            new RichEnumOption(917, ".PARS"),
            new RichEnumOption(918, ".PARTNERS"),
            new RichEnumOption(919, ".PARTS"),
            new RichEnumOption(920, ".PARTY"),
            new RichEnumOption(921, ".PASSAGENS"),
            new RichEnumOption(922, ".PAY"),
            new RichEnumOption(923, ".PCCW"),
            new RichEnumOption(924, ".PE"),
            new RichEnumOption(925, ".PET"),
            new RichEnumOption(926, ".PF"),
            new RichEnumOption(927, ".PFIZER"),
            new RichEnumOption(928, ".PG"),
            new RichEnumOption(929, ".PH"),
            new RichEnumOption(930, ".PHARMACY"),
            new RichEnumOption(931, ".PHD"),
            new RichEnumOption(932, ".PHILIPS"),
            new RichEnumOption(933, ".PHONE"),
            new RichEnumOption(934, ".PHOTO"),
            new RichEnumOption(935, ".PHOTOGRAPHY"),
            new RichEnumOption(936, ".PHOTOS"),
            new RichEnumOption(937, ".PHYSIO"),
            new RichEnumOption(938, ".PICS"),
            new RichEnumOption(939, ".PICTET"),
            new RichEnumOption(940, ".PICTURES"),
            new RichEnumOption(941, ".PID"),
            new RichEnumOption(942, ".PIN"),
            new RichEnumOption(943, ".PING"),
            new RichEnumOption(944, ".PINK"),
            new RichEnumOption(945, ".PIONEER"),
            new RichEnumOption(946, ".PIZZA"),
            new RichEnumOption(947, ".PK"),
            new RichEnumOption(948, ".PL"),
            new RichEnumOption(949, ".PLACE"),
            new RichEnumOption(950, ".PLAY"),
            new RichEnumOption(951, ".PLAYSTATION"),
            new RichEnumOption(952, ".PLUMBING"),
            new RichEnumOption(953, ".PLUS"),
            new RichEnumOption(954, ".PM"),
            new RichEnumOption(955, ".PN"),
            new RichEnumOption(956, ".PNC"),
            new RichEnumOption(957, ".POHL"),
            new RichEnumOption(958, ".POKER"),
            new RichEnumOption(959, ".POLITIE"),
            new RichEnumOption(960, ".PORN"),
            new RichEnumOption(961, ".POST"),
            new RichEnumOption(962, ".PR"),
            new RichEnumOption(963, ".PRAMERICA"),
            new RichEnumOption(964, ".PRAXI"),
            new RichEnumOption(965, ".PRESS"),
            new RichEnumOption(966, ".PRIME"),
            new RichEnumOption(967, ".PRO"),
            new RichEnumOption(968, ".PROD"),
            new RichEnumOption(969, ".PRODUCTIONS"),
            new RichEnumOption(970, ".PROF"),
            new RichEnumOption(971, ".PROGRESSIVE"),
            new RichEnumOption(972, ".PROMO"),
            new RichEnumOption(973, ".PROPERTIES"),
            new RichEnumOption(974, ".PROPERTY"),
            new RichEnumOption(975, ".PROTECTION"),
            new RichEnumOption(976, ".PRU"),
            new RichEnumOption(977, ".PRUDENTIAL"),
            new RichEnumOption(978, ".PS"),
            new RichEnumOption(979, ".PT"),
            new RichEnumOption(980, ".PUB"),
            new RichEnumOption(981, ".PW"),
            new RichEnumOption(982, ".PWC"),
            new RichEnumOption(983, ".PY"),
            new RichEnumOption(984, ".QA"),
            new RichEnumOption(985, ".QPON"),
            new RichEnumOption(986, ".QUEBEC"),
            new RichEnumOption(987, ".QUEST"),
            new RichEnumOption(988, ".QVC"),
            new RichEnumOption(989, ".RACING"),
            new RichEnumOption(990, ".RADIO"),
            new RichEnumOption(991, ".RAID"),
            new RichEnumOption(992, ".RE"),
            new RichEnumOption(993, ".READ"),
            new RichEnumOption(994, ".REALESTATE"),
            new RichEnumOption(995, ".REALTOR"),
            new RichEnumOption(996, ".REALTY"),
            new RichEnumOption(997, ".RECIPES"),
            new RichEnumOption(998, ".RED"),
            new RichEnumOption(999, ".REDSTONE"),
            new RichEnumOption(1000, ".REDUMBRELLA"),
            new RichEnumOption(1001, ".REHAB"),
            new RichEnumOption(1002, ".REISE"),
            new RichEnumOption(1003, ".REISEN"),
            new RichEnumOption(1004, ".REIT"),
            new RichEnumOption(1005, ".RELIANCE"),
            new RichEnumOption(1006, ".REN"),
            new RichEnumOption(1007, ".RENT"),
            new RichEnumOption(1008, ".RENTALS"),
            new RichEnumOption(1009, ".REPAIR"),
            new RichEnumOption(1010, ".REPORT"),
            new RichEnumOption(1011, ".REPUBLICAN"),
            new RichEnumOption(1012, ".REST"),
            new RichEnumOption(1013, ".RESTAURANT"),
            new RichEnumOption(1014, ".REVIEW"),
            new RichEnumOption(1015, ".REVIEWS"),
            new RichEnumOption(1016, ".REXROTH"),
            new RichEnumOption(1017, ".RICH"),
            new RichEnumOption(1018, ".RICHARDLI"),
            new RichEnumOption(1019, ".RICOH"),
            new RichEnumOption(1020, ".RIGHTATHOME"),
            new RichEnumOption(1021, ".RIL"),
            new RichEnumOption(1022, ".RIO"),
            new RichEnumOption(1023, ".RIP"),
            new RichEnumOption(1024, ".RMIT"),
            new RichEnumOption(1025, ".RO"),
            new RichEnumOption(1026, ".ROCHER"),
            new RichEnumOption(1027, ".ROCKS"),
            new RichEnumOption(1028, ".RODEO"),
            new RichEnumOption(1029, ".ROGERS"),
            new RichEnumOption(1030, ".ROOM"),
            new RichEnumOption(1031, ".RS"),
            new RichEnumOption(1032, ".RSVP"),
            new RichEnumOption(1033, ".RU"),
            new RichEnumOption(1034, ".RUGBY"),
            new RichEnumOption(1035, ".RUHR"),
            new RichEnumOption(1036, ".RUN"),
            new RichEnumOption(1037, ".RW"),
            new RichEnumOption(1038, ".RWE"),
            new RichEnumOption(1039, ".RYUKYU"),
            new RichEnumOption(1040, ".SA"),
            new RichEnumOption(1041, ".SAARLAND"),
            new RichEnumOption(1042, ".SAFE"),
            new RichEnumOption(1043, ".SAFETY"),
            new RichEnumOption(1044, ".SAKURA"),
            new RichEnumOption(1045, ".SALE"),
            new RichEnumOption(1046, ".SALON"),
            new RichEnumOption(1047, ".SAMSCLUB"),
            new RichEnumOption(1048, ".SAMSUNG"),
            new RichEnumOption(1049, ".SANDVIK"),
            new RichEnumOption(1050, ".SANDVIKCOROMANT"),
            new RichEnumOption(1051, ".SANOFI"),
            new RichEnumOption(1052, ".SAP"),
            new RichEnumOption(1053, ".SARL"),
            new RichEnumOption(1054, ".SAS"),
            new RichEnumOption(1055, ".SAVE"),
            new RichEnumOption(1056, ".SAXO"),
            new RichEnumOption(1057, ".SB"),
            new RichEnumOption(1058, ".SBI"),
            new RichEnumOption(1059, ".SBS"),
            new RichEnumOption(1060, ".SC"),
            new RichEnumOption(1061, ".SCA"),
            new RichEnumOption(1062, ".SCB"),
            new RichEnumOption(1063, ".SCHAEFFLER"),
            new RichEnumOption(1064, ".SCHMIDT"),
            new RichEnumOption(1065, ".SCHOLARSHIPS"),
            new RichEnumOption(1066, ".SCHOOL"),
            new RichEnumOption(1067, ".SCHULE"),
            new RichEnumOption(1068, ".SCHWARZ"),
            new RichEnumOption(1069, ".SCIENCE"),
            new RichEnumOption(1070, ".SCJOHNSON"),
            new RichEnumOption(1071, ".SCOR"),
            new RichEnumOption(1072, ".SCOT"),
            new RichEnumOption(1073, ".SD"),
            new RichEnumOption(1074, ".SE"),
            new RichEnumOption(1075, ".SEARCH"),
            new RichEnumOption(1076, ".SEAT"),
            new RichEnumOption(1077, ".SECURE"),
            new RichEnumOption(1078, ".SECURITY"),
            new RichEnumOption(1079, ".SEEK"),
            new RichEnumOption(1080, ".SELECT"),
            new RichEnumOption(1081, ".SENER"),
            new RichEnumOption(1082, ".SERVICES"),
            new RichEnumOption(1083, ".SES"),
            new RichEnumOption(1084, ".SEVEN"),
            new RichEnumOption(1085, ".SEW"),
            new RichEnumOption(1086, ".SEX"),
            new RichEnumOption(1087, ".SEXY"),
            new RichEnumOption(1088, ".SFR"),
            new RichEnumOption(1089, ".SG"),
            new RichEnumOption(1090, ".SH"),
            new RichEnumOption(1091, ".SHANGRILA"),
            new RichEnumOption(1092, ".SHARP"),
            new RichEnumOption(1093, ".SHAW"),
            new RichEnumOption(1094, ".SHELL"),
            new RichEnumOption(1095, ".SHIA"),
            new RichEnumOption(1096, ".SHIKSHA"),
            new RichEnumOption(1097, ".SHOES"),
            new RichEnumOption(1098, ".SHOP"),
            new RichEnumOption(1099, ".SHOPPING"),
            new RichEnumOption(1100, ".SHOUJI"),
            new RichEnumOption(1101, ".SHOW"),
            new RichEnumOption(1102, ".SHOWTIME"),
            new RichEnumOption(1103, ".SHRIRAM"),
            new RichEnumOption(1104, ".SI"),
            new RichEnumOption(1105, ".SILK"),
            new RichEnumOption(1106, ".SINA"),
            new RichEnumOption(1107, ".SINGLES"),
            new RichEnumOption(1108, ".SITE"),
            new RichEnumOption(1109, ".SJ"),
            new RichEnumOption(1110, ".SK"),
            new RichEnumOption(1111, ".SKI"),
            new RichEnumOption(1112, ".SKIN"),
            new RichEnumOption(1113, ".SKY"),
            new RichEnumOption(1114, ".SKYPE"),
            new RichEnumOption(1115, ".SL"),
            new RichEnumOption(1116, ".SLING"),
            new RichEnumOption(1117, ".SM"),
            new RichEnumOption(1118, ".SMART"),
            new RichEnumOption(1119, ".SMILE"),
            new RichEnumOption(1120, ".SN"),
            new RichEnumOption(1121, ".SNCF"),
            new RichEnumOption(1122, ".SO"),
            new RichEnumOption(1123, ".SOCCER"),
            new RichEnumOption(1124, ".SOCIAL"),
            new RichEnumOption(1125, ".SOFTBANK"),
            new RichEnumOption(1126, ".SOFTWARE"),
            new RichEnumOption(1127, ".SOHU"),
            new RichEnumOption(1128, ".SOLAR"),
            new RichEnumOption(1129, ".SOLUTIONS"),
            new RichEnumOption(1130, ".SONG"),
            new RichEnumOption(1131, ".SONY"),
            new RichEnumOption(1132, ".SOY"),
            new RichEnumOption(1133, ".SPACE"),
            new RichEnumOption(1134, ".SPORT"),
            new RichEnumOption(1135, ".SPOT"),
            new RichEnumOption(1136, ".SPREADBETTING"),
            new RichEnumOption(1137, ".SR"),
            new RichEnumOption(1138, ".SRL"),
            new RichEnumOption(1139, ".SS"),
            new RichEnumOption(1140, ".ST"),
            new RichEnumOption(1141, ".STADA"),
            new RichEnumOption(1142, ".STAPLES"),
            new RichEnumOption(1143, ".STAR"),
            new RichEnumOption(1144, ".STATEBANK"),
            new RichEnumOption(1145, ".STATEFARM"),
            new RichEnumOption(1146, ".STC"),
            new RichEnumOption(1147, ".STCGROUP"),
            new RichEnumOption(1148, ".STOCKHOLM"),
            new RichEnumOption(1149, ".STORAGE"),
            new RichEnumOption(1150, ".STORE"),
            new RichEnumOption(1151, ".STREAM"),
            new RichEnumOption(1152, ".STUDIO"),
            new RichEnumOption(1153, ".STUDY"),
            new RichEnumOption(1154, ".STYLE"),
            new RichEnumOption(1155, ".SU"),
            new RichEnumOption(1156, ".SUCKS"),
            new RichEnumOption(1157, ".SUPPLIES"),
            new RichEnumOption(1158, ".SUPPLY"),
            new RichEnumOption(1159, ".SUPPORT"),
            new RichEnumOption(1160, ".SURF"),
            new RichEnumOption(1161, ".SURGERY"),
            new RichEnumOption(1162, ".SUZUKI"),
            new RichEnumOption(1163, ".SV"),
            new RichEnumOption(1164, ".SWATCH"),
            new RichEnumOption(1165, ".SWIFTCOVER"),
            new RichEnumOption(1166, ".SWISS"),
            new RichEnumOption(1167, ".SX"),
            new RichEnumOption(1168, ".SY"),
            new RichEnumOption(1169, ".SYDNEY"),
            new RichEnumOption(1170, ".SYMANTEC"),
            new RichEnumOption(1171, ".SYSTEMS"),
            new RichEnumOption(1172, ".SZ"),
            new RichEnumOption(1173, ".TAB"),
            new RichEnumOption(1174, ".TAIPEI"),
            new RichEnumOption(1175, ".TALK"),
            new RichEnumOption(1176, ".TAOBAO"),
            new RichEnumOption(1177, ".TARGET"),
            new RichEnumOption(1178, ".TATAMOTORS"),
            new RichEnumOption(1179, ".TATAR"),
            new RichEnumOption(1180, ".TATTOO"),
            new RichEnumOption(1181, ".TAX"),
            new RichEnumOption(1182, ".TAXI"),
            new RichEnumOption(1183, ".TC"),
            new RichEnumOption(1184, ".TCI"),
            new RichEnumOption(1185, ".TD"),
            new RichEnumOption(1186, ".TDK"),
            new RichEnumOption(1187, ".TEAM"),
            new RichEnumOption(1188, ".TECH"),
            new RichEnumOption(1189, ".TECHNOLOGY"),
            new RichEnumOption(1190, ".TEL"),
            new RichEnumOption(1191, ".TEMASEK"),
            new RichEnumOption(1192, ".TENNIS"),
            new RichEnumOption(1193, ".TEVA"),
            new RichEnumOption(1194, ".TF"),
            new RichEnumOption(1195, ".TG"),
            new RichEnumOption(1196, ".TH"),
            new RichEnumOption(1197, ".THD"),
            new RichEnumOption(1198, ".THEATER"),
            new RichEnumOption(1199, ".THEATRE"),
            new RichEnumOption(1200, ".TIAA"),
            new RichEnumOption(1201, ".TICKETS"),
            new RichEnumOption(1202, ".TIENDA"),
            new RichEnumOption(1203, ".TIFFANY"),
            new RichEnumOption(1204, ".TIPS"),
            new RichEnumOption(1205, ".TIRES"),
            new RichEnumOption(1206, ".TIROL"),
            new RichEnumOption(1207, ".TJ"),
            new RichEnumOption(1208, ".TJMAXX"),
            new RichEnumOption(1209, ".TJX"),
            new RichEnumOption(1210, ".TK"),
            new RichEnumOption(1211, ".TKMAXX"),
            new RichEnumOption(1212, ".TL"),
            new RichEnumOption(1213, ".TM"),
            new RichEnumOption(1214, ".TMALL"),
            new RichEnumOption(1215, ".TN"),
            new RichEnumOption(1216, ".TO"),
            new RichEnumOption(1217, ".TODAY"),
            new RichEnumOption(1218, ".TOKYO"),
            new RichEnumOption(1219, ".TOOLS"),
            new RichEnumOption(1220, ".TOP"),
            new RichEnumOption(1221, ".TORAY"),
            new RichEnumOption(1222, ".TOSHIBA"),
            new RichEnumOption(1223, ".TOTAL"),
            new RichEnumOption(1224, ".TOURS"),
            new RichEnumOption(1225, ".TOWN"),
            new RichEnumOption(1226, ".TOYOTA"),
            new RichEnumOption(1227, ".TOYS"),
            new RichEnumOption(1228, ".TR"),
            new RichEnumOption(1229, ".TRADE"),
            new RichEnumOption(1230, ".TRADING"),
            new RichEnumOption(1231, ".TRAINING"),
            new RichEnumOption(1232, ".TRAVEL"),
            new RichEnumOption(1233, ".TRAVELCHANNEL"),
            new RichEnumOption(1234, ".TRAVELERS"),
            new RichEnumOption(1235, ".TRAVELERSINSURANCE"),
            new RichEnumOption(1236, ".TRUST"),
            new RichEnumOption(1237, ".TRV"),
            new RichEnumOption(1238, ".TT"),
            new RichEnumOption(1239, ".TUBE"),
            new RichEnumOption(1240, ".TUI"),
            new RichEnumOption(1241, ".TUNES"),
            new RichEnumOption(1242, ".TUSHU"),
            new RichEnumOption(1243, ".TV"),
            new RichEnumOption(1244, ".TVS"),
            new RichEnumOption(1245, ".TW"),
            new RichEnumOption(1246, ".TZ"),
            new RichEnumOption(1247, ".UA"),
            new RichEnumOption(1248, ".UBANK"),
            new RichEnumOption(1249, ".UBS"),
            new RichEnumOption(1250, ".UG"),
            new RichEnumOption(1252, ".UNICOM"),
            new RichEnumOption(1253, ".UNIVERSITY"),
            new RichEnumOption(1254, ".UNO"),
            new RichEnumOption(1255, ".UOL"),
            new RichEnumOption(1256, ".UPS"),
            new RichEnumOption(1257, ".US"),
            new RichEnumOption(1258, ".UY"),
            new RichEnumOption(1259, ".UZ"),
            new RichEnumOption(1260, ".VA"),
            new RichEnumOption(1261, ".VACATIONS"),
            new RichEnumOption(1262, ".VANA"),
            new RichEnumOption(1263, ".VANGUARD"),
            new RichEnumOption(1264, ".VC"),
            new RichEnumOption(1265, ".VE"),
            new RichEnumOption(1266, ".VEGAS"),
            new RichEnumOption(1267, ".VENTURES"),
            new RichEnumOption(1268, ".VERISIGN"),
            new RichEnumOption(1269, ".VERSICHERUNG"),
            new RichEnumOption(1270, ".VET"),
            new RichEnumOption(1271, ".VG"),
            new RichEnumOption(1272, ".VI"),
            new RichEnumOption(1273, ".VIAJES"),
            new RichEnumOption(1274, ".VIDEO"),
            new RichEnumOption(1275, ".VIG"),
            new RichEnumOption(1276, ".VIKING"),
            new RichEnumOption(1277, ".VILLAS"),
            new RichEnumOption(1278, ".VIN"),
            new RichEnumOption(1279, ".VIP"),
            new RichEnumOption(1280, ".VIRGIN"),
            new RichEnumOption(1281, ".VISA"),
            new RichEnumOption(1282, ".VISION"),
            new RichEnumOption(1283, ".VIVA"),
            new RichEnumOption(1284, ".VIVO"),
            new RichEnumOption(1285, ".VLAANDEREN"),
            new RichEnumOption(1286, ".VN"),
            new RichEnumOption(1287, ".VODKA"),
            new RichEnumOption(1288, ".VOLKSWAGEN"),
            new RichEnumOption(1289, ".VOLVO"),
            new RichEnumOption(1290, ".VOTE"),
            new RichEnumOption(1291, ".VOTING"),
            new RichEnumOption(1292, ".VOTO"),
            new RichEnumOption(1293, ".VOYAGE"),
            new RichEnumOption(1294, ".VU"),
            new RichEnumOption(1295, ".VUELOS"),
            new RichEnumOption(1296, ".WALES"),
            new RichEnumOption(1297, ".WALMART"),
            new RichEnumOption(1298, ".WALTER"),
            new RichEnumOption(1299, ".WANG"),
            new RichEnumOption(1300, ".WANGGOU"),
            new RichEnumOption(1301, ".WATCH"),
            new RichEnumOption(1302, ".WATCHES"),
            new RichEnumOption(1303, ".WEATHER"),
            new RichEnumOption(1304, ".WEATHERCHANNEL"),
            new RichEnumOption(1305, ".WEBCAM"),
            new RichEnumOption(1306, ".WEBER"),
            new RichEnumOption(1307, ".WEBSITE"),
            new RichEnumOption(1308, ".WED"),
            new RichEnumOption(1309, ".WEDDING"),
            new RichEnumOption(1310, ".WEIBO"),
            new RichEnumOption(1311, ".WEIR"),
            new RichEnumOption(1312, ".WF"),
            new RichEnumOption(1313, ".WHOSWHO"),
            new RichEnumOption(1314, ".WIEN"),
            new RichEnumOption(1315, ".WIKI"),
            new RichEnumOption(1316, ".WILLIAMHILL"),
            new RichEnumOption(1317, ".WIN"),
            new RichEnumOption(1318, ".WINDOWS"),
            new RichEnumOption(1319, ".WINE"),
            new RichEnumOption(1320, ".WINNERS"),
            new RichEnumOption(1321, ".WME"),
            new RichEnumOption(1322, ".WOLTERSKLUWER"),
            new RichEnumOption(1323, ".WOODSIDE"),
            new RichEnumOption(1324, ".WORK"),
            new RichEnumOption(1325, ".WORKS"),
            new RichEnumOption(1326, ".WORLD"),
            new RichEnumOption(1327, ".WOW"),
            new RichEnumOption(1328, ".WS"),
            new RichEnumOption(1329, ".WTC"),
            new RichEnumOption(1330, ".WTF"),
            new RichEnumOption(1331, ".XBOX"),
            new RichEnumOption(1332, ".XEROX"),
            new RichEnumOption(1333, ".XFINITY"),
            new RichEnumOption(1334, ".XIHUAN"),
            new RichEnumOption(1335, ".XIN"),
            new RichEnumOption(1336, ".XN--11B4C3D"),
            new RichEnumOption(1337, ".XN--1CK2E1B"),
            new RichEnumOption(1338, ".XN--1QQW23A"),
            new RichEnumOption(1339, ".XN--2SCRJ9C"),
            new RichEnumOption(1340, ".XN--30RR7Y"),
            new RichEnumOption(1341, ".XN--3BST00M"),
            new RichEnumOption(1342, ".XN--3DS443G"),
            new RichEnumOption(1343, ".XN--3E0B707E"),
            new RichEnumOption(1344, ".XN--3HCRJ9C"),
            new RichEnumOption(1345, ".XN--3OQ18VL8PN36A"),
            new RichEnumOption(1346, ".XN--3PXU8K"),
            new RichEnumOption(1347, ".XN--42C2D9A"),
            new RichEnumOption(1348, ".XN--45BR5CYL"),
            new RichEnumOption(1349, ".XN--45BRJ9C"),
            new RichEnumOption(1350, ".XN--45Q11C"),
            new RichEnumOption(1351, ".XN--4GBRIM"),
            new RichEnumOption(1352, ".XN--54B7FTA0CC"),
            new RichEnumOption(1353, ".XN--55QW42G"),
            new RichEnumOption(1354, ".XN--55QX5D"),
            new RichEnumOption(1355, ".XN--5SU34J936BGSG"),
            new RichEnumOption(1356, ".XN--5TZM5G"),
            new RichEnumOption(1357, ".XN--6FRZ82G"),
            new RichEnumOption(1358, ".XN--6QQ986B3XL"),
            new RichEnumOption(1359, ".XN--80ADXHKS"),
            new RichEnumOption(1360, ".XN--80AO21A"),
            new RichEnumOption(1361, ".XN--80AQECDR1A"),
            new RichEnumOption(1362, ".XN--80ASEHDB"),
            new RichEnumOption(1363, ".XN--80ASWG"),
            new RichEnumOption(1364, ".XN--8Y0A063A"),
            new RichEnumOption(1365, ".XN--90A3AC"),
            new RichEnumOption(1366, ".XN--90AE"),
            new RichEnumOption(1367, ".XN--90AIS"),
            new RichEnumOption(1368, ".XN--9DBQ2A"),
            new RichEnumOption(1369, ".XN--9ET52U"),
            new RichEnumOption(1370, ".XN--9KRT00A"),
            new RichEnumOption(1371, ".XN--B4W605FERD"),
            new RichEnumOption(1372, ".XN--BCK1B9A5DRE4C"),
            new RichEnumOption(1373, ".XN--C1AVG"),
            new RichEnumOption(1374, ".XN--C2BR7G"),
            new RichEnumOption(1375, ".XN--CCK2B3B"),
            new RichEnumOption(1376, ".XN--CG4BKI"),
            new RichEnumOption(1377, ".XN--CLCHC0EA0B2G2A9GCD"),
            new RichEnumOption(1378, ".XN--CZR694B"),
            new RichEnumOption(1379, ".XN--CZRS0T"),
            new RichEnumOption(1380, ".XN--CZRU2D"),
            new RichEnumOption(1381, ".XN--D1ACJ3B"),
            new RichEnumOption(1382, ".XN--D1ALF"),
            new RichEnumOption(1383, ".XN--E1A4C"),
            new RichEnumOption(1384, ".XN--ECKVDTC9D"),
            new RichEnumOption(1385, ".XN--EFVY88H"),
            new RichEnumOption(1386, ".XN--FCT429K"),
            new RichEnumOption(1387, ".XN--FHBEI"),
            new RichEnumOption(1388, ".XN--FIQ228C5HS"),
            new RichEnumOption(1389, ".XN--FIQ64B"),
            new RichEnumOption(1390, ".XN--FIQS8S"),
            new RichEnumOption(1391, ".XN--FIQZ9S"),
            new RichEnumOption(1392, ".XN--FJQ720A"),
            new RichEnumOption(1393, ".XN--FLW351E"),
            new RichEnumOption(1394, ".XN--FPCRJ9C3D"),
            new RichEnumOption(1395, ".XN--FZC2C9E2C"),
            new RichEnumOption(1396, ".XN--FZYS8D69UVGM"),
            new RichEnumOption(1397, ".XN--G2XX48C"),
            new RichEnumOption(1398, ".XN--GCKR3F0F"),
            new RichEnumOption(1399, ".XN--GECRJ9C"),
            new RichEnumOption(1400, ".XN--GK3AT1E"),
            new RichEnumOption(1401, ".XN--H2BREG3EVE"),
            new RichEnumOption(1402, ".XN--H2BRJ9C"),
            new RichEnumOption(1403, ".XN--H2BRJ9C8C"),
            new RichEnumOption(1404, ".XN--HXT814E"),
            new RichEnumOption(1405, ".XN--I1B6B1A6A2E"),
            new RichEnumOption(1406, ".XN--IMR513N"),
            new RichEnumOption(1407, ".XN--IO0A7I"),
            new RichEnumOption(1408, ".XN--J1AEF"),
            new RichEnumOption(1409, ".XN--J1AMH"),
            new RichEnumOption(1410, ".XN--J6W193G"),
            new RichEnumOption(1411, ".XN--JLQ61U9W7B"),
            new RichEnumOption(1412, ".XN--JVR189M"),
            new RichEnumOption(1413, ".XN--KCRX77D1X4A"),
            new RichEnumOption(1414, ".XN--KPRW13D"),
            new RichEnumOption(1415, ".XN--KPRY57D"),
            new RichEnumOption(1416, ".XN--KPU716F"),
            new RichEnumOption(1417, ".XN--KPUT3I"),
            new RichEnumOption(1418, ".XN--L1ACC"),
            new RichEnumOption(1419, ".XN--LGBBAT1AD8J"),
            new RichEnumOption(1420, ".XN--MGB9AWBF"),
            new RichEnumOption(1421, ".XN--MGBA3A3EJT"),
            new RichEnumOption(1422, ".XN--MGBA3A4F16A"),
            new RichEnumOption(1423, ".XN--MGBA7C0BBN0A"),
            new RichEnumOption(1424, ".XN--MGBAAKC7DVF"),
            new RichEnumOption(1425, ".XN--MGBAAM7A8H"),
            new RichEnumOption(1426, ".XN--MGBAB2BD"),
            new RichEnumOption(1427, ".XN--MGBAH1A3HJKRD"),
            new RichEnumOption(1428, ".XN--MGBAI9AZGQP6J"),
            new RichEnumOption(1429, ".XN--MGBAYH7GPA"),
            new RichEnumOption(1430, ".XN--MGBBH1A"),
            new RichEnumOption(1431, ".XN--MGBBH1A71E"),
            new RichEnumOption(1432, ".XN--MGBC0A9AZCG"),
            new RichEnumOption(1433, ".XN--MGBCA7DZDO"),
            new RichEnumOption(1434, ".XN--MGBCPQ6GPA1A"),
            new RichEnumOption(1435, ".XN--MGBERP4A5D4AR"),
            new RichEnumOption(1436, ".XN--MGBGU82A"),
            new RichEnumOption(1437, ".XN--MGBI4ECEXP"),
            new RichEnumOption(1438, ".XN--MGBPL2FH"),
            new RichEnumOption(1439, ".XN--MGBT3DHD"),
            new RichEnumOption(1440, ".XN--MGBTX2B"),
            new RichEnumOption(1441, ".XN--MGBX4CD0AB"),
            new RichEnumOption(1442, ".XN--MIX891F"),
            new RichEnumOption(1443, ".XN--MK1BU44C"),
            new RichEnumOption(1444, ".XN--MXTQ1M"),
            new RichEnumOption(1445, ".XN--NGBC5AZD"),
            new RichEnumOption(1446, ".XN--NGBE9E0A"),
            new RichEnumOption(1447, ".XN--NGBRX"),
            new RichEnumOption(1448, ".XN--NODE"),
            new RichEnumOption(1449, ".XN--NQV7F"),
            new RichEnumOption(1450, ".XN--NQV7FS00EMA"),
            new RichEnumOption(1451, ".XN--NYQY26A"),
            new RichEnumOption(1452, ".XN--O3CW4H"),
            new RichEnumOption(1453, ".XN--OGBPF8FL"),
            new RichEnumOption(1454, ".XN--OTU796D"),
            new RichEnumOption(1455, ".XN--P1ACF"),
            new RichEnumOption(1456, ".XN--P1AI"),
            new RichEnumOption(1457, ".XN--PBT977C"),
            new RichEnumOption(1458, ".XN--PGBS0DH"),
            new RichEnumOption(1459, ".XN--PSSY2U"),
            new RichEnumOption(1460, ".XN--Q7CE6A"),
            new RichEnumOption(1461, ".XN--Q9JYB4C"),
            new RichEnumOption(1462, ".XN--QCKA1PMC"),
            new RichEnumOption(1463, ".XN--QXA6A"),
            new RichEnumOption(1464, ".XN--QXAM"),
            new RichEnumOption(1465, ".XN--RHQV96G"),
            new RichEnumOption(1466, ".XN--ROVU88B"),
            new RichEnumOption(1467, ".XN--RVC1E0AM3E"),
            new RichEnumOption(1468, ".XN--S9BRJ9C"),
            new RichEnumOption(1469, ".XN--SES554G"),
            new RichEnumOption(1470, ".XN--T60B56A"),
            new RichEnumOption(1471, ".XN--TCKWE"),
            new RichEnumOption(1472, ".XN--TIQ49XQYJ"),
            new RichEnumOption(1473, ".XN--UNUP4Y"),
            new RichEnumOption(1474, ".XN--VERMGENSBERATER-CTB"),
            new RichEnumOption(1475, ".XN--VERMGENSBERATUNG-PWB"),
            new RichEnumOption(1476, ".XN--VHQUV"),
            new RichEnumOption(1477, ".XN--VUQ861B"),
            new RichEnumOption(1478, ".XN--W4R85EL8FHU5DNRA"),
            new RichEnumOption(1479, ".XN--W4RS40L"),
            new RichEnumOption(1480, ".XN--WGBH1C"),
            new RichEnumOption(1481, ".XN--WGBL6A"),
            new RichEnumOption(1482, ".XN--XHQ521B"),
            new RichEnumOption(1483, ".XN--XKC2AL3HYE2A"),
            new RichEnumOption(1484, ".XN--XKC2DL3A5EE0H"),
            new RichEnumOption(1485, ".XN--Y9A3AQ"),
            new RichEnumOption(1486, ".XN--YFRO4I67O"),
            new RichEnumOption(1487, ".XN--YGBI2AMMX"),
            new RichEnumOption(1488, ".XN--ZFR164B"),
            new RichEnumOption(1489, ".XXX"),
            new RichEnumOption(1490, ".XYZ"),
            new RichEnumOption(1491, ".YACHTS"),
            new RichEnumOption(1492, ".YAHOO"),
            new RichEnumOption(1493, ".YAMAXUN"),
            new RichEnumOption(1494, ".YANDEX"),
            new RichEnumOption(1495, ".YE"),
            new RichEnumOption(1496, ".YODOBASHI"),
            new RichEnumOption(1497, ".YOGA"),
            new RichEnumOption(1498, ".YOKOHAMA"),
            new RichEnumOption(1499, ".YOU"),
            new RichEnumOption(1500, ".YOUTUBE"),
            new RichEnumOption(1501, ".YT"),
            new RichEnumOption(1502, ".YUN"),
            new RichEnumOption(1503, ".ZA"),
            new RichEnumOption(1504, ".ZAPPOS"),
            new RichEnumOption(1505, ".ZARA"),
            new RichEnumOption(1506, ".ZERO"),
            new RichEnumOption(1507, ".ZIP"),
            new RichEnumOption(1508, ".ZM"),
            new RichEnumOption(1509, ".ZONE"),
            new RichEnumOption(1510, ".ZUERICH"),
            new RichEnumOption(1511, ".ZW"),
        );
    }
}

// COMMON ENUMS

@sealed
export class BooleanEnum extends RichEnum {
    constructor() {
        super(
            'BOOLEAN',
            new RichEnumOption(1, 'NO'),
            new RichEnumOption(2, 'YES'),
        );
    }
}

@sealed
export class BooleanEnumWithUnknown extends RichEnum {
    constructor() {
        super(
            'BOOLEAN_WITH_UNKNOWN',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'NO'),
            new RichEnumOption(2, 'YES'),
        );
    }

    public fromExact(value: boolean): string {
        let num = (value === null || value === undefined) ? 0 : (value === true ? 2 : 1);
        return this.getForValue(num).toString();
    }
}

@sealed
export class NullIsUsed extends RichEnum {
    constructor() {
        super(
            'BOOLEAN_WITH_UNKNOWN',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(0, 'NOT_USED'),
            new RichEnumOption(1, 'USED')
        );
    }
}

@sealed
export class NullIsOnline extends RichEnum {
    constructor() {
        super(
            'BOOLEAN_WITH_UNKNOWN',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(1, 'IS_ONLINE'),
            new RichEnumOption(0, 'IS_OFFLINE')
        );
    }
}

@sealed
export class SERPLanguage extends RichEnum {
    constructor() {
        super(
            'SERP_LANGUAGE',
            new RichEnumOption(0, 'EN'),
            new RichEnumOption(1, 'IT'),
            new RichEnumOption(2, 'ES'),
            new RichEnumOption(3, 'FR'),
        );
    }
}

@sealed
export class SERPFrequency extends RichEnum {
    public static Disabled: number = 0;
    public static Weekly: number = 1;
    public static Monthly: number = 2;
    public static Manual: number = 3;

    constructor() {
        super(
            'SERP_FREQUENCY',
            new RichEnumOption(SERPFrequency.Disabled, 'DISABLED'),
            new RichEnumOption(SERPFrequency.Weekly, 'WEEKLY'),
            new RichEnumOption(SERPFrequency.Monthly, 'MONTHLY'),
            new RichEnumOption(SERPFrequency.Manual, 'MANUAL'),
        );
    }
}

@sealed
export class SERPBoxType extends RichEnum {
    public static Link: number = 0;
    public static Image: number = 1;
    public static Video: number = 2;
    public static News: number = 3;
    public static SubLink: number = 4;

    constructor() {
        super(
            'SERP_BOXTYPE',
            new RichEnumOption(SERPBoxType.Link, 'LINK'),
            new RichEnumOption(SERPBoxType.Image, 'IMAGE'),
            new RichEnumOption(SERPBoxType.Video, 'VIDEO'),
            new RichEnumOption(SERPBoxType.News, 'NEWS'),
            new RichEnumOption(SERPBoxType.SubLink, 'SUBLINK'),
        );
    }
}

@sealed
export class SERPLinkKind extends RichEnum {
    public static SoftLegal: number = -2;
    public static Negative: number = -1;
    public static Unknown: number = 0;
    public static Unrelated: number = 1;
    public static External: number = 2;
    public static Enforced: number = 3;
    public static Controlled: number = 4;

    constructor() {
        super(
            'SERP_LINK_KIND',
            new RichEnumOption(SERPLinkKind.SoftLegal, 'SOFT_LEGAL'),
            new RichEnumOption(SERPLinkKind.Negative, 'NEGATIVE'),
            new RichEnumOption(SERPLinkKind.Unknown, 'UNKNOWN'),
            new RichEnumOption(SERPLinkKind.Unrelated, 'UNRELATED'),
            new RichEnumOption(SERPLinkKind.External, 'EXTERNAL'),
            new RichEnumOption(SERPLinkKind.Enforced, 'ENFORCED'),
            new RichEnumOption(SERPLinkKind.Controlled, 'CONTROLLED'),
        );
    }
}

@sealed
export class WorkFlowResult extends RichEnum {
    constructor() {
        super(
            'WORKFLOW_RESULT',
            new RichEnumOption(0, 'OPEN'),
            new RichEnumOption(1, 'WON'),
            new RichEnumOption(2, 'LOST'),
        );
    }
}

@sealed
export class SearchOrder extends RichEnum {
    static Reversed: number = -1;
    static NoOrder: number = 0;
    static Straight: number = 1;

    static changeOrder(param: OrderParams, propName: string): void {
        const currentVal = param.order;
        if (currentVal === undefined || currentVal === null)
            return;

        if (propName != param.orderPropertyName) {
            param.orderPropertyName = propName;
            param.order = SearchOrder.Straight;
            return;
        }

        param.orderPropertyName = propName;

        if (currentVal == SearchOrder.Straight) {
            param.order = SearchOrder.Reversed;
            return;
        }

        if (currentVal == SearchOrder.Reversed || currentVal == SearchOrder.NoOrder) {
            param.order = SearchOrder.Straight;
            return;
        }
    }

    static getFlipIconClass(param: OrderParams, propertyName: string) {
        if (param.orderPropertyName !== propertyName)
            return 'd-none';

        if (param.order === SearchOrder.NoOrder)
            return 'd-none';

        if (param.order !== SearchOrder.Straight)
            return 'flip-icon';

        return null;
    }

    constructor() {
        super(
            'SEARCH_ORDER',
            new RichEnumOption(SearchOrder.Reversed, 'Reversed'),
            new RichEnumOption(SearchOrder.NoOrder, 'No order'),
            new RichEnumOption(SearchOrder.Straight, 'Straight'),
        );
    }
}

@sealed
export class ChannelEnum extends RichEnum {
    constructor() {
        super(
            'CHANNEL',
            new RichEnumOption(0, 'N/A', 'N/A'),
            new RichEnumOption(1, 'Website personal', 'Website personal'),
            new RichEnumOption(2, 'LinkedIn', 'LinkedIn'),
            new RichEnumOption(3, 'H2Biz', 'H2Biz'),
            new RichEnumOption(4, 'Executive Manager', 'Executive Manager'),
            new RichEnumOption(5, 'Viadeo', 'Viadeo'),
            new RichEnumOption(6, 'Xing', 'Xing'),
            new RichEnumOption(7, 'About Me', 'About Me'),
            new RichEnumOption(8, 'Slideshare', 'Slideshare'),
            new RichEnumOption(9, 'Youtube', 'Youtube'),
            new RichEnumOption(10, 'Crunchbase', 'Crunchbase'),
            new RichEnumOption(11, 'Blogspot', 'Blogspot'),
            new RichEnumOption(12, 'Wordpress', 'Wordpress'),
            new RichEnumOption(13, 'Myblog', 'Myblog'),
            new RichEnumOption(14, 'Professionisti Italiani', 'Professionisti Italiani'),
            new RichEnumOption(15, 'Avvocati Italia', 'Avvocati Italia'),
            new RichEnumOption(16, 'Professionisti e Aziende', 'Professionisti e Aziende'),
            new RichEnumOption(17, 'Academia Edu', 'Academia Edu'),
            new RichEnumOption(18, 'Facebook', 'Facebook'),
            new RichEnumOption(19, 'Google+', 'Google+'),
            new RichEnumOption(20, 'Twitter', 'Twitter'),
            new RichEnumOption(21, 'Original Contents', 'Original Contents'),
            new RichEnumOption(22, 'Vimeo', 'Vimeo'),
            new RichEnumOption(23, 'Top 100 Manager', 'Top 100 Manager'),
            new RichEnumOption(24, 'Flickr', 'Flickr'),
            new RichEnumOption(25, 'Riflettori Su', 'Riflettori Su'),
            new RichEnumOption(26, 'Pinterest', 'Pinterest'),
            new RichEnumOption(27, 'Website corporate', 'Website corporate'),
            new RichEnumOption(28, 'Energia e Ambiente', 'Energia e Ambiente'),
            new RichEnumOption(29, 'Top Managers', 'Top Managers'),
            new RichEnumOption(30, 'Economia Oggi', 'Economia Oggi'),
            new RichEnumOption(31, 'Economia e Finanza', 'Economia e Finanza'),
            new RichEnumOption(32, 'Economia Oggi', 'Economia Oggi'),
            new RichEnumOption(33, 'Portale Economia', 'Portale Economia'),
            new RichEnumOption(34, 'CORPG', 'CORPG'),
            new RichEnumOption(35, 'Wikimilano', 'Wikimilano'),
            new RichEnumOption(36, 'Green Go Energy', 'Green Go Energy'),
            new RichEnumOption(37, 'Cultura 360', 'Cultura 360'),
        );
    }
}


// CONTACT ENUMS

class AmountEnum extends RichEnum {
    constructor(name: string = '') {
        super(
            name,
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'LOW'),
            new RichEnumOption(2, 'MEDIUM'),
            new RichEnumOption(3, 'HIGH')
        );
    }
}

@sealed
export class SuccessOfIntervention extends AmountEnum {
    constructor() {
        super('SUCCESS_OF_INTERVENTION');
    }
}

@sealed
export class PublicRelevance extends AmountEnum {
    constructor() {
        super('PUBLIC_RELEVANCE');
    }
}

@sealed
export class ContactRelationship extends RichEnum {
    public static readonly Relationship: number = 0;
    public static readonly Extension: number = 1;

    constructor() {
        super(
            'CONTACT_RELATIONSHIP',
            new RichEnumOption(ContactRelationship.Relationship, 'RELATIONSHIP')
            //new RichEnumOption(ContactRelationship.Extension, 'EXTENSION')
        );
    }
}


@sealed
export class CaseType extends RichEnum {
    constructor() {
        super(
            'CASE_TYPE',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(2, 'PERSONAL'),
            new RichEnumOption(4, 'MIDDLE'),
            new RichEnumOption(8, 'EXECUTIVE'),
            new RichEnumOption(1, 'CORPORATE')
        );
    }
}

@sealed
export class ContactStatus extends RichEnum {
    public static readonly NoPotential: number = 1;
    public static readonly Prospect: number = 2;
    public static readonly Lead: number = 4;
    public static readonly Customer: number = 8;
    
    constructor() {
        super(
            'CONTACT_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(ContactStatus.NoPotential, 'NO_POTENTIAL'),
            new RichEnumOption(ContactStatus.Prospect, 'PROSPECT'),
            new RichEnumOption(ContactStatus.Lead, 'LEAD'),
            new RichEnumOption(ContactStatus.Customer, 'CUSTOMER')
        );
    }
}

@sealed
export class InjuryLevel extends RichEnum {
    public static readonly Unknown = 0;
    public static readonly Easy = 1;
    public static readonly Medium = 2;
    public static readonly Difficult = 3;
    public static readonly NotWorkable = 4;
    public static readonly NoMoreAvailable = 5;
    constructor() {
        super(
            'INJURY_LEVEL',
            new RichEnumOption(InjuryLevel.Unknown, 'N/A'),
            new RichEnumOption(InjuryLevel.Easy, 'EASY'),
            new RichEnumOption(InjuryLevel.Medium, 'MEDIUM'),
            new RichEnumOption(InjuryLevel.Difficult, 'DIFFICULT'),
            new RichEnumOption(InjuryLevel.NotWorkable, 'NOT_WORKABLE'),
            new RichEnumOption(InjuryLevel.NoMoreAvailable, 'NO_MORE_AVAILABLE')
        );
    }
}

@sealed
export class RecallType extends RichEnum {
    constructor() {
        super(
            'RECALL_TYPE',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'CALL', 'recall-type-call'),
            new RichEnumOption(2, 'MAIL', 'recall-type-mail'),
            new RichEnumOption(3, 'MEETING', 'recall-type-meeting')
        );
    }
}

@sealed class PreAnalysisStatus extends RichEnum {
    readonly NewContact: number = 1;
    readonly Feasibility: number = 2;
    readonly CommercialOffer: number = 3;
    readonly ActivityStart: number = 4;
    readonly ActivityEnd: number = 5;

    constructor() {
        super(
            'PREANALYSIS_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'NEW_CONTACT'),
            new RichEnumOption(2, 'FEASIBILITY'),
            new RichEnumOption(3, 'COMMERCIAL_OFFER'),
            new RichEnumOption(4, 'ACTIVITY_START'),
            new RichEnumOption(5, 'ACTIVITY_END'),
        );
    }
}

@sealed
export class EventStatus extends RichEnum {
    constructor() {
        super(
            'EVENT_STATUS',
            new RichEnumOption(0, 'NOT_STARTED'),
            new RichEnumOption(1, 'STARTED'),
            new RichEnumOption(2, 'FINISHED'),
            new RichEnumOption(3, 'CANCELLED'),
        );
    }
}

@sealed
export class EventResult extends RichEnum {
    constructor() {
        super(
            'EVENT_RESULT',
            new RichEnumOption(0, 'SUCCESS'),
            new RichEnumOption(1, 'FAIL'),
            new RichEnumOption(2, 'NO_RESULT'),
        );
    }
}



// CONTENT ENUMS

@sealed
export class ContentType extends RichEnum {
    constructor() {
        super(
            'CONTENT_TYPE',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'AUDIO'),
            new RichEnumOption(2, 'VIDEO'),
            new RichEnumOption(3, 'TEXT'),
            new RichEnumOption(4, 'PHOTO'),
        );
    }
}

@sealed
export class AssetStatus extends RichEnum {
    constructor() {
        super(
            'ASSET_STATUS',
            new RichEnumOption(0, 'PENDING'),
            new RichEnumOption(1, 'USABLE'),
            new RichEnumOption(2, 'NON_USABLE'),
        );
    }
}

@sealed
export class PossibleUse extends RichEnum {
    constructor() {
        super(
            'POSSIBLE_USE',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'UNUSABLE'),
            new RichEnumOption(2, 'ARTICLE_MARKETING'),
            new RichEnumOption(4, 'DIGITALIZATION'),
            new RichEnumOption(8, 'PROFILE'),
            new RichEnumOption(16, 'NEWS'),
            new RichEnumOption(32, 'SEO_PAPER'),
            new RichEnumOption(64, 'OPTIMIZATION'),
            new RichEnumOption(128, 'SOCIAL'),
        );
    }
}

@sealed
export class ContentStatus extends RichEnum {
    constructor() {
        super(
            'CONTENT_STATUS',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'DRAW_UP'),
            new RichEnumOption(2, 'UNDER_VALIDATION'),
            new RichEnumOption(3, 'VALIDATED'),
            new RichEnumOption(4, 'PUBLISHED')
        );
    }
}


// CUSTOMER LINK ENUMS

@sealed
export class ContractStatus extends RichEnum {
    constructor() {
        super(
            'CONTRACT_STATUS',
            new RichEnumOption(1, 'CONTRACT'),
            new RichEnumOption(2, 'FOLLOWING'),
        );
    }
}

@sealed
export class Contacts extends RichEnum {
    constructor() {
        super(
            'CONTACTS',
            new RichEnumOption(1, 'ARCHIVED'),
            new RichEnumOption(2, 'TO_BE_ARCHIVED'),
        );
    }
}

@sealed
export class SoftLegalLinkStatus extends RichEnum {
    static readonly Unknown: number = 0;
    static readonly Anonymized: number = 1;
    static readonly Deleted: number = 2;
    static readonly UnIndexed: number = 4;
    //static readonly Updating: number = 8;
    static readonly Updated: number = 16;
    static readonly Refused: number = 32;
    //static readonly Unworkable: number = 64;
    static readonly Blocked: number = 128;

    constructor() {
        super(
            'INSTANCE_LINK_STATUS',
            new RichEnumOption(SoftLegalLinkStatus.Unknown, 'N/A'),
            new RichEnumOption(SoftLegalLinkStatus.Anonymized, 'ANONYMIZED'),
            new RichEnumOption(SoftLegalLinkStatus.Deleted, 'CANCELLED'),
            new RichEnumOption(SoftLegalLinkStatus.UnIndexed, 'DE_INDEXED'),
            //new RichEnumOption(SoftLegalLinkStatus.Updating, 'UPDATING'),
            new RichEnumOption(SoftLegalLinkStatus.Updated, 'UPDATED'),
            new RichEnumOption(SoftLegalLinkStatus.Refused, 'REJECTED'),
            //new RichEnumOption(SoftLegalLinkStatus.Unworkable, 'UNWORKABLE'),
            new RichEnumOption(SoftLegalLinkStatus.Blocked, 'BLOCKED'),
        );
    }
}

@sealed
export class InstanceLinkStatus extends RichEnum {
    static readonly Anonymized: number = 1;
    static readonly Deleted: number = 2;
    static readonly UnIndexed: number = 4;
    static readonly Updated: number = 16;
    static readonly Refused: number = 32;
    static readonly Unworkable: number = 64;

    constructor() {
        super(
            'INSTANCE_LINK_STATUS',
            new RichEnumOption(InstanceLinkStatus.Anonymized, 'ANONYMIZED'),
            new RichEnumOption(InstanceLinkStatus.Deleted, 'CANCELLED'),
            new RichEnumOption(InstanceLinkStatus.UnIndexed, 'DE_INDEXED'),
            new RichEnumOption(InstanceLinkStatus.Updated, 'UPDATED'),
            new RichEnumOption(InstanceLinkStatus.Refused, 'REJECTED'),
            new RichEnumOption(InstanceLinkStatus.Unworkable, 'UNWORKABLE'),
        );
    }
}

@sealed
export class SuccessFeeStatus extends RichEnum {
    static readonly Unknown: number = 0;
    static readonly Contract_SuccessFee: number = 1;
    static readonly Contract_NoSuccessFee: number = 2;
    static readonly NoContract_SuccessFee: number = 3;

    constructor() {
        super(
            'SUCCESS_FEE_STATUS',
            new RichEnumOption(SuccessFeeStatus.Unknown, 'N/A'),
            new RichEnumOption(SuccessFeeStatus.Contract_SuccessFee, 'SUCCESSFEEWITHCONTRACT'),
            new RichEnumOption(SuccessFeeStatus.Contract_NoSuccessFee, 'NOSUCCESSFEEWITHCONTRACT'),
            new RichEnumOption(SuccessFeeStatus.NoContract_SuccessFee, 'SUCCESSFEEWITHNOCONTRACT'),
        );
    }
}

@sealed
export class WebFormStatus extends RichEnum {
    static readonly Rejected: number = 0;
    static readonly Cancelled: number = 1;
    static readonly Blocked: number = 2;
    constructor() {
        super(
            'INSTANCE_LINK_STATUS',
            new RichEnumOption(WebFormStatus.Rejected, 'REJECTED'),
            new RichEnumOption(WebFormStatus.Cancelled, 'CANCELLED'),
            new RichEnumOption(WebFormStatus.Blocked, 'BLOCKED'),
        );
    }
}

@sealed
export class SearchEngineStatus extends RichEnum {
    static readonly Rejected: number = 0;
    static readonly Blocked: number = 1;
    constructor() {
        super(
            'INSTANCE_LINK_STATUS',
            new RichEnumOption(SearchEngineStatus.Rejected, 'REJECTED'),
            new RichEnumOption(SearchEngineStatus.Blocked, 'BLOCKED'),
        );
    }
}

@sealed
export class AuthorityStatus extends RichEnum {
    static readonly Not_Accepted: number = 0;
    static readonly Accepted: number = 1;
    constructor() {
        super(
            'AUTHORITY_STATUS',
            new RichEnumOption(AuthorityStatus.Not_Accepted, 'NOTACCEPTED'),
            new RichEnumOption(AuthorityStatus.Accepted, 'ACCEPTED'),
        );
    }
}


@sealed
export class SearchEngines extends RichEnum {
    static readonly NoOne: number = 0;
    static readonly Google: number = 1;
    static readonly Yahoo: number = 2;
    static readonly Bing: number = 4;
    constructor() {
        super(
            'SEARCH_ENGINES_BLOCKED',
            new RichEnumOption(SearchEngines.NoOne, 'NONE'),
            new RichEnumOption(SearchEngines.Google, 'GOOGLE'),
            new RichEnumOption(SearchEngines.Yahoo, 'YAHOO'),
            new RichEnumOption(SearchEngines.Bing, 'BING'),
        );
    }
}

@sealed
export class UserEvaluationWithNeutral extends RichEnum {//Used in Import links from unium
    constructor() {
        super(
            'USER_EVALUATION_WITH_NEUTRAL',
            new RichEnumOption(-1, 'N/A'),
            new RichEnumOption(0, 'ALLNEGATIVE'),
            new RichEnumOption(1, 'POSITIVE'),
            new RichEnumOption(2, 'NEUTRAL'),   
        );
    }
}

@sealed
export class UserEvaluationWithNeutralUNIUM extends RichEnum {//Used in Import links from unium
    constructor() {
        super(
            'USER_EVALUATION_WITH_NEUTRAL',
            new RichEnumOption(-1, 'N/A'),
            new RichEnumOption(0, 'ALLNEGATIVE'),
            new RichEnumOption(1, 'POSITIVE'),
            new RichEnumOption(2, 'NEUTRALUNIUM'),
        );
    }
}

@sealed
export class ContentEvaluation extends RichEnum {//Used in content data add / update
    readonly NEGATIVE: number = 0;
    readonly POSITIVE: number = 1;
    readonly NEUTRAL: number = 2;
    readonly LOWNEGATIVE: number = 3;
    constructor() {
        super(
            'USER_EVALUATION_WITH_NEUTRAL',
            new RichEnumOption(null, 'N/A'),
            new RichEnumOption(0, 'NEGATIVE'),
            new RichEnumOption(1, 'POSITIVE'),
            new RichEnumOption(2, 'NEUTRAL'),
            new RichEnumOption(3, 'LOWNEGATIVE'),
        );
    }
}

@sealed
export class ContentEvaluationFilter extends RichEnum {//USE IT IN FILTER ONLY
    constructor() {
        super(
            'USER_EVALUATION_WITH_NEUTRAL',
            new RichEnumOption(-2, 'N/A'),//filter for extdata with Evaluation null
            new RichEnumOption(0, 'NEGATIVE'),
            new RichEnumOption(1, 'POSITIVE'),
            new RichEnumOption(2, 'NEUTRAL'),
            new RichEnumOption(3, 'LOWNEGATIVE'),
        );
    }
}

@sealed
export class Pertinence extends RichEnum {
    readonly UNKNOWN: number = 0;
    readonly RELATED: number = 1;
    readonly UNRELATED: number = 2;
    constructor() {
        super(
            'PERTINENCE',
            new RichEnumOption(0, 'UNKNOWN'),
            new RichEnumOption(1, 'RELATED'),
            new RichEnumOption(2, 'UNRELATED'),
        );
    }
}

@sealed
export class PertinenceFilter extends RichEnum {
    constructor() {
        super(
            'PERTINENCE',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(0, 'UNKNOWN'),
            new RichEnumOption(1, 'RELATED'),
            new RichEnumOption(2, 'UNRELATED'),
        );
    }
}

@sealed
export class SenderFilter extends RichEnum {
    constructor() {
        super(
            'CONTENT',
            new RichEnumOption(null, 'SELECT'),
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'RM'),
            new RichEnumOption(2, 'CUSTOMER'),  
        );
    }
}

@sealed
export class Sender extends RichEnum {
    constructor() {
        super(
            'CONTENT',
            new RichEnumOption(0, 'N/A'),
            new RichEnumOption(1, 'RM'),
            new RichEnumOption(2, 'CUSTOMER')  
        );
    }
}

@sealed
export class TargetStatus extends RichEnum {
    constructor() {
        super(
            'TARGET_STATUS',
            new RichEnumOption(3, 'ACCERTATO'),            
            new RichEnumOption(1, 'DA_VERIFICARE'),          
            new RichEnumOption(-1, 'ANY'),
            //new RichEnumOption(2, 'CANCELLATO'),
            //new RichEnumOption(3, ''),
            //new RichEnumOption(4, 'INTERVENTO'),
            //new RichEnumOption(5, 'AZIONE_LEGALE'),
            //new RichEnumOption(6, 'TEMPORANEAMENTE_NON_PERTINENTE'),
            //new RichEnumOption(7, 'SOSPESO_PER_CAMPIONAMENTO'),
            //new RichEnumOption(8, 'STAND_BY'),
        );
    }
}

@sealed
export class RecallElapsedStatus extends RichEnum {
    constructor() {
        super(
            'RECALL_ELAPSED_STATUS',
            new RichEnumOption(-1, null, '--bg-grey'),
            new RichEnumOption(0, 'ELAPSED_LESS_THAN_3_DAYS_AGO', '--bg-grey'),
            new RichEnumOption(1, 'ELAPSED_MORE_THAN_3_DAYS_AGO', '--bg-yellow'),
            new RichEnumOption(2, 'ELAPSED_MORE_THAN_6_DAYS_AGO', '--bg-warn'),
        );
    }
}

@sealed
export class SoftLegalRecallElapsedStatus extends RichEnum {
    constructor() {
        super(
            'SOFT_LEGAL_RECALL_ELAPSED_STATUS',
            new RichEnumOption(-1, null, '--bg-grey'),
            new RichEnumOption(0, 'ELAPSED_LESS_THAN_7_DAYS_AGO', '--bg-grey'),
            new RichEnumOption(1, 'ELAPSED_MORE_THAN_10_DAYS_AGO', '--bg-yellow'),
            new RichEnumOption(2, 'ELAPSED_MORE_THAN_15_DAYS_AGO', '--bg-warn'),
        );
    }
}

@sealed
export class InstanceStatus extends RichEnum {
    static readonly Open: number = 1;
    static readonly Sent: number = 2;
    static readonly Closed: number = 3;

    constructor() {
        super(
            'INSTANCE_STATUS',
            new RichEnumOption(1, 'OPEN', 'open'),
            new RichEnumOption(2, 'SENT', 'sent'),
            new RichEnumOption(3, 'CLOSED', 'closed')
        );
    }
}

@sealed
export class InstanceStatusOnlyFE extends RichEnum {
    static readonly Open: number = 1;
    static readonly Sent: number = 2;
    static readonly Closed: number = 3;
    static readonly AnomalyDetected: number = 4;
    static readonly WaitingForClose: number = 99;
    constructor() {
        super(
            'INSTANCE_STATUS',
            new RichEnumOption(InstanceStatusOnlyFE.Open, 'OPEN', 'open'),
            new RichEnumOption(InstanceStatusOnlyFE.Sent, 'SENT', 'sent'),
            new RichEnumOption(InstanceStatusOnlyFE.Closed, 'CLOSED', 'closed'),
            new RichEnumOption(InstanceStatusOnlyFE.AnomalyDetected, 'ANOMALYDETECTED', 'anomaly detected'),
            new RichEnumOption(InstanceStatusOnlyFE.WaitingForClose, 'WAITINGFORCLOSE', 'waiting for close')
        );
    }
}

@sealed
export class UserGroupFilter extends RichEnum {
    static readonly Users: number = 1;
    static readonly Sales: number = 2;
    static readonly Account: number = 4;
    static readonly Legal: number = 8;
    static readonly SEO: number = 16;
    static readonly Writers: number = 32;
    static readonly Any: number = 64; //Admin
    static readonly Customer: number = 128;

    constructor() {
        super(
            'USERGROUP',
            new RichEnumOption(1, 'USER'),
            new RichEnumOption(2, 'SALES'),
            new RichEnumOption(4, 'ACCOUNT'),
            new RichEnumOption(8, 'LEGAL'),
            new RichEnumOption(16, 'SEO'),
            new RichEnumOption(32, 'WRITERS'),
            new RichEnumOption(64, 'ANY'),
            new RichEnumOption(128, 'CUSTOMER')  
        );
    }
}

@sealed
export class UNIUMSentiment extends RichEnum {
    static readonly NEG5: number = -5;
    static readonly NEG4: number = -4;
    static readonly NEG3: number = -3;
    static readonly NEG2: number = -2;
    static readonly NEG1: number = -1;
    static readonly POS1: number = 1;
    static readonly POS2: number = 2;
    static readonly POS3: number = 3;
    static readonly POS4: number = 4;
    static readonly POS5: number = 5;

    constructor() {
        super(
            'UNIUMSENTIMENT',
            new RichEnumOption(-5, 'NEG5'),
            new RichEnumOption(-4, 'NEG4'),
            new RichEnumOption(-3, 'NEG3'),
            new RichEnumOption(-2, 'NEG2'),
            new RichEnumOption(-1, 'NEG1'),
            new RichEnumOption(1, 'POS1'),
            new RichEnumOption(2, 'POS2'),
            new RichEnumOption(3, 'POS3'),
            new RichEnumOption(4, 'POS4'),
            new RichEnumOption(5, 'POS5')
        );
    }
}

@sealed
export class ContentDocumentType extends RichEnum {
    static readonly General: number = 0;
    static readonly CustomerLinkImage: number = 1;
    constructor() {
        super(
            'CONTENT_DOCUMENT_TYPE',
            new RichEnumOption(ContentDocumentType.General, 'GENERAL'),
            new RichEnumOption(ContentDocumentType.CustomerLinkImage, 'CUSTOMER_LINK_IMAGE'),
        );
    }
}

@sealed
export class Enums {
    public readonly BooleanEnum = new BooleanEnum();
    public readonly BooleanEnumWithUnknown = new BooleanEnumWithUnknown();
    public readonly ChannelEnum = new ChannelEnum();
    public readonly RecallElapsedStatus = new RecallElapsedStatus();
    public readonly SoftLegalRecallElapsedStatus = new SoftLegalRecallElapsedStatus();
    public readonly Pertinence = new Pertinence();
    public readonly PertinenceFilter = new PertinenceFilter();
    public readonly ContentEvaluation = new ContentEvaluation();//Eval content details
    public readonly ContentEvaluationFilter = new ContentEvaluationFilter();//Eval content filter
    public readonly UserEvaluationWithNeutral = new UserEvaluationWithNeutral();
    public readonly UserEvaluationWithNeutralUNIUM = new UserEvaluationWithNeutralUNIUM();//Eval import from unium
    public readonly Sender = new Sender();
    public readonly SenderFilter = new SenderFilter();
    public readonly TargetStatus = new TargetStatus();
    // CONTACT ENUMS
    public readonly ContactPartner = new ContactPartner();
    public readonly WftStatus = new WftStatus();
    public readonly ValueMatrixCategory = new ValueMatrixCategory();
    public readonly ContentManagedBy = new ContentManagedBy();
    public readonly ContactLanguage = new ContactLanguage();
    public readonly ChannelApprovalStates = new ChannelApprovalStates();
    public readonly TldList = new TldList();
    public readonly ChannelStatus = new ChannelStatus();
    public readonly ChannelWorkingStatus = new ChannelWorkingStatus();
    public readonly ContentTypology = new ContentTypology();
    public readonly ChannelIndexing = new ChannelIndexing();
    public readonly ActivityTarget = new ActivityTarget();
    public readonly DomainTypology = new DomainTypology();
    public readonly ChannelType = new ChannelType();
    public readonly CaseType = new CaseType();
    public readonly PublicRelevance = new PublicRelevance();
    public readonly ContactStatus = new ContactStatus();
    public readonly InjuryLevel = new InjuryLevel();
    public readonly SuccessOfIntervention = new SuccessOfIntervention();
    public readonly PreAnalysisStatus = new PreAnalysisStatus();
    public readonly EventStatus = new EventStatus();
    public readonly EventResult = new EventResult();
    public readonly WorkFlowResult = new WorkFlowResult();
    public readonly RecallType = new RecallType();
    public readonly ContactRelationship = new ContactRelationship();
    public readonly UserGroupFilter = new UserGroupFilter()
    // CONTENT ENUMS
    public readonly ContentType = new ContentType();
    public readonly AssetStatus = new AssetStatus();
    public readonly PossibleUse = new PossibleUse();
    public readonly ContentStatus = new ContentStatus();
    // CUSTOMER LINK ENUMS
    public readonly ContractStatus = new ContractStatus();
    public readonly Contacts = new Contacts();
    public readonly InstanceStatus = new InstanceStatus();
    public readonly InstanceLinkStatus = new InstanceLinkStatus();
    public readonly SearchEngines = new SearchEngines();
    public readonly SuccessFeeStatus = new SuccessFeeStatus();
    //SOFT LEGAL ACTION STATUS
    public readonly SoftLegalLinkStatus = new SoftLegalLinkStatus();
    public readonly WebFormStatus = new WebFormStatus();
    public readonly SearchEngineStatus = new SearchEngineStatus();
    public readonly AuthorityStatus = new AuthorityStatus();
    public readonly InstanceStatusOnlyFE = new InstanceStatusOnlyFE();


    // SERP
    public readonly SERPLinkKind = new SERPLinkKind();
    public readonly SERPFrequency = new SERPFrequency();
    public readonly SERPLanguage = new SERPLanguage();
    public readonly SERPBoxType = new SERPBoxType();

    public readonly NullIsUsed = new NullIsUsed();
    public readonly NullIsOnline = new NullIsOnline();    

    public readonly UNIUMSentiment = new UNIUMSentiment();    

    public readonly ContentDocumentType = new ContentDocumentType();    

    public filterEnumValues(option: RichEnumOption): boolean {
        return option.name && option.value !== undefined && option.value !== 0;
    }
}
