<div id='myDiagramDiv'></div>

<button mat-raised-button
        (click)="export()"
        style="float:right; margin-right:20px; margin-top: 20px!important;"
        class="level2-button newDefaultChannel">
    <img src="assets/svg/material-icons/save_alt_blue.svg">
    <span class="uniumx-button-text">Esporta</span>
</button>

<button mat-raised-button
        (click)="save()"
        style="float:right; margin-right:20px; margin-top: 20px!important;"
        class="level2-button newDefaultChannel">
    <img src="assets/svg/material-icons/save_blue.svg">
    <span class="uniumx-button-text">Salva</span>
</button>

<button mat-raised-button
        (click)="reset()"
        style="float:right; margin-right:20px; margin-top: 20px!important;"
        class="level2-button newDefaultChannel">
    <img src="assets/svg/material-icons/delete_blue.svg">
    <span class="uniumx-button-text">Reset</span>
</button>