<div *ngIf="usersCustomers" style="min-height: 475px;">

    <!-- STEP 0 -scelta utenti- -->
    <div *ngIf="step == 0">
        <div class="row mb-2">
            <div class="col-12 mb-3">
                <mat-form-field style="width: 100%">
                    <input [(ngModel)]="usersFilter"
                           style="width: 100%"
                           #name="ngModel"
                           name="name_filter"
                           matInput
                           placeholder="Username">
                </mat-form-field>
            </div>

            <div class="col-6 headerTable">
                <b>{{'TABLE_HEADINGS.USERS' | translate}}</b>
            </div>
            <div class="col-6 text-center">
                <b>{{'TITLES.ASSOCIATED' | translate}}</b>
            </div>
        </div>
        <div class="row m-0" style="padding: 0 15px" *ngFor="let obj of filterUsersCustomers(modelCustomers); let i = index">
            <div *ngIf="i < usersLength" class="col-6 usersCustomerRow">
                <span>{{obj.user.fullName}}</span>
            </div>
            <div *ngIf="i < usersLength" class="usersCustomerRow justify-content-end col-6">
                <mat-checkbox (change)="newAssociated($event, obj)"
                              [(ngModel)]="obj.isAssociated"
                              class="col-3 mb-0 d-flex align-items-center sCheck">
                </mat-checkbox>
                <div class="col-3">
                    <img *ngIf="obj.isAssociated" (click)="step = 1; settingsForUserId(obj)" src="/assets/svg/material-icons/settings_white.svg" style="filter: invert(1); opacity: .65;  margin-top:5px!important;">
                </div>
            </div>
            
        </div>
    </div>


    <!-- STEP 1 -scelta tabs- -->
    <div *ngIf="step == 1 && detailUser">
        <div class="col-6 headerTable mb-4">
            <b>{{detailUser.user.fullName}}</b>
        </div>
        <!-- Matrice -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>Matrice</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.matrixIsActive" class="col-6 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
            </div>
        </div>
        <!-- TimeLine -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>TimeLine</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.timelineIsActive" class="col-6 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
            </div>
        </div>
        <!-- WIP -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>Piano Canali</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.wipIsActive" class="col-6 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
            </div>
        </div>
        <!-- Serp -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>Serp</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.serpIsActive" (change)="setAllSerp($event, detailUser)" class="col-3 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
                <div class="col-3">
                    <img *ngIf="detailUser.serpIsActive" (click)="step = 2;" src="/assets/svg/material-icons/settings_white.svg" style="filter: invert(1); opacity: .65;">
                </div>
            </div>
        </div>
        <!-- Soft Legal -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>Soft Legal</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.softLegalIsActive" class="col-6 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
            </div>
        </div>
        <!-- Documets -->
        <div class="row m-0" style="padding: 0 15px">
            <div class="col-6 usersCustomerRow">
                <span>Documenti</span>
            </div>
            <div class="col-6 usersCustomerRow justify-content-end">
                <mat-checkbox [(ngModel)]="detailUser.documentsIsActive" class="col-6 mb-0 d-flex align-items-center sCheck"></mat-checkbox>
            </div>
        </div>
    </div>

    <!-- STEP 2 -scelta SERP- -->
    <div *ngIf="step == 2 && detailUser">
        <div class="col-6 headerTable mb-4">
            <b>{{detailUser.user.fullName}}</b>
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-9">
                <span>Numero di risultati da mostrare</span>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="detailUser.serpResults">
                        <mat-option [value]="20">20</mat-option>
                        <mat-option [value]="50">50</mat-option>
                        <mat-option [value]="100">100</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row m-0" style="padding: 0 15px" *ngFor="let serp of allSerp">
            <div class="col-9 usersCustomerRow">
                <span>{{serp.querySearchEngineHost}} - {{serp.querySearchKeywords}}</span>
            </div>
            <div class="col-3 usersCustomerRow justify-content-end">
                <mat-checkbox (change)="serpCheck($event, serp.id)"
                              [checked]="isChecked(detailUser, serp.id)"
                              class="col-3 mb-0 d-flex align-items-center sCheck">
                </mat-checkbox>
            </div>
        </div>
    </div>

</div>

<mat-dialog-actions class="w-100 mr-3">
    <button mat-button color="primary"
            class="ml-auto level2-button"
            *ngIf="usersLength < usersCustomerLength(modelCustomers) && step == 0"
            (click)="usersLength = usersLength + 50">
        {{'BUTTONS.LOAD_MORE' | translate}}
    </button>
    <button mat-button color="primary"
            class="ml-auto level2-button"
            *ngIf="step != 0"
            (click)="step = step - 1">
        {{'BUTTONS.BACK' | translate}}
    </button>
    <button mat-button color="primary"
            class="level2-button"
            (click)="_ok()">
        <img class="mr-2" src="/assets/svg/material-icons/save_blue.svg">
        {{'BUTTONS.SAVE' | translate}}
    </button>
</mat-dialog-actions>

